<k5-widget *ngIf="feedUrl">
    <div *ngIf="!loading; else loadingIndicator" class="flex h-full w-full flex-col">
        <div class="news-image max-h-[50%] basis-6/12">
            <a *ngIf="entry.image; else errorImage" [href]="entry.link" target="_blank">
                <img class="h-full w-full object-contain" [src]="entry.image" [alt]="entry.description" />
            </a>

            <button
                *ngIf="entry.image"
                class="action-buttons absolute left-0 top-1/4 ml-6 h-10 w-10 rounded-full border border-solid border-slate-300 bg-white text-black hover:bg-gray-200"
                (click)="previous()"
            >
                <i class="fa-duotone fa-chevron-left"></i>
            </button>
            <button
                *ngIf="entry.image"
                class="action-buttons absolute right-0 top-1/4 mr-6 h-10 w-10 rounded-full border border-solid border-slate-300 bg-white text-black hover:bg-gray-200"
                (click)="next()"
            >
                <i class="fa-duotone fa-chevron-right"></i>
            </button>
        </div>
        <span *ngIf="entry.title; else errorMessage" class="pt-md line-clamp-2 text-xl font-bold">{{
            entry.title
        }}</span>

        <span class="line-clamp-7 grow pt-4">
            {{ entry.description }}
        </span>

        <button *ngIf="entry.link" mat-button color="primary" class="w-fit" (click)="openLinkInTab(entry.link)">
            <i aria-hidden="true" class="fa-duotone fa-arrow-right pr-4"></i>Weiterlesen
        </button>
        <button
            *ngIf="!entry.link"
            mat-button
            color="primary"
            class="w-fit"
            (click)="loadWidgetEinstellungen(feedUrl)"
        >
            <i aria-hidden="true" class="fa-duotone fa-arrows-rotate pr-4"></i>Erneut versuchen
        </button>
    </div>
    <ng-template #loadingIndicator>
        <k5-loading message="News werden geladen ..."></k5-loading>
    </ng-template>
    <ng-template #errorMessage>
        <span class="line-clamp-2 pt-4 text-xl font-bold"> Fehler beim Laden des Widgets </span>
    </ng-template>
    <ng-template #errorImage>
        <a target="_blank">
            <img class="h-full w-full object-contain" src="assets/img/k5next_logo_minimal.svg" />
        </a>
    </ng-template>
</k5-widget>
