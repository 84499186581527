import { Component, Input, OnInit } from '@angular/core';
import { AvatarService } from '@shared/services/avatar.service';

@Component({
    selector: 'k5-avatar-photo',
    templateUrl: './avatar-photo.component.html',
    styleUrls: ['./avatar-photo.component.scss']
})
export class AvatarPhotoComponent implements OnInit {
    @Input()
    photoUrl: string;

    @Input()
    firstName: string | null;

    @Input()
    lastName: string | null;

    initials: string = '';
    circleColor: string = '#000000';

    /**
     * Initialisiert die Services
     */
    constructor(private avatarService: AvatarService) {}

    ngOnInit(): void {
        if (!this.photoUrl && this.firstName) {
            this.initials = this.avatarService.generateInitials(this.firstName, this.lastName);
            this.circleColor = this.avatarService.generateColor(this.initials);
        }
    }
}
