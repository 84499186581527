import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { WahlbestandSchnellsucheResponse } from '@shared/models/wahlbestandSchnellsucheResponse';
import { Observable } from 'rxjs';
import { WahlbestandResponse } from 'src/app/buergerservice/wahl/models/wahlbestandResponse';

/**
 * Service für die Schnellsuche
 */
@Injectable({
    providedIn: 'root'
})
export class SchnellsucheService {
    // Eventemitter für das Löschen des Texts aus der Schnellsuche
    clearSearchInput: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {}

    /**
     * Emittiert ein Event für das Löschen der Sucheingabe in der Schnellsuche
     */
    emitClearSearchInput(preserveDropdownData: boolean = true): void {
        this.clearSearchInput.emit(preserveDropdownData);
    }

    /**
     * Liefert Ergebnisse vom Kontaktmanagement für die Schnellsuche, die dem Suchstring entsprechen.
     * @param suchText Suchstring
     * @param maxResults maximale Anzahl der Treffer
     * @returns ein Observable mit den Suchergebnissen
     */
    getKontaktmanagementSchnellsucheErgebnis(
        suchText: string,
        maxResults: number = 10
    ): Observable<KontaktmanagementClient.SchnellsucheV2Response> {
        return this.httpClient.get<KontaktmanagementClient.SchnellsucheV2Response>(
            `${this.configService.getConfig().k5civisKm.kontaktmanagementUiApiBaseUrl}/V2/Suche/Kontaktpersonen`,
            {
                params: new HttpParams().set('suchstring', suchText).set('maxResults', maxResults)
            }
        );
    }

    /**
     * Liefert Ergebnisse aus dem Wahlbestand für die Schnellsuche, die dem Suchstring entsprechen.
     * @param wahlId Id der Wahl
     * @param suchText Suchstring
     * @param adressierung Adressierungsoption zur Aufbereitung der Adresse
     */
    getWahlbestandSchnellsucheErgebnis(wahlId: string, suchText: string): Observable<WahlbestandSchnellsucheResponse> {
        return this.httpClient.get<WahlbestandSchnellsucheResponse>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahlbestand/schnellsuche`,
            {
                params: new HttpParams().set('suchText', suchText).set('wahlId', wahlId)
            }
        );
    }

    /**
     * Liefert Ergebnisse aus dem Wahlbestand für die Schnellsuche, die dem Suchstring entsprechen, für die Listenansicht
     * @param wahlId Wahl Id
     * @param suchText Suchtext
     * * @param adressierung Adressierungsoption zur Aufbereitung der Adresse
     */
    getWahlbestandSchnellsucheListe(wahlId: string, suchText: string): Observable<WahlbestandResponse> {
        return this.httpClient.get<WahlbestandResponse>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahlbestand/schnellsucheListe`,
            {
                params: new HttpParams().set('suchText', suchText).set('wahlId', wahlId)
            }
        );
    }
}
