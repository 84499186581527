import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { UnsavedChangesDialogComponent } from '@core/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GlobalEditService {
    // Zustand, welche Sektion gerade vom Benutzer bearbeitet wird
    private editSectionId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    editSectionId$: Observable<string | null> = this.editSectionId.asObservable();

    // Form, die gerade vom Benutzer bearbeitet wird
    private editSectionForm: BehaviorSubject<FormGroup | null> = new BehaviorSubject<FormGroup | null>(null);
    editSectionForm$: Observable<FormGroup | null> = this.editSectionForm.asObservable();

    isEditing$: Observable<boolean> = this.editSectionId$.pipe(
        map((editSection: string | null): boolean => {
            return !!editSection;
        })
    );

    // Ereignis, um die gesamte Anwendung in den Lesemodus zu versetzen
    private closeAllEditSections: Subject<void> = new Subject<void>();
    closeAllEditSections$: Observable<void> = this.closeAllEditSections.asObservable();

    constructor(private dialog: MatDialog) {}

    /**
     * Stellt fest, ob der Benutzer aktuell eine Datenänderung durchführt
     * @returns boolean der angibt, ob der Benutzer aktuell eine Bearbeitungsmaske geöffnet hat
     */
    get isEditing(): boolean {
        return !!this.editSectionId.value;
    }

    /**
     * Gibt die Id der aktuell bearbeiteten Sektion zurück
     * @returns Id der Sektion
     */
    get currentEditSectionId(): string {
        return this.editSectionId.value;
    }

    /**
     * Setzt die aktuelle form, welche vom Benutzer bearbeitet wird
     * @param form form
     */
    setEditSectionForm(form: FormGroup): void {
        this.editSectionForm.next(form);
    }

    /**
     * Gibt die aktuell bearbeitete Form zurück
     * @returns form
     */
    getEditSectionForm(): FormGroup {
        return this.editSectionForm.value;
    }

    /**
     * Versetzt eine Sektion in den Bearbeitungsmodus.
     * Wirft Fehler, wenn bereits editiert wird oder keine Bearbeitungs-Id als Parameter mitgegeben
     * wird.
     */
    switchToEditMode(editSectionId: string): void {
        if (editSectionId === this.editSectionId.value) {
            return;
        }

        if (this.isEditing) {
            throw new Error('Die Anwendung befindet sich bereits im Änderungsmodus');
        } else if (editSectionId) {
            this.editSectionId.next(editSectionId);
        } else {
            throw new Error('Es wurde keine gültige Bearbeitungs-Id übergeben');
        }
    }

    /**
     * Versetzt die Anwendung in den Lesemodus
     */
    switchToReadMode(): void {
        this.closeAllEditSections.next();
        this.editSectionId.next(null);
        this.editSectionForm.next(null);
        const readModeEvent = new CustomEvent('switchToReadMode', {
            detail: {
                finished: true
            }
        });
        document.dispatchEvent(readModeEvent);
    }

    /**
     * Ändert die _handleClicke Methode beim Tab switch
     */
    registerTabGroupClickHandler(matTabGroup: MatTabGroup): void {
        const handleTabClick = matTabGroup._handleClick;

        matTabGroup._handleClick = (tab, header, index) => {
            if (this.isEditing && (this.getEditSectionForm() ? this.getEditSectionForm().dirty : true)) {
                const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
                dialogRef.componentInstance.leaveSiteObservable.subscribe({
                    next: (leaveSite: boolean) => {
                        if (leaveSite) {
                            handleTabClick.apply(matTabGroup, [tab, header, index]);
                        }
                    }
                });
            } else {
                if (this.isEditing) {
                    this.switchToReadMode();
                }
                handleTabClick.apply(matTabGroup, [tab, header, index]);
            }
        };
    }
}
