<k5-widget id="gespeicherteGruppeWidgetContent" [title]="titelText" data-cy="home-gespeicherte-gruppen">
    <div *ngIf="!loading; else loadingIndicator" class="flex h-full flex-col">
        <k5-header-search
            [centerSearch]="true"
            (inputChanged)="search($event)"
            id="gespeicherteGruppenFilter"
        ></k5-header-search>

        <div class="mt-4 overflow-auto">
            <div *ngIf="filteredGespeicherteGruppen?.length === 0" class="font-bold">
                Keine gespeicherten Gruppen gefunden
            </div>
            <div
                *ngFor="let gespeicherteGruppe of filteredGespeicherteGruppen; let i = index"
                class="gruppe-list-item flex items-center border-b border-solid border-gray-300 p-4"
                (click)="navigateToKM(gespeicherteGruppe.daten?.id)"
            >
                <div class="pr-4">
                    <i
                        *ngIf="gespeicherteGruppe.daten?.istEigeneGruppe"
                        class="fa-duotone fa-users fa-2x text-gray-500"
                    ></i>
                    <i
                        *ngIf="!gespeicherteGruppe.daten?.istEigeneGruppe"
                        class="fa-duotone fa-share-nodes fa-2x text-gray-500"
                    ></i>
                </div>
                <div class="flex flex-col">
                    <span class="search-name pb-2">
                        {{ gespeicherteGruppe.daten?.name }}
                    </span>
                    <span
                        *ngIf="gespeicherteGruppe.daten?.istEigeneGruppe"
                        class="text-xs text-gray-500"
                        [matTooltip]="
                            'zuletzt geändert am ' +
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteGruppe.daten?.letzteAenderung
                            ) +
                            ' von ' +
                            gespeicherteGruppe.daten?.geaendertVon
                        "
                    >
                        {{
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteGruppe.daten?.letzteAenderung
                            )
                        }},
                        {{ gespeicherteGruppe.daten?.geaendertVon }}
                    </span>
                    <span
                        *ngIf="!gespeicherteGruppe.daten?.istEigeneGruppe"
                        class="text-xs text-gray-500"
                        [matTooltip]="
                            'zuletzt geändert am ' +
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteGruppe.daten?.letzteAenderung
                            ) +
                            ' von ' +
                            gespeicherteGruppe.daten?.geaendertVon
                        "
                    >
                        {{
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteGruppe.daten?.letzteAenderung
                            )
                        }},
                        {{ gespeicherteGruppe.daten?.geaendertVon }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loadingIndicator>
        <k5-loading message="Gespeicherte Gruppen werden geladen ..."></k5-loading>
    </ng-template>
</k5-widget>
