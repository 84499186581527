import { Pipe, PipeTransform } from '@angular/core';
import { SucheWahlkartenStatusEnum } from '@shared/models/enums';

// /**
//  * Konvertiert die Enum-Werte des Typs WahlkartenStatus
//  * in eine passende textuelle Darstellung für das UI.
//  */
@Pipe({
    name: 'sucheWahlkartenStatus'
})
export class SucheWahlkartenStatusPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value SucheWahlkartenStatusEnum
     */
    transform(value: SucheWahlkartenStatusEnum): string {
        switch (value) {
            case SucheWahlkartenStatusEnum.Keine:
                return 'Keine Wahlkarte';
            case SucheWahlkartenStatusEnum.Beantragt:
                return 'Wahlkarte beantragt';
            case SucheWahlkartenStatusEnum.WahlkarteGedruckt:
                return 'Wahlkarte gedruckt';
            case SucheWahlkartenStatusEnum.EtikettGedruckt:
                return 'Etikett gedruckt';
            case SucheWahlkartenStatusEnum.Versendet:
                return 'Aufgabeliste Post gedruckt';
            case SucheWahlkartenStatusEnum.Ausgestellt:
                return 'Wahlkarte ausgestellt';
        }
    }
}
