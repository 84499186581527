<k5-dialog-heading heading="Ungespeicherte Änderungen" [showClose]="false"></k5-dialog-heading>

<k5-dialog-content>
    <p>
        Sie haben ungespeicherte Änderungen, sind Sie sicher, dass Sie diese Seite verlassen und die Änderungen
        verwerfen wollen?
    </p>
</k5-dialog-content>

<k5-dialog-actions [alignEnd]="true">
    <button mat-stroked-button color="primary" (click)="handleDialogAction(false)">Auf der Seite bleiben</button>
    <button mat-raised-button color="primary" (click)="handleDialogAction(true)" data-cy="seite-verlassen">
        Seite verlassen
    </button>
</k5-dialog-actions>
