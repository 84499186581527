import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { ChartLegend } from '@shared/models/chart-legend';
import { MEINE_WAHLKARTE_THEME } from '@shared/models/chartColors';
import { WahlkartenantraegeSearchParameters } from '@shared/models/meineWahlkarteSearchParameters';
import { MeineWahlkarteStatistikResponse } from '@shared/models/meineWahlkarteStatistikResponse';
import { Wahldaten } from '@shared/models/wahldaten';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { ChartOptions } from 'chart.js';
import { StatusFilter } from 'src/app/buergerservice/wahl/models/enums';
import { WahldetailService } from 'src/app/buergerservice/wahl/services/wahldetail.service';

@Component({
    selector: 'k5-meine-wahlkarte-widget',
    templateUrl: './meine-wahlkarte-widget.component.html'
})
export class MeineWahlkarteWidgetComponent implements OnInit {
    @Input()
    wahl: Wahldaten | null = null;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    meineWahlkarteStatistik: MeineWahlkarteStatistikResponse = null;

    dataSource: ChartLegend[] = [];

    // Bar-Chart
    barChartOptions: ChartOptions<'bar'> = null;
    barChartLabels = ['Wahlkartenanträge'];
    barChartDatasets = null;
    barChartLegend = false;

    colorScheme = {
        domain: MEINE_WAHLKARTE_THEME
    };

    error: boolean = false;
    loading: boolean = true;

    constructor(
        private wahlService: WahldetailService,
        private router: Router,
        private detailsucheService: DetailsucheService
    ) {}

    ngOnInit(): void {
        this.loadWidgetData();
    }

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }

    /**
     * Lädt die Daten für die Anzeige im Widget
     */
    loadWidgetData(): void {
        this.loading = true;
        this.error = false;
        this.wahlService.getMeineWahlkarteStatistik(this.wahl?.id).subscribe({
            next: (data: MeineWahlkarteStatistikResponse) => {
                this.meineWahlkarteStatistik = data;
                this.buildLegend(data);
                this.loading = false;
            },
            error: () => {
                this.error = true;
                this.loading = false;
            }
        });
    }

    /**
     * Baut die Daten für die Anzeige zusammen
     * @param data MeineWahlkarte Statistik
     */
    buildLegend(data: MeineWahlkarteStatistikResponse): void {
        const legend: ChartLegend[] = [
            {
                id: 1,
                name: 'Wahlkarten genehmigt',
                amount: data.wahlkartenGenehmigt,
                color: MEINE_WAHLKARTE_THEME[0]
            },
            {
                id: 2,
                name: 'Wahlkarten Genehmigung offen',
                amount: data.wahlkartenGehehmigungOffen,
                color: MEINE_WAHLKARTE_THEME[1]
            },
            {
                id: 3,
                name: 'Wahlkarten abgelehnt',
                amount: data.wahlkartenAbgelehnt,
                color: MEINE_WAHLKARTE_THEME[2]
            },
            {
                id: 4,
                name: 'Wahlkarten zurückgezogen',
                amount: data.wahlkartenZurueckgezogen,
                color: MEINE_WAHLKARTE_THEME[3]
            }
        ];

        this.dataSource = legend;

        const totalAmount = data.wahlkartenEingelangt;

        this.barChartDatasets = legend.map((x, i) => {
            return {
                label: [x.name],
                data: [x.amount],
                backgroundColor: MEINE_WAHLKARTE_THEME[i],
                maxBarThickness: 24
            };
        });

        this.barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            indexAxis: 'y',
            scales: {
                x: {
                    max: totalAmount,
                    stacked: true,
                    display: false,
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    display: false,
                    grid: {
                        display: false
                    }
                }
            }
        };
    }

    /**
     * Navigiert zu Meine Wahlkarte mit den vordefinierten Suchparametern
     */
    navigateToMeineWahlkarte(item: ChartLegend): void {
        const key = this.detailsucheService.generateSessionStorageKey();

        const searchParameters: WahlkartenantraegeSearchParameters = {
            statusFilter: [],
            identitaetsnachweisTypen: []
        };

        switch (item.id) {
            case 1:
                searchParameters.statusFilter = [StatusFilter.Genehmigt];
                break;
            case 2:
                searchParameters.statusFilter = [StatusFilter.Offen];
                break;
            case 3:
                searchParameters.statusFilter = [StatusFilter.Abgelehnt];
                break;
            case 4:
                searchParameters.statusFilter = [StatusFilter.Zurueckgezogen];
                break;
        }

        this.detailsucheService.saveSearchInStorage<WahlkartenantraegeSearchParameters>(searchParameters, key);
        this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id, PathConstants.MEINE_WAHLKARTE], {
            queryParams: { searchKey: key }
        });
    }
}
