import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { FileUploadConstants } from '@shared/constants/shared-constants';
import { Bildformat } from '@shared/models/bildformat';
import { ChangeGruppeRequest } from '@shared/models/changeGruppeRequest';
import { CreateGruppeRequest } from '@shared/models/createGruppeRequest';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { ShareGruppeRequest } from '@shared/models/shareGruppeRequest';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GruppenService {
    config: AppConfig = null;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {
        this.config = this.configService.getConfig();
    }

    /**
     * Gibt alle Gruppen zurück
     */
    getGruppen(): Observable<KontaktmanagementClient.GruppeResponse[]> {
        return this.httpClient.get<KontaktmanagementClient.GruppeResponse[]>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Gruppe`
        );
    }

    /**
     * Legt eine neue Gruppe an
     */
    createGruppe(request: CreateGruppeRequest): Observable<KontaktmanagementClient.GruppeResponse> {
        return this.httpClient.post<KontaktmanagementClient.GruppeResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Gruppe`,
            request
        );
    }

    /**
     * Updated eine bestehende Gruppe
     */
    updateGruppe(request: ChangeGruppeRequest, gruppeId: string): Observable<KontaktmanagementClient.GruppeResponse> {
        return this.httpClient.put<KontaktmanagementClient.GruppeResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Gruppe/${gruppeId}`,
            request
        );
    }

    /**
     * Löscht eine Gruppe
     */
    deleteGruppe(gruppeId: string): Observable<void> {
        return this.httpClient.delete<void>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Gruppe/${gruppeId}`
        );
    }

    /**
     * Teilt eine Gruppe mit Benutzern
     */
    shareGruppe(request: ShareGruppeRequest, gruppeId: string): Observable<KontaktmanagementClient.GruppeResponse> {
        return this.httpClient.post<KontaktmanagementClient.GruppeResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Gruppe/${gruppeId}/Share`,
            request
        );
    }

    /**
     * Fügt eine natürliche Person zu einer Gruppe hinzu
     */
    addNatPersonToGruppe(
        request: KontaktmanagementClient.AssignGruppenRequest,
        personId: string
    ): Observable<KontaktmanagementClient.DeletableNatuerlicheKontaktpersonResponse> {
        return this.httpClient.post<KontaktmanagementClient.DeletableNatuerlicheKontaktpersonResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/NatuerlicheKontaktperson/${personId}/Gruppen`,
            request
        );
    }

    /**
     * Fügt eine natürliche Person zu einer Gruppe hinzu
     */
    addUnternehmenToGruppe(
        request: KontaktmanagementClient.AssignGruppenRequest,
        unternehmenId: string
    ): Observable<KontaktmanagementClient.DeletableUnternehmensKontaktpersonResponse> {
        return this.httpClient.post<KontaktmanagementClient.DeletableUnternehmensKontaktpersonResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/UnternehmensKontaktperson/${unternehmenId}/Gruppen`,
            request
        );
    }

    // Helper Methoden

    /**
     * Baut den string für die image source zusammen
     * @param gruppeDaten Daten der Gruppe
     * @returns Zusammengebauter String, der im image tag verwendet werden kann
     */
    buildImageSrc(gruppeDaten: KontaktmanagementClient.GruppeDaten): string {
        if (!gruppeDaten?.bildinhalt) {
            return '';
        }
        let imageSrc: string = 'data:';
        switch (gruppeDaten.bildformat) {
            case Bildformat.Gif:
                imageSrc += FileUploadConstants.MIME_TYPE_GIF;
                break;
            case Bildformat.Jpeg:
                imageSrc += FileUploadConstants.MIME_TYPE_JPEG;
                break;
            case Bildformat.Png:
                imageSrc += FileUploadConstants.MIME_TYPE_PNG;
                break;
            case Bildformat.Svg:
                imageSrc += FileUploadConstants.MIME_TYPE_SVG;
                break;
            case Bildformat.None:
                return '';
            default:
                return '';
        }
        imageSrc += ';base64,' + gruppeDaten.bildinhalt;
        return imageSrc;
    }
}
