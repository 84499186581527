import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { AlertService } from '@core/services/alert.service';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { K5NextNotification } from '@k5next/ui-components';
import { KontaktmanagementDetailsuche, NotificationConstants } from '@shared/constants/shared-constants';
import { MandantClient } from '@shared/models/mandantenClient';
import { UIBenachrichtigung } from '@shared/models/uIBenachrichtigung';
import { UIBenachrichtigungInfo } from '@shared/models/uIBenachrichtigungInfo';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    notificationAmount = 0;

    config: AppConfig = null;

    notificationSubject: BehaviorSubject<UIBenachrichtigung[]> = new BehaviorSubject([]);
    notification$: Observable<UIBenachrichtigung[]> = this.notificationSubject.asObservable();

    notificationHistorySubject: BehaviorSubject<UIBenachrichtigung[]> = new BehaviorSubject([]);
    notificationHistory$: Observable<UIBenachrichtigung[]> = this.notificationHistorySubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService,
        private alertService: AlertService
    ) {
        this.config = this.configService.getConfig();
    }

    /**
     * Setzt die Anzahl der Benachrichtigungen
     * @param amount Anzahl der Benachrichtigungen
     */
    setNotificationAmount(amount: number): void {
        this.notificationAmount = amount;
    }

    /**
     * Setzt die Werte der Notification Listen
     * @param data Data
     */
    setNewNotificationInfo(data: MandantClient.BenachrichtigungInfo | null): void {
        if (data?.neu.length === 1) {
            this.alertService.notification(data.neu[0].text, data.neu[0]);
        } else if (data?.neu.length > 1) {
            this.alertService.info('Sie haben ' + data.neu.length + ' neue Benachrichtigungen');
        }
        this.setNotificationAmount(data?.ungelesen ? data?.ungelesen : 0);
    }

    /**
     * Gibt Informationen über aktuelle Benachrichtigungen zurück
     * @returns Informationen über neue Benachrichtigungen
     */
    getNotificationInfo(): Observable<UIBenachrichtigungInfo> {
        return this.httpClient.get<UIBenachrichtigungInfo>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Benachrichtigung/Aktualisierung`
        );
    }

    /**
     * Gibt alle aktuellen Benachrichtigungen zurück.
     * @param includeHistory Gibt an, ob Benachrichtigungen inklusive der Historie zurück gegeben werden sollen.
     * @returns ein Array von Benachrichtigungen als Observable.
     */
    getNotifications(includeHistory: boolean): Observable<UIBenachrichtigung[]> {
        const params: HttpParams = new HttpParams().set('historie', includeHistory);
        return this.httpClient.get<UIBenachrichtigung[]>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Benachrichtigung`,
            { params: params }
        );
    }

    /**
     * Markiert eine Benachrichtigung als gelesen
     * @param notificationId der Notification.
     * @returns ein Array von Benachrichtigungen als Observable.
     */
    markNotificationAsRead(notificationId: string): Observable<UIBenachrichtigung[]> {
        return this.httpClient.put<UIBenachrichtigung[]>(
            `${
                this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
            }/Benachrichtigung/${notificationId}/Gelesen`,
            null
        );
    }

    /**
     * Ermittelt die URL des Verweis, nachdem auf die Benachrichtigung geklickt wurde.
     * @param notification Notification Object.
     * @returns die Url der Notification als String.
     */
    getVerweisUrlNeu(notification: K5NextNotification): string | null {
        let url: string;
        if (notification.daten) {
            switch (notification.daten['objektTyp']) {
                case NotificationConstants.TYP_GESPEICHERTE_SUCHE:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_KEY}=${notification.daten['objektId']}`;
                    break;
                case NotificationConstants.TYP_SUCHERGEBNIS:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_RESULT_KEY}=${notification.daten['objektId']}`;
                    break;
                case NotificationConstants.TYP_GRUPPE:
                    url = `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.GRUPPE_KEY}=${notification.daten['objektId']}`;
                    break;
                default:
                    break;
            }
        }
        return url;
    }

    /**
     * Ermittelt die URL des Verweis, nachdem auf die Benachrichtigung geklickt wurde.
     * @param notification Notification Object.
     * @returns die Url der Notification als String.
     */
    getVerweisUrlFromNotificationAlertDialog(notification: UIBenachrichtigung): string | null {
        let url: string;
        if (notification.daten) {
            switch (notification.daten.objektTyp) {
                case NotificationConstants.TYP_GESPEICHERTE_SUCHE:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_KEY}=${notification.daten.objektId}`;
                case NotificationConstants.TYP_SUCHERGEBNIS:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.SEARCH_RESULT_KEY}=${notification.daten.objektId}`;
                case NotificationConstants.TYP_GRUPPE:
                    return `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.GRUPPE_KEY}=${notification.daten.objektId}`;
                default:
                    break;
            }
        }
        return url;
    }
}
