<k5-detailsuche
    [heading]="title"
    [additionalSearchCriteria]="additionalSearchCriteria"
    [showGespeicherteSuchen]="showGespeicherteSuchen()"
    [savedSearchList]="gespeicherteSuchen"
    [changedSearchData]="changedSearchData"
    (executeSearch)="handleSuchparameterAndNavigate()"
    (deleteSearch)="deleteSearch($event)"
    (resetSearch)="resetDetailSearchAndQuicksearch()"
    (shareSearch)="shareSearch($event)"
    (searchChanged)="handleSearchChanged($event)"
    (saveExistingSearch)="saveExistingSearch($event)"
    (saveNewSearch)="saveNewSearch($event)"
    (changeAdditionalSearchCriteria)="searchCriteriaSelectionChange($event)"
>
    <div k5-detailsuche-content>
        <form
            id="search-criteria-form"
            class="p-4"
            novalidate
            aria-disabled="true"
            [formGroup]="searchCriteriaForm"
            (keydown.enter)="handleSuchparameterAndNavigate()"
            (click)="clearQuickSearchPara()"
        >
            <mat-form-field class="w-full">
                <mat-label id="art">Art</mat-label>
                <mat-select aria-labelledby="art" formControlName="art" data-cy="detail-search-kontakte-art">
                    <mat-option
                        *ngFor="let typ of PERSONENART_VALUES | enumToArray"
                        [value]="typ.value"
                        data-cy="detail-search-kontakte-art-option"
                    >
                        {{ typ.value | personArt }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('istRegistergebunden')">
                <mat-label id="istRegistergebunden">Registergeprüft</mat-label>
                <mat-select
                    aria-labelledby="istRegistergebunden"
                    formControlName="istRegistergebunden"
                    data-cy="detail-search-kontakte-registergeprueft"
                >
                    <mat-option
                        *ngFor="let typ of JA_NEIN_VALUES | enumToArray"
                        [value]="typ.value"
                        data-cy="detail-search-kontakte-registergeprueft-option"
                    >
                        {{ typ.value | jaNeinEnum }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('notiz')">
                <mat-label id="notiz">Notiz</mat-label>
                <input
                    aria-labelledby="notiz"
                    matInput
                    formControlName="notiz"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-notiz-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.notiz"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.notiz.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <!-- Suchkriterien Kontakt: -->
            <div class="pt-4 text-xl">Kontakt</div>

            <mat-form-field class="w-full">
                <mat-label id="name">Name</mat-label>
                <input
                    aria-labelledby="name"
                    matInput
                    formControlName="name"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                    data-cy="detail-search-kontakte-input-name-text"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.name"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.name.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label id="geburtsdatum">Geburtsdatum</mat-label>
                <input
                    aria-labelledby="geburtsdatum"
                    matInput
                    formControlName="geburtsdatum"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-geburtsdatum-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.geburtsdatum"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.geburtsdatum.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label id="erreichbarkeit">Erreichbarkeit</mat-label>
                <input
                    aria-labelledby="erreichbarkeit"
                    matInput
                    formControlName="erreichbarkeit"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-erreichbarkeit-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.erreichbarkeit"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.erreichbarkeit.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="showFormField('gruppe')" class="w-full">
                <mat-label id="gruppe">Gruppe</mat-label>
                <mat-select aria-labelledby="gruppe" formControlName="gruppe" data-cy="detail-suche-gruppe">
                    <mat-option [value]="null"> - </mat-option>
                    <mat-option
                        *ngFor="let gruppe of gruppen"
                        data-cy="detail-suche-gruppe-option"
                        [value]="gruppe.daten.id"
                    >
                        {{ gruppe.daten.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('firmenbuchNr')">
                <mat-label id="firmenbuchNr">Firmenbuchnummer</mat-label>
                <input
                    aria-labelledby="firmenbuchNr"
                    matInput
                    formControlName="firmenbuchNr"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-firmenbuch-nr-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.firmenbuchNr"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.firmenbuchNr.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('uidNummer')">
                <mat-label id="uidNummer">UID-Nummer</mat-label>
                <input
                    aria-labelledby="uidNummer"
                    matInput
                    formControlName="uidNummer"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-uid-nr-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.uidNummer"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.uidNummer.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('ansprFunktion')">
                <mat-label id="ansprFunktion">Ansprechpartner Funktion</mat-label>
                <input
                    aria-labelledby="ansprFunktion"
                    matInput
                    data-cy="detail-search-kontakte-ansprechpartner-input"
                    formControlName="ansprFunktion"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.ansprFunktion"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.ansprFunktion.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full" *ngIf="showFormField('tag')">
                <mat-label>Tag</mat-label>
                <mat-chip-grid #chipGrid aria-label="Tag Auswahl">
                    @for (tag of tags; track tag) {
                        <mat-chip-row (removed)="removeTagFromChipList(tag)">
                            {{ tag }}
                            <button title="entfernen" matChipRemove [attr.aria-label]="tag + ' entfernen'">
                                <i class="fa-duotone fa-minus-circle fa-fw cursor-pointer" aria-hidden="true"></i>
                            </button>
                        </mat-chip-row>
                    }
                </mat-chip-grid>
                <input
                    placeholder="Tag ..."
                    #tagInput
                    [formControl]="searchCriteriaForm.controls.tag"
                    [matChipInputFor]="chipGrid"
                    [matAutocomplete]="auto"
                    data-cy="detail-search-kontakte-tag-input"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTagToChipList($event)"
                />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
                    @for (tag of filteredTags | async; track tag) {
                        <mat-option data-cy="detail-search-kontakte-tag-input-option" [value]="tag">{{
                            tag
                        }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            <!-- Suchkriterien Adresse: -->
            <div class="pt-4 text-xl">Adresse</div>

            <mat-form-field class="full-width">
                <mat-label id="strasse">Straße</mat-label>
                <input
                    aria-labelledby="strasse"
                    matInput
                    formControlName="strasse"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                    data-cy="detail-search-kontakte-strasse-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.strasse"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.strasse.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label id="orientierungsnummer">Hausnummer</mat-label>
                <input
                    aria-labelledby="orientierungsnummer"
                    matInput
                    formControlName="orientierungsnummer"
                    data-cy="detail-search-kontakte-orientierungsnummer-input"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.orientierungsnummer"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.orientierungsnummer.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label id="ort">Ort</mat-label>
                <input
                    aria-labelledby="ort"
                    matInput
                    formControlName="ort"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                    data-cy="detail-search-kontakte-input-ort"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.ort"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.ort.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="showFormField('postleitzahl')">
                <mat-label id="postleitzahl">Postleitzahl</mat-label>
                <input
                    aria-labelledby="postleitzahl"
                    matInput
                    formControlName="postleitzahl"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-plz-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.postleitzahl"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.postleitzahl.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="showFormField('gemeindekennziffer')">
                <mat-label id="gemeindekennziffer">Gemeindekennziffer</mat-label>
                <input
                    aria-labelledby="gemeindekennziffer"
                    matInput
                    formControlName="gemeindekennziffer"
                    data-cy="detail-search-kontakte-gkz-input"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.gemeindekennziffer"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.gemeindekennziffer.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="showFormField('staatISO3')">
                <mat-label id="staatISO3"> Staat</mat-label>
                <input
                    aria-labelledby="staatISO3"
                    matInput
                    formControlName="staatISO3"
                    (click)="clearQuickSearchPara()"
                    data-cy="detail-search-kontakte-staat-input"
                    autocomplete="off"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.staatISO3"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.staatISO3.reset()"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>
        </form>
    </div>
</k5-detailsuche>
