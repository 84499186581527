import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { MandantClient } from '@shared/models/mandantenClient';

@Component({
    selector: 'k5-mandant-darstellung',
    templateUrl: './mandant-darstellung.component.html'
})
export class MandantDarstellungComponent implements OnChanges {
    @Input()
    displaySmall: boolean = false;

    @Input({ required: true })
    darstellungData: MandantClient.DarstellungData | null = null;

    @Input({ required: true })
    bildUrl: string | null = null;

    imageSrc: string = '';
    avatarText: string = '';

    UI_DARSTELLUNG = MandantClient.DarstellungTyp;

    /**
     * Ändert das Bild bzw. den Avatartext, wenn sich die Inputparameter verändern
     * @param changes changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['bildUrl']) {
            if (changes['bildUrl'].currentValue) {
                this.imageSrc = changes['bildUrl'].currentValue;
            } else {
                this.imageSrc = UiConstants.WAPPEN_FALLBACK_URL;
            }
        }
        if (changes['darstellungData']) {
            if (changes['darstellungData'].currentValue) {
                this.avatarText = this.darstellungData?.nameZeile1.substring(0, 2);
            } else {
                this.avatarText = '';
            }
        }
    }
}
