import { Component, Input } from '@angular/core';

@Component({
    selector: 'k5-share-action-item',
    templateUrl: './share-action-item.component.html',
    styleUrls: ['./share-action-item.component.scss']
})
export class ShareActionItemComponent {
    @Input()
    icon: string;

    @Input()
    description: string;

    @Input()
    firstName: string;

    @Input()
    lastName: string;

    @Input()
    loading: boolean;
}
