import { Pipe, PipeTransform } from '@angular/core';

/**
 * Sucht die im Suchbegriff 'search' enthaltenen Teile in einem Text und umgibt diese mit einem <b> -Tag.
 * Die gefundenen Teile im Text werden dadurch dicker am Bildschirm agezeigt.
 * Beispiel:
 *    Text: 'Hallo Welt', Suchbegriff: 'hallo
 *    formattiert zu: '<b>Hallo</b> Welt'
 */
@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    // Mapping Tabelle für diakritsische Zeichen
    private accentMap = {
        ae: '(ae|æ|ǽ|ǣ)',
        a: '(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)',
        c: '(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)',
        e: '(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)',
        i: '(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)',
        n: '(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)',
        o: '(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)',
        u: '(u|ú|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)'
    };

    /**
     * Sucht in einem Textstring nach den Suchbegriffen 'search'
     * und erstzt gefundene Stellen im Text durch den, von <b> -Tags umgebenen Suchbegriff.
     * Sonderzeichen aus dem Suchbegriff werde vor dem Aufruf entfernt.
     * @param text Text, in welchem Teile markiert werden sollen
     * @param search Mehrteiliger Suchstring als Grundlage für das Markieren
     */
    transform(text: string, search: string): string {
        let highlightText = text;
        const searchStrings = search.split(' ').filter((searchString) => searchString.length > 0);

        searchStrings.forEach((searchString) => {
            // Bildet aus den einzelnen Suchstrings ein Pattern für die Suche im Text
            const pattern = searchString
                // Escape Suchstring
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                // Mehrteilige Suchstrings bei Leerzeichen teilen
                .split(' ')
                // Teile des Suchstring müssen mindestens 1 Zeichen lang sein
                .filter((item) => item.length > 0);

            // Regex der Mappingtabelle erstellen
            const accentRegex = new RegExp(Object.keys(this.accentMap).join('|'), 'g');

            // Suchstrings mit Regex der Mappingtabelle vergleichen und Zeichen austauschen
            const regex = new RegExp(
                pattern
                    .map((part) => {
                        return part.toLowerCase().replace(accentRegex, (match) => this.accentMap[match] || match);
                    })
                    .join('|'),
                'gi'
            );

            // Vergleich mit des überarbeiteten Suchstring mit dem Text um Highlighting durchzuführen
            highlightText = highlightText.replace(regex, (match) => `<b>${match}</b>`);
        });

        return highlightText;
    }
}
