import { Pipe, PipeTransform } from '@angular/core';
import { IdentitaesnachweisEnum } from '@shared/models/enums';

@Pipe({
    name: 'identitaetsNachweis'
})
export class IdentitaetsNachweisPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value IdentitaetsnachweisEnum
     */
    transform(value: IdentitaesnachweisEnum): string {
        switch (value) {
            case IdentitaesnachweisEnum.Fuehrerschein:
                return 'Führerschein';
            case IdentitaesnachweisEnum.Reisepass:
                return 'Reisepass';
            case IdentitaesnachweisEnum.Personalausweis:
                return 'Personalausweis';
            case IdentitaesnachweisEnum.AndereUrkunde:
                return 'Andere Urkunde';
            case IdentitaesnachweisEnum.IdAustria:
                return 'ID-Austria';
            case IdentitaesnachweisEnum.Antragscode:
                return 'Antragscode';
            default:
                return '';
        }
    }
}
