/**
 * Konstanten für die Umwandlung von Date-Objekten zu Strings mit unterschiedlich formatierten Datums- und Zeitwerten
 */
export class DateUtilsConstants {
    static readonly DATETIME_FORMAT_STRING = 'dd.MM.YYYY, HH:mm:ss';
    static readonly DATETIME_FORMAT_STRING_WITHOUT_SECONDS = 'dd.MM.YYYY, HH:mm';
    static readonly TIME_FORMAT_STRING = 'HH:mm:ss';
}

/**
 * Konstanten für die Detailsuche im Wahlbestand
 */
export class WahlDetailsuche {
    static readonly SPRENGEL_NUMMER = 'sprengelNummer';
    static readonly NAME = 'name';
    static readonly GEBURTSDATUM = 'geburtsdatum';

    static readonly WAHLBEZUGSADRESSE = 'wahlbezugsadresse';
    static readonly STRASSE = 'strasse';
    static readonly HAUSNUMMER = 'hausnummer';
    static readonly PLZ = 'postleitzahl';
    static readonly ORT = 'ort';

    static readonly WAHLKARTENABO = 'wahlkartenabo';
    static readonly ZUSTELLADRESSE_VORHANDEN = 'zustellAdresse';
    static readonly PERSONENTYP = 'personenTyp';
    static readonly STATUS = 'status';
    static readonly DATENABGLEICH = 'datenabgleich';
    static readonly ABGLEICHDATUM = 'abgleichdatum';

    static readonly OFFENE_BEARBEITUNGEN = 'offeneBearbeitungen';
    static readonly WK_STATUS = 'wkStatus';
    static readonly WK_STATUS_ZEITRAUM = 'wkStatusZeitraum';
    static readonly ANTRAGSART = 'antragsArt';
    static readonly AUTHENTIFIZIERUNGSART = 'authentifizierungsArt';
    static readonly ZUSTELLART = 'zustellArt';
    static readonly BES_WAHLBEHOERDE = 'besondereWahlbehoerde';
    static readonly EINSCHREIBNUMMER = 'einschreibnummer';
    static readonly WK_NOTIZ = 'wkNotiz';
    static readonly WK_SCHABLONE = 'wkSchablone';
}

/**
 * Konstanten für die Kontaktmanagement Detailsuche
 */
export class KontaktmanagementDetailsuche {
    static readonly IST_REGISTERGEBUNDEN = 'istRegistergebunden';
    static readonly NOTIZ = 'notiz';
    static readonly FIRMENBUCH_NR = 'firmenbuchNr';
    static readonly UID_NUMMER = 'uidNummer';
    static readonly POSTLEITZAHL = 'postleitzahl';
    static readonly GKZ = 'gemeindekennziffer';
    static readonly STAAT = 'staatISO3';
    static readonly ANSPRECHPARTNER_FUNKTION = 'ansprFunktion';
    static readonly GRUPPE = 'gruppe';
    static readonly TAG = 'tag';

    static readonly SEARCH_KEY = 'searchKey';
    static readonly SEARCH_RESULT_KEY = 'searchResultKey';
    static readonly GRUPPE_KEY = 'gruppeKey';
}

/**
 * Konstanten für die Seitenmenü-Einstellungen in Berichten
 */
export class SidemenuSettings {
    // Allgemein
    static readonly SPRENGEL_NUMMER = 'sprengelNummer';

    // Wählerinformation
    static readonly WAHLKARTENABO = 'ohneWahlkartenabo';
    static readonly WAHLKARTENWAEHLER = 'mitWahlkartenwaehler';

    static readonly MIT_FREIMACHUNGSVERMERK = 'mitFreimachungsvermerk';
    static readonly MIT_ANTRAGSCODE = 'mitAntragscode';
    static readonly ADRESSE_LINKS = 'adresseLinks';

    // Adressetiketten
    static readonly VORLAUF = 'vorlauf';

    // Wahlkarte
    static readonly SEITENVERSCHIEBUNG_HORIZONTAL = 'wkSeitenverschiebungHorizontal';
    static readonly SEITENVERSCHIEBUNG_VERTIKAL = 'wkSeitenverschiebungVertikal';
    static readonly UNTERSCHRIFT = 'wkUnterschrift';
    static readonly UNTERSCHRIFTEN_AVAILABLE = 'unterschriftenAvailable';
    static readonly AUSNAHME = 'wkAusnahme';
    static readonly BLANKO = 'wkBlanko';

    /**
     * Einstellungen die beim Druck, Nachdruck und Sammeldrucke von Wahlkarten geladen werden müssen
     */
    static readonly WAHLKARTE_EINSTELLUNGEN = [
        SidemenuSettings.SEITENVERSCHIEBUNG_HORIZONTAL,
        SidemenuSettings.SEITENVERSCHIEBUNG_VERTIKAL,
        SidemenuSettings.UNTERSCHRIFT,
        SidemenuSettings.AUSNAHME,
        SidemenuSettings.BLANKO
    ];
}

/**
 * Konstanten für die Bearbeitungs-Sektionen
 */
export class EditSectionConstants {
    // Personenverwaltung
    static readonly KONTAKTE_SUCHE_SPEICHERN = 'kontakte-suche-speichern';

    static readonly PERSON_BASISDATEN = 'person-basisdaten';
    static readonly PERSON_ADRESSEN = 'person-adressen';
    static readonly PERSON_KONTAKTE = 'person-kontakte';
    static readonly PERSON_NOTIZEN = 'person-notizen';
    static readonly PERSON_BRIEFKOPF = 'person-briefkopf';
    static readonly PERSON_ERSTELLEN = 'person-erstellen';

    static readonly UNTERNEHMEN_BASISDATEN = 'unternehmen-basisdaten';
    static readonly UNTERNEHMEN_ADRESSEN = 'unternehmen-adressen';
    static readonly UNTERNEHMEN_KONTAKTE = 'unternehmen-kontakte';
    static readonly UNTERNEHMEN_NOTIZEN = 'unternehmen-notizen';
    static readonly UNTERNEHMEN_ANSPRECHPARTNER = 'unternehmen-ansprechpartner';
    static readonly UNTERNEHMEN_BRIEFKOPF = 'unternehmen-briefkopf';
    static readonly UNTERNEHMEN_ERSTELLEN = 'unternehmen-erstellen';

    static readonly GRUPPE_CREATE = 'gruppe-erstellen';
    static readonly GRUPPE_EDIT = 'gruppe-bearbeiten';

    // Konfiguration
    static readonly CONFIGURATION_MANDANTENSPEZIFISCHE_EINSTELLUNGEN =
        'configuration-mandantenspezifischeWahleinstellungen';
    static readonly CONFIGURATION_EINSTELLUNGEN_JE_WAHLTYP = 'configuration-einstellungenJeWahltyp';
    static readonly CONFIGURATION_EINSPRUCHSSTELLE = 'configuration-einspruchsstelle';
    static readonly CONFIGURATION_EINSPRUCHSSTELLE_OEFFNUNGSZEITEN = 'configuration-einspruchsstelle-oeffnungszeiten';

    // User
    static readonly USER_PERSOENLICHE_EINSTELLUNGEN = 'user-persoenlicheEinstellungen';

    // Weitere Apps
    static readonly WEITERE_APPS_SPRENGELGRUPPE = 'weitere-apps-sprengelgruppe';
    static readonly WEITERE_APPS_SPRENGEL_EDIT = 'weitere-apps-sprengel-edit';
    static readonly WEITERE_APPS_SPRENGEL_CREATE = 'weitere-apps-sprengel-create';
    static readonly WEITERE_APPS_ADRESSZUORDNUNG = 'weitere-apps-adresszuordnung';
    static readonly WEITERE_APPS_ADRESSZUORDNUNG_MANUELL = 'weitere-apps-adresszuordnung-manuell';
    static readonly WEITERE_APPS_WAHLLOKALE_CREATE = 'weitere-apps-adresszuordnung';
    static readonly WEITERE_APPS_WAHLLOKALE_EDIT = 'weitere-apps-adresszuordnung';

    // Wahl
    static readonly WAHL_WAEHLERVERZEICHNIS = 'wahl-waehlerverzeichnis';
    static readonly WAHL_ZENTRALE_SERVICES = 'wahl-zentrale-services';
    static readonly WAHL_WAHLKARTE = 'wahl-wahlkarte';
    static readonly WAHL_PERSON_HINEINREKLAMIEREN = 'wahl-person-hineinreklamieren';
    static readonly WAHL_KLAERUNGSFALL_EDIT = 'wahl-klaerungsfall-edit';
    static readonly WAHL_PERSON_KORRIGIEREN = 'wahl-person-korrigieren';
    static readonly WAHL_KONTAKTDATEN_AENDERN = 'wahl-kontaktdaten-aendern';
    static readonly WAHL_UNTERSTUETZUNGSERKLAERUNG_EDIT = 'wahl-unterstuetzungserklaerung-edit';
    static readonly WAHL_IMPORT = 'wahl-import';

    static readonly WAHL_CREATE_WAHLKARTENANTRAG = 'wahl-create-wahlkartenantrag';
    static readonly WAHL_EDIT_WAHLKARTENANTRAG = 'wahl-edit-wahlkartenantrag';

    // Shared
    static readonly SHARED_FILE_UPLOAD = 'shared-file-upload';
    static readonly SHARED_WIDGET_CREATE = 'shared-widget-create';
    static readonly SHARED_BEGRUENDUNG = 'shared-begruendung';
    static readonly SHARED_ZUSTELLADRESSE = 'shared-zustelladresse';
}

export class ValidationRegex {
    /**
     * Validator für Email
     */

    // Regex für die Ausführen mit .execute
    // Unit Tests dafür befinden sich aktuell nur in ansprechpartner-dialog-component.spec.ts und erreichbarkeit-dialog.component.spec.ts für die Forms, die dort verwendet werden.
    // Unit Tests werden im Nachhinein in einen custom Validator spec file ausgelagert
    static readonly EMAIL_VALIDATOR_REGEX: RegExp =
        /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    // Regex für die Validierungen in forms
    // (.source gibt die regExp als string '[A-Za-z0-9äÄöÖüÜ._+%-]+@[A-Za-zäÄöÖüÜ0-9._+%-]+\.[a-z]{2,3}' zurück ohne / am Anfang und am Ende, sodass das in den Forms verwendbar ist)
    static readonly EMAIL_VALIDATOR: string = ValidationRegex.EMAIL_VALIDATOR_REGEX.source;

    /**
     * Validator für Telefon
     */

    // Regex für die Ausführen mit .execute
    // Als zweiten Parameter kann man Regex-Flags übergeben, z.B. 'i' für case-insensitive
    static readonly TELEFON_VALIDATOR_REGEX: RegExp = new RegExp(/[0-9+-/() ]+/);

    // Regex für die Validierungen in forms
    // (.source gibt die regExp als string '[0-9\+\-\/\(\ \)]+' zurück ohne / am Anfang und am Ende, sodass das in den Forms verwendbar ist)
    static readonly TELEFON_VALIDATOR: string = ValidationRegex.TELEFON_VALIDATOR_REGEX.source;

    // Validator für UID
    static readonly UID_NUMMER_VALIDATOR: RegExp =
        /\b(([A][T][U][0-9]{8}))\b|\b(([G][B][0-9]{9}|[G][B][G][D][0-9]{3})\b|\b([G][B][H][A][0-9]{3}))\b|\b((([N][L]|[E][S])([0-9]{9}|[0-9]{12}|[A-Z]{9}|[A-Z]{12})))\b|\b(((?!ES|NL|GB))([A-Z]{2}[0-9]{8,12}))\b/;

    // Regex für die Validierung der Firmenbuchnummer
    static readonly FIRMENBUCHNUMMER_VALIDATOR = '([0-9]{5,6}[abdfghikmpstvwxyz]{1})';

    // Lest die Datei Endung einer Datei aus z.B jpg oder png
    static readonly FILE_EXTENSION_READER: RegExp = /(?:.([^.]+))?$/;

    // Regex für Nummern
    static readonly NUMBER_REGEX = '^[0-9]*';
}

/**
 * Konstanten für die Adressierungsoption
 */
export class Adressierung {
    static readonly GEMEINDENAME = 'Gemeindename';
    static readonly GEMEINDENAME_UND_ORTSNAME = 'GemeindenameUndOrtsname';
    static readonly ORTSNAME = 'Ortsname';
    static readonly ORTSNAME_ODER_GEMEINDENAME = 'OrtsnameOderGemeindename';
}

/**
 * Konstanten für die Staatsangehörigkeiten
 */
export class Staatsangehoerigkeiten {
    static readonly OESTERREICH_ISO3 = 'AUT';
}

export class KontaktmanagementConstants {
    static readonly quickSearchIdKey = 'quicksearchInput';
    static readonly familiennameIdKey = 'familiennameInput';
    static readonly aenderungsgrundKey = 'aenderungsgrundInput';

    static readonly SUCHERGEBNIS_MAX_MAIL_LENGTH = 2000;
    // Standard Änderungsgrund
    static readonly STANDARD_AENDERUNGSGRUND_TEXT = 'Neuanlage Kontaktmanagement';

    static readonly HISTORISCHE_ADRESSE_CLOCK_ICON = 'fa-clock';
    static readonly HISTORISCHE_CLOCK_ICON_TOOLTIP = 'Historische Adresse';
    static readonly PAPERCLIP_ICON_NAME = 'fa-paperclip';
    static readonly PAPERCLIP_ICON_TOOLTIP = 'Zustimmungsdokument hinterlegt';

    // Kontaktperson Icons
    static readonly FA_USER = 'fa-user';
    static readonly FA_USER_FG_GRAY = 'fa-user fg-gray';
    static readonly FA_USER_PRIMARY = 'fa-user fg-primary';
    static readonly FA_USER_MINUS = 'fa-user-minus';
    static readonly FA_USER_XMARK = 'fa-user-xmark';
    static readonly FA_USER_XMARK_FG_GRAY = 'fa-user-xmark fg-gray';
    static readonly FA_INDUSTRY = 'fa-industry';
    static readonly FA_INDUSTRY_PRIMARY = 'fa-industry fg-primary';
    static readonly FA_INDUSTRY_FG_GRAY = 'fa-industry fg-gray';
    static readonly FA_INDUSTRY_XMARK = 'fa-k5-industry-xmark';
    static readonly FA_INDUSTRY_XMARK_LG_PRIMARY_MAIN = 'fa-k5-industry-xmark-lg bg-primary-main';
    static readonly FA_INDUSTRY_XMARK_FG_GRAY = 'fa-k5-industry-xmark bg-gray h-4';
    static readonly FA_INDUSTRY_XMARK_LG_BG_GRAY = 'fa-k5-industry-xmark-lg bg-gray';

    // Erreichbarkeit Types mit Icon Bezeichnungen
    static readonly FA_ANSPRECHPARTNER_ICONSET = ['0', 'fa-user'];
    static readonly FA_ZMR_HAUPTWOHNSITZ_ICONSET = ['1', 'fa-house'];
    static readonly FA_ZMR_NEBENWOHNSITZ_ICONSET = ['2', 'fa-umbrella-beach'];
    static readonly FA_ERN_P_ANSCHRIFT_ICONSET = ['3', 'fa-location-dot'];
    static readonly FA_UR_FIRMENSITZ_ICONSET = ['4', 'fa-house'];
    static readonly FA_UR_ZUSTELLADRESSE_ICONSET = ['5', 'fa-house-building'];
    static readonly FA_MANUELL_HAUPTADRESSE_ICONSET = ['6', 'fa-location-dot'];
    static readonly FA_MANUELL_ZUSTELLADRESSE_ICONSET = ['7', 'fa-location-dot'];
    static readonly FA_TELEFON_ICONSET = ['8', 'fa-phone-alt'];
    static readonly FA_MOBILE_ICONSET = ['9', 'fa-mobile-alt'];
    static readonly FA_FAX_ICONSET = ['10', 'fa-fax'];
    static readonly FA_EMAIL_ICONSET = ['11', 'fa-at'];
    static readonly FA_WEBSITE_ICONSET = ['12', 'fa-browser'];
}

/**
 * Properties für die Weiterleitung auf Identity
 */
export class IdentityProperties {
    static readonly BENUTZERPROFIL = 'profile.management.account';
    static readonly EDIT_PROFILBILD = 'profile.management.user_image_edit';
    static readonly PASSWORT = 'profile.management.password';
    static readonly MFA = 'profile.management.mfa';
    static readonly BASEURL = 'iss';
    static readonly PROFILBILD = 'picture';
}

/**
 * Konstanten für die Widgets
 */
export class WidgetConstants {
    // Konstanten für das Willkommenswidget
    static readonly MORNING_HOUR = 10;
    static readonly AFTERNOON_HOUR = 14;
    static readonly EVENING_HOUR = 17;
    static readonly NIGHT_HOUR = 0;

    static readonly SETTINGS_RSS_KEY = 'RssFeed';

    static readonly BILD_PROPERTY_NAME = 'Bild';
    static readonly URL_PROPERTY_NAME = 'Url';
    static readonly NAME_PROPERTY_NAME = 'Name';
    static readonly BETREIBER_FALLBACK_IMG = 'assets/img/k5next_logo_minimal.svg';
}

/**
 * Konstanten für die Avatar Fotos
 */
export class AvatarPhotoConstants {
    static readonly BACKGROUND_COLORS = [
        '#c219df',
        '#8f6a11',
        '#427f63',
        '#a428ff',
        '#2d7d7c',
        '#3960fd',
        '#b915f2',
        '#bf4f6e',
        '#cc491a',
        '#7967a0',
        '#d700c1',
        '#587a4c',
        '#e90107',
        '#bf07db',
        '#2d66fc',
        '#c911bc',
        '#7d6e64',
        '#df2233',
        '#c84b23',
        '#b715f8',
        '#ab5570',
        '#c63385',
        '#a75f2f',
        '#7656f3',
        '#ae5487',
        '#7a7766',
        '#e6232a',
        '#e01545',
        '#db3421',
        '#e1130a',
        '#8852f9',
        '#bc41c1',
        '#108175',
        '#a914fe',
        '#4c7978',
        '#cd4610',
        '#467f0e',
        '#8a6d39',
        '#e1070e',
        '#7067c9',
        '#3a8250',
        '#7d7700',
        '#e5025d',
        '#717272',
        '#d31c8d',
        '#e71257',
        '#a66252',
        '#cf149a',
        '#ba533f',
        '#1e799d',
        '#cd3c09',
        '#d52468',
        '#9b38f4',
        '#3669ee',
        '#e8171f',
        '#29822d',
        '#5562ec',
        '#e00f62',
        '#dc1389',
        '#5f757b',
        '#d7109a',
        '#e2134e',
        '#ac4e92',
        '#b55a2c',
        '#a244da',
        '#b921e4'
    ];

    static readonly BACKGROUND_COLOR_AMOUNT = this.BACKGROUND_COLORS.length;
}

/**
 * Konstanten für das Logging
 */
export class LoggingConstants {
    // Name der Operationen
    static readonly KM_ZUSTIMMUNGSDOKUMENT_GENERATED = 'UI_KM_Zustimmungsdokument_Generate';
    static readonly KM_ZUSTIMMUNGSDOKUMENT_UPLOADED = 'UI_KM_Zustimmungsdokument_Upload';
    static readonly KM_DETAILSUCHE_SPEICHERN = 'UI_KM_Detailsuche_Speichern';
    static readonly KM_SCHNELLSUCHE = 'UI_KM_Schnellsuche';
    static readonly KM_SUCHE_SHARED = 'UI_KM_Suche_Shared';
    static readonly KM_TAG_USED = 'UI_KM_Tag_Used';
    static readonly KM_TAG_CREATED = 'UI_KM_Tag_Created';
    static readonly KM_SEARCHCRITERIA_USED = 'UI_KM_Suchkriterium_Used';
    static readonly KM_SUCHKRITERIUM_ERWEITERT_USED = 'UI_KM_Suchkriterium_Erweitert_Used';
    static readonly KM_SCHNELLSUCHE_NOTSUFFICIENT = 'UI_KM_Schnellsuche_NotSufficient';
    static readonly KM_SCHNELLSUCHE_SUFFICIENT = 'UI_KM_Schnellsuche_Sufficient';
    static readonly KM_PERSONEN_CLEARING = 'UI_KM_Personen_Clearing'; // Feature existiert noch nicht
    static readonly UI_WIDGET_ADDED = 'UI_Widget_Added'; // Feature existiert noch nicht
    static readonly UI_ELEMENT_CLICKED = 'UI_Element_Clicked';
    static readonly UI_KM_DETAILSUCHE_ZEILE_CLICKED_TOEXPAND = 'UI_KM_Detailsuche_Clicked_Zeile_Zu_Erweitern';
    static readonly UI_KM_DETAILSUCHE_DETAILS_CLICKED_WITH_EXPANDED_ZEILE =
        'UI_KM_Detailsuche_Details_Clicked_Mit_Erweiterter_Zeile';
    static readonly UI_KM_DETAILSUCHE_DETAILS_CLICKED_WITHOUT_EXPANDED_ZEILE =
        'UI_KM_Detailsuche_Details_Clicked_Ohne_Erweiterter_Zeile';
    static readonly KM_GRUPPE_CREATED = 'UI_KM_Gruppe_Created';
    static readonly KM_EXCEL_EXPORTED = 'UI_KM_Excel_Exported';
    static readonly KM_ADRESSZUORDNUNG_EXPORTED = 'UI_KM_ADRESSZUORDNUNG_EXPORTED';

    // Zusätzliche Parameter
    static readonly KEY_NAME = 'Name';
    static readonly WILLKOMMEN_WIDGET = 'Willkommen Widget';
    static readonly COMPONENT_NAME = 'ComponentName';
    static readonly ELEMENT_ID = 'ElementId';
    static readonly ELEMENT_NAME = 'ElementName';
    static readonly CLICKED_ELEMENT_ID = 'ClickedElementId';
    static readonly CLICKED_ELEMENT_NAME = 'ClickedElementName';

    // Propertyname der Detailsuchkriterien
    static readonly ANSPRFUNKTION = 'Ansprechpartner Funktion';
    static readonly ANSPRNAME = 'Ansprechpartner Name';
    static readonly ART = 'Art';
    static readonly ERREICHBARKEIT = 'Erreichbarkeit';
    static readonly FIRMENBUCHNUMMER = 'Fimenbuchnummer';
    static readonly GEBURTSDATUM = 'Geburtsdatum';
    static readonly GEMEINDEKENNZIFFER = 'Gemeindekennziffer';
    static readonly ISTREGISTERGEBUNDEN = 'Registergeprüft';
    static readonly NAME = 'Name';
    static readonly NOTIZ = 'Notiz';
    static readonly ORIENTIERUNGSNUMMER = 'Hausnummer';
    static readonly ORT = 'Ort';

    // Erweiterte Suchkriterien
    static readonly ERWEITERTE_SUCHPARAMETERN: { [key: string]: string } = {
        ['OR']: ',',
        ['RANGE/MINUS/VON/BIS']: '-',
        ['GESTERN']: 'gestern',
        ['VORGESTERN']: 'vorgestern',
        ['HEUTE']: 'heute',
        ['MORGEN']: 'morgen',
        ['DOT']: '.',
        ['PLUS']: '+'
    };
}

/**
 * Konstanten für die Notifications
 */
export class NotificationConstants {
    static readonly TYP_K5NEXT_VERWEIS = 'k5NextVerweis';
    static readonly TYP_LINK = 'Link';
    static readonly TYP_INFORMATION = 'Information';

    static readonly TYP_GESPEICHERTE_SUCHE = 'gespeicherte-suche';
    static readonly TYP_SUCHERGEBNIS = 'suchergebnis';
    static readonly TYP_GRUPPE = 'gruppe';
}

export class JaNeinAlleConstants {
    static readonly JA = 'ja';
    static readonly NEIN = 'nein';
    static readonly ALLE = 'alle';
}

/**
 * Konstanten für File Upload
 */
export class FileUploadConstants {
    static readonly FILE_UPLOAD_EMPTY_TEXT = 'Keine Datei ausgewählt';

    static readonly MIME_TYPE_JPEG = 'image/jpeg';
    static readonly MIME_TYPE_JPG = 'image/jpg';
    static readonly MIME_TYPE_PNG = 'image/png';
    static readonly MIME_TYPE_SVG = 'image/svg';
    static readonly MIME_TYPE_GIF = 'image/gif';

    static readonly FILE_EXTENSION_JPEG = 'jpeg';
    static readonly FILE_EXTENSION_JPG = 'jpg';
    static readonly FILE_EXTENSION_PNG = 'png';
    static readonly FILE_EXTENSION_SVG = 'svg';
    static readonly FILE_EXTENSION_GIF = 'gif';
}

/**
 * Konstanten für die Schnellsuche
 */
export class SchnellsucheConstants {
    static readonly WAHLBESTANDSCHNELLSUCHEDATEN_TEXT = 'wahlbestandSchnellsucheDaten';
    static readonly SUCHERGEBNISSE_TEXT = 'suchergebnisse';

    // Regex für die Erkennung von Antragscodes
    static readonly ANTRAGSCODE_REGEXP: RegExp = /^\S{4}\s?\S{4}\s?\S{4}$/;
}

export class SharedConstants {
    static readonly STRASSE_NOT_SELECTED_ERROR_MESSAGE = 'Bitte vorher eine Straße auswählen';
}
