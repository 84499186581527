import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KontaktmanagementConstants } from '@shared/constants/shared-constants';
import { SchnellsucheErgebnisElement } from '../../models/schnellsucheErgebnisElement';
import { SchnellsucheErgebnisElementZusatz } from '@shared/models/schnellsucheErgebnisElementZusatz';

/**
 * Komponente für die Darstellung der Vorschläge der Schnellsuche.
 */
@Component({
    selector: 'k5-quick-search-item',
    templateUrl: './quick-search-item.component.html',
    styleUrls: ['./quick-search-item.component.scss']
})
export class QuickSearchItemComponent {
    // Schnittstelle für die Konstante
    KM_CONST = KontaktmanagementConstants;

    @Input()
    item: SchnellsucheErgebnisElement;

    @Output()
    selected: EventEmitter<Event> = new EventEmitter();

    /**
     * Behandelt die Auswahl eines Schnellsuchelements.
     * @param event Event
     */
    onClick(event: Event) {
        this.selected.emit(event);
    }

    /**
     * Überprüft welcher Typ der jeweilige Zusatz in der Schnellsuche ist und fügt das dazugehörige Icon hinzu
     * @param zusatz Zusatz des SchnellsucheErgebnisElements
     * @returns einen HTML-String mit Icon und Text für den Zusatz
     */
    buildZusatz(zusatz: SchnellsucheErgebnisElementZusatz): string {
        const classTextStarting: string = '<i class="fa-duotone';
        let iconString: string | null;
        const classTextMiddle: string = 'fg-gray" aria-hidden="true"></i> <span>';
        const classTextEnding: string = '</span>';

        // Icon setzen
        switch (zusatz.art.toString()) {
            case this.KM_CONST.FA_ANSPRECHPARTNER_ICONSET[0]:
                iconString = this.KM_CONST.FA_ANSPRECHPARTNER_ICONSET[1];
                break;
            case this.KM_CONST.FA_ZMR_HAUPTWOHNSITZ_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_ZMR_HAUPTWOHNSITZ_ICONSET[1];
                break;
            case this.KM_CONST.FA_ZMR_NEBENWOHNSITZ_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_ZMR_NEBENWOHNSITZ_ICONSET[1];
                break;
            case this.KM_CONST.FA_ERN_P_ANSCHRIFT_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_ERN_P_ANSCHRIFT_ICONSET[1];
                break;
            case this.KM_CONST.FA_UR_FIRMENSITZ_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_UR_FIRMENSITZ_ICONSET[1];
                break;
            case this.KM_CONST.FA_UR_ZUSTELLADRESSE_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_UR_ZUSTELLADRESSE_ICONSET[1];
                break;
            case this.KM_CONST.FA_MANUELL_HAUPTADRESSE_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_MANUELL_HAUPTADRESSE_ICONSET[1];
                break;
            case this.KM_CONST.FA_MANUELL_ZUSTELLADRESSE_ICONSET[0]:
                iconString = !this.item.istStandardadresseAktiv // Wenn die Adresse inaktiv ist, wird sie als 'historisch' gekennzeichnet
                    ? this.KM_CONST.HISTORISCHE_ADRESSE_CLOCK_ICON
                    : this.KM_CONST.FA_MANUELL_ZUSTELLADRESSE_ICONSET[1];
                break;
            case this.KM_CONST.FA_TELEFON_ICONSET[0]:
                iconString = this.KM_CONST.FA_TELEFON_ICONSET[1];
                break;
            case this.KM_CONST.FA_MOBILE_ICONSET[0]:
                iconString = this.KM_CONST.FA_MOBILE_ICONSET[1];
                break;
            case this.KM_CONST.FA_FAX_ICONSET[0]:
                iconString = this.KM_CONST.FA_FAX_ICONSET[1];
                break;
            case this.KM_CONST.FA_EMAIL_ICONSET[0]:
                iconString = this.KM_CONST.FA_EMAIL_ICONSET[1];
                break;
            case this.KM_CONST.FA_WEBSITE_ICONSET[0]:
                iconString = this.KM_CONST.FA_WEBSITE_ICONSET[1];
                break;
            default:
                iconString = null;
                break;
        }
        return (
            classTextStarting +
            ' ' +
            (iconString === null ? '' : iconString + ' ') +
            classTextMiddle +
            (zusatz.text ? zusatz.text : '') +
            classTextEnding
        );
    }
}
