<div class="flex h-full flex-col">
    <!-- Detailsuche header -->
    <div class="detailSearch-header flex h-20 items-center justify-center p-4">
        <span class="grow text-2xl">
            {{ title }}
        </span>
        <button
            type="button"
            aria-label="Schließen"
            matTooltip="Schließen"
            mat-icon-button
            (click)="closeDetailSearch()"
            data-cy="detail-search-wahl-close-button"
        >
            <mat-icon fontSet="fa-solid" fontIcon="fa-chevron-right" class="fg-primary"></mat-icon>
        </button>
    </div>

    <!-- Detailsuche scrollbarer Kriterienbereich -->
    <div class="grow overflow-auto">
        <form
            aria-label="detailsuche-form"
            aria-hidden="true"
            id="search-criteria-form"
            class="p-4"
            novalidate
            [formGroup]="searchCriteriaForm"
            novalidate
            (keydown.enter)="submitOnEnter()"
            (click)="clearQuickSearchPara()"
        >
            <!-- Suchkriterien Waehler: -->
            <div class="pt-4 text-xl">
                <span>Person</span>
            </div>
            <mat-form-field class="w-full">
                <mat-label>Sprengelnummer</mat-label>
                <mat-select
                    id="sprengelnummer"
                    formControlName="sprengelNummer"
                    data-cy="detail-search-wahl-sprengelnummer-select"
                >
                    <mat-option id="sprengel-option" [value]=""></mat-option>
                    <mat-option
                        id="sprengel-option"
                        *ngFor="let sprengelInformation of sprengelInformation$ | async"
                        [value]="sprengelInformation.sprengelNummer"
                        data-cy="detail-search-wahl-sprengelnummer-option"
                    >
                        {{ sprengelInformation.sprengelNummer }} {{sprengelInformation.sprengelBezeichnung}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Name: erweitertes Suchkriterium -->
            <mat-form-field *ngIf="isVisible(WAHLDETAILSUCHE.NAME)" class="w-full">
                <mat-label id="label-name">Name</mat-label>
                <input
                    id="name"
                    matInput
                    type="text"
                    formControlName="name"
                    autocomplete="off"
                    aria-labelledby="label-name"
                    data-cy="detail-search-wahl-name-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.name"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.name.reset()"
                    data-cy="detail-search-wahl-name-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
                <mat-error>
                    {{ formErrorService.getFormControlErrorMessages(searchCriteriaForm.controls.name, 'Name') }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label id="label-geburtsdatum">Geburtsdatum</mat-label>
                <input
                    id="geburtsdatum"
                    matInput
                    formControlName="geburtsdatum"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                    aria-labelledby="label-geburtsdatum"
                    data-cy="detail-search-wahl-geburtsdatum-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.geburtsdatum"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.geburtsdatum.reset()"
                    data-cy="detail-search-wahl-geburtsdatum-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label id="label-wahlbezugsadresse">Wahlbezugsadresse</mat-label>
                <input
                    id="wahlbezugsadresse"
                    type="text"
                    matInput
                    formControlName="wahlbezugsadresse"
                    [matAutocomplete]="auto"
                    aria-labelledby="label-wahlbezugsadresse"
                    data-cy="detail-search-wahl-wahlbezugsadresse-input"
                />
                <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    data-cy="detail-search-wahl-wahlbezugsadresse-autocomplete"
                >
                    <mat-option
                        *ngFor="let adresse of filteredWahlbezugsadresse$ | async"
                        (onSelectionChange)="selectWahlbezugsadresse(adresse)"
                        [value]="adresse?.adresse"
                        data-cy="detail-search-wahl-wahlbezugsadresse-option"
                    >
                    <span [innerHTML]="adresse.strasse | highlight: toHighlight"></span>
                    <span *ngIf="showHausnummer" [innerHTML]="' ' + (adresse.hausnummer | highlight: toHighlight)"></span>
                    <span *ngIf="showPlz" [innerHTML]="' ' + (adresse.plz | highlight: toHighlight)"></span>
                    <span [innerHTML]="' ' + (adresse.ort | highlight: toHighlight)"></span>
                    </mat-option>
                </mat-autocomplete>
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.controls.wahlbezugsadresse.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.wahlbezugsadresse.reset()"
                    data-cy="detail-search-wahl-wahlbezugsadresse-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Wahlkartenabo</mat-label>
                <mat-select
                    id="wahlkartenabo"
                    formControlName="wahlkartenabo"
                    data-cy="detail-search-wahl-wahlkartenabo-select"
                >
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of NULLABLE_BOOLEAN | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-wahlkartenabo-option"
                    >
                        {{ selection | nullableBoolean }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Zustelladresse vorhanden</mat-label>
                <mat-select
                    id="zustellAdresse"
                    formControlName="zustellAdresse"
                    data-cy="details-search-wahl-zustelladresse-select"
                >
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of ZUSTELLADRESSE_VORHANDEN | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-zustelladresse-option"
                    >
                        {{ selection | zustelladresseVorhanden }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Personentyp</mat-label>
                <mat-select
                    id="personentyp"
                    formControlName="personenTyp"
                    multiple
                    data-cy="detail-search-wahl-personentyp-select"
                >
                    <mat-option
                        *ngFor="let personenTyp of PERSONEN_TYP | enumKeys"
                        [value]="personenTyp"
                        data-cy="detail-search-wahl-personentyp-option"
                    >
                        {{ personenTyp | personenTyp }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Status</mat-label>
                <mat-select id="status" formControlName="status" multiple data-cy="detail-search-wahl-status-select">
                    <div *ngFor="let status of WAEHLER_STATUS | enumKeys">
                        <mat-option [value]="status" data-cy="detail-search-wahl-status-option">
                            {{ status | waehlerStatus }}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Datenabgleich</mat-label>
                <mat-select
                    id="datenabgleich"
                    formControlName="datenabgleich"
                    multiple
                    data-cy="detail-search-wahl-datenabgleich-select"
                >
                    <div *ngFor="let datenabgleichValue of DATENABGLEICH | enumKeys">
                        <mat-option [value]="datenabgleichValue" data-cy="detail-search-wahl-datenabgleich-option">
                            {{ datenabgleichValue | datenabgleich }}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label id="label-abgleichdatum">Abgleichdatum</mat-label>
                <input
                    id="abgleichdatum"
                    matInput
                    formControlName="abgleichdatum"
                    (click)="clearQuickSearchPara()"
                    autocomplete="off"
                    aria-labelledby="label-abgleichdatum"
                    data-cy="detail-search-wahl-abgleichdatum-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.abgleichdatum"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.abgleichdatum.reset()"
                    data-cy="detail-search-wahl-abgleichdatum-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <!-- Suchkriterien Wahlkarte: -->
            <div class="pt-4 text-xl">
                <span>Wahlkarte</span>
            </div>

            <!-- Offene Bearbeitungen -->
            <mat-form-field class="w-full">
                <mat-label>Offene Bearbeitungen</mat-label>
                <mat-select
                    id="offeneBearbeitungen"
                    formControlName="offeneBearbeitungen"
                    data-cy="detail-search-wahl-offene-bearbeitungen-select"
                >
                    <!-- No Option Value wird für das zurücksetzen der Menüeinträge im Wahlbestand benötigt -->
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of OFFENE_BEARBEITUNGEN | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-offene-bearbeitungen-option"
                    >
                        {{ selection | offeneBearbeitungen }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Status Wahlkarte</mat-label>
                <mat-select
                    id="wkStatus"
                    formControlName="wkStatus"
                    name="wkStatus"
                    data-cy="detail-search-wahl-wk-status-select"
                >
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let status of WAHLKARTEN_STATUS | enumKeys"
                        [value]="status"
                        data-cy="detail-search-wahl-wk-status-option"
                    >
                        {{ status | sucheWahlkartenStatus }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="wkStatusSelected()" class="w-full">
                <mat-label id="label-datum">Datum/Zeitpunkt</mat-label>
                <input
                    id="wkStatusZeitraum"
                    matInput
                    type="text"
                    placeholder="07.08.2024 12:00"
                    formControlName="wkStatusZeitraum"
                    autocomplete="off"
                    aria-labelledby="label-datum"
                    data-cy="detail-search-wahl-wk-zeitraum-input"
                />
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Antragsart</mat-label>
                <mat-select formControlName="antragsArt" data-cy="detail-search-wahl-wk-antragsart-select">
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of ANTRAGS_ART | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-wk-antragsart-option"
                    >
                        {{ selection | wkAntragsart }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Identitätsnachweis: erweitertes Suchkriterium -->
            <mat-form-field *ngIf="isVisible(WAHLDETAILSUCHE.AUTHENTIFIZIERUNGSART)" class="w-full">
                <mat-label>Identitätsnachweis</mat-label>
                <mat-select
                    id="authentifizierungsArt"
                    formControlName="authentifizierungsArt"
                    multiple
                    data-cy="detail-search-wahl-identitaetsnachweis-select"
                >
                    <mat-option
                        *ngFor="let identitaetsnachweis of IDENTITAETSNACHWEIS"
                        [value]="identitaetsnachweis.value"
                        data-cy="detail-search-wahl-identitaetsnachweis-option"
                    >
                        {{ identitaetsnachweis.value | identitaetsNachweis }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Zustellart</mat-label>
                <mat-select id="zustellArt" formControlName="zustellArt" data-cy="detail-search-wahl-zustellart-select">
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let zustellart of ZUSTELLARTEN"
                        [value]="zustellart.value"
                        data-cy="detail-search-wahl-zustellart-option"
                    >
                        {{ zustellart.value | wkZustellart }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Besondere Wahlbehörde</mat-label>
                <mat-select
                    id="besondereWahlbehoerde"
                    formControlName="besondereWahlbehoerde"
                    data-cy="detail-search-wahl-bes-wahlbehoerde-select"
                >
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of NULLABLE_BOOLEAN | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-bes-wahlbehoerde-option"
                    >
                        {{ selection | nullableBoolean }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Wahlkarte: erweiterte Suchkriterien -->
            <mat-form-field *ngIf="isVisible(WAHLDETAILSUCHE.EINSCHREIBNUMMER)" class="w-full">
                <mat-label id="label-einschreibnummer">Einschreibnummer</mat-label>
                <input
                    id="einschreibnummer"
                    matInput
                    type="text"
                    formControlName="einschreibnummer"
                    autocomplete="off"
                    aria-labelledby="label-einschreibnummer"
                    data-cy="detail-search-wahl-einschreibnummer-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.einschreibnummer"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.einschreibnummer.reset()"
                    data-cy="detail-search-wahl-einschreibnummer-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="isVisible(WAHLDETAILSUCHE.WK_NOTIZ)" class="w-full">
                <mat-label id="label-wahlkartenNotiz">Notiz zur Wahlkarte</mat-label>
                <input
                    id="wkNotiz"
                    matInput
                    type="text"
                    formControlName="wkNotiz"
                    autocomplete="off"
                    aria-labelledby="label-wahlkartenNotiz"
                    data-cy="detail-search-wahl-wk-notiz-input"
                />
                <button
                    type="button"
                    *ngIf="searchCriteriaForm.value.wkNotiz"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchCriteriaForm.controls.wkNotiz.reset()"
                    data-cy="detail-search-wahl-wk-notiz-clear-button"
                >
                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="isVisible(WAHLDETAILSUCHE.WK_SCHABLONE)" class="w-full">
                <mat-label>Schablone Wahlkarte</mat-label>
                <mat-select
                    id="wkSchablone"
                    formControlName="wkSchablone"
                    data-cy="detail-search-wahl-wk-schablone-select"
                >
                    <mat-option [value]=""></mat-option>
                    <mat-option
                        *ngFor="let selection of NULLABLE_BOOLEAN | enumKeys"
                        [value]="selection"
                        data-cy="detail-search-wahl-wk-schablone-option"
                    >
                        {{ selection | nullableBoolean }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <!-- Aktionsbereich für das Hinzufügen von Suchkriterien und die Ausführung der Suche -->
    <div class="block" [ngClass]="{ 'extended-shadow': extended }">
        <!-- Eigener Bereich für die Suchkriterien, die hinzugefügt werden können -->
        <div class="pt-4" *ngIf="extended">
            <span class="pl-4 text-xl">weitere Suchkriterien</span>

            <mat-selection-list
                #searchCriteria
                (selectionChange)="searchCriteriaSelectionChange($event)"
                data-cy="detail-search-wahl-suchkriterien-list"
            >
                <mat-list-option
                    *ngFor="let searchCriteria of optionalSearchCriteria"
                    [value]="searchCriteria.key"
                    [selected]="searchCriteria.selected"
                    color="primary"
                    data-cy="detail-search-wahl-suchkriterium-option"
                >
                    {{ searchCriteria.name }}
                </mat-list-option>
            </mat-selection-list>
        </div>

        <!-- Button zum Erweitern der Suchkriterien -->
        <button
            matRipple
            class="h-10 w-full hover:cursor-pointer hover:bg-gray-100"
            (click)="toggleSearchCriteriaSelection()"
            data-cy="detail-search-wahl-suchkriterien-button"
        >
            <div *ngIf="!extended; else extendedSearchButton" class="flex grow items-center px-4">
                <i class="fg-primary fa-fw fa-solid fa-lg fa-plus" aria-hidden="true"></i>
                <span class="pl-2">weitere Suchkriterien</span>
                <span class="grow"></span>
                <i class="fa-fw fa-solid fa-lg fa-angle-up text-gray-500" aria-hidden="true"></i>
            </div>
            <ng-template #extendedSearchButton>
                <div class="flex grow items-center px-4">
                    <i class="fg-primary fa-fw fa-duotone fa-lg fa-check" aria-hidden="true"></i>
                    <span class="pl-2">fertig</span>
                    <span class="grow"></span>
                    <i class="fa-fw fa-solid fa-lg fa-angle-down text-gray-500" aria-hidden="true"></i>
                </div>
            </ng-template>
        </button>

        <!-- Trennlinie zwischen Kriterienbereich und Aktionsbereich -->
        <div class="line mx-4 mt-1"></div>

        <div class="flex justify-evenly py-4">
            <button
                mat-stroked-button
                type="submit"
                color="primary"
                (click)="startNewSearch()"
                data-cy="detail-search-wahl-neue-suche-button"
            >
                <i class="fg-primary fa-fw fa-duotone fa-lg fa-rotate-left" aria-hidden="true"></i>
                Neue Suche
            </button>
            <button
                mat-raised-button
                color="primary"
                class="pl-1"
                type="submit"
                (click)="submitOnEnter()"
                data-cy="detail-search-wahl-suchen-button"
            >
                <i class="fa-fw fa-duotone fa-lg fa-search" aria-hidden="true"></i>
                Suchen
            </button>
        </div>
    </div>
</div>
