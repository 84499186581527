<k5-dialog-heading [heading]="heading"></k5-dialog-heading>

<k5-dialog-content>
    <ng-template #loadingIndicator>
        <k5-loading message="Staaten werden geladen ..."></k5-loading>
    </ng-template>
    <div *ngIf="!isLoading; else loadingIndicator">
        <form [formGroup]="zustelladresseDialogForm">
            <mat-form-field class="w-full">
                <mat-label>Staat</mat-label>
                <mat-select formControlName="staatAuswahl">
                    <mat-option *ngFor="let staat of staatList" [value]="staat">
                        {{ staat.name }} ({{ staat.isO3 }})
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ formErrorMessageService.getFormErrorMessage('staatAuswahl', zustelladresseDialogForm, 'Staat') }}
                </mat-error>
            </mat-form-field>
        </form>
        <div *ngIf="isStaatSelected">
            <div *ngIf="isInlandAdresse; else auslandsadresse">
                <form
                    id="zustelladresse-form-at"
                    [formGroup]="zustelladresseFormAt"
                    autocomplete="off"
                    class="flex flex-col"
                >
                    <mat-form-field>
                        <mat-label>Adresszeile 1 (Firmenname, c/o, usw.)</mat-label>
                        <input matInput formControlName="adresszeile1" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Straße/Hausnummer</mat-label>
                        <input matInput id="strasse" formControlName="strasseHausnr" [matAutocomplete]="auto" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectStrasse($event.option.value)">
                            <mat-option *ngFor="let strasse of strasseList" [value]="strasse">
                                {{ strasse.strassenname }}, {{ strasse.plz }} {{ strasse.zustellort }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error>
                            {{
                                formErrorMessageService.getFormErrorMessage(
                                    'strasseHausnr',
                                    zustelladresseFormAt,
                                    'Straße/Hausnummer'
                                )
                            }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>PLZ/Ort</mat-label>
                        <input matInput formControlName="plzOrt" />
                        <mat-error>
                            {{ formErrorMessageService.getFormErrorMessage('plzOrt', zustelladresseFormAt, 'PLZ/Ort') }}
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>
            <ng-template #auslandsadresse>
                <form
                    id="zustelladresse-form"
                    [formGroup]="zustelladresseForm"
                    autocomplete="off"
                    class="flex flex-col"
                >
                    <mat-error *ngIf="zustelladresseForm.hasError('adresszeilenCount')">
                        Es müssen mindestens 2 der Anschriftszeilen ausgefüllt sein
                    </mat-error>
                    <mat-form-field>
                        <mat-label>Anschriftszeile 1</mat-label>
                        <input matInput formControlName="anschriftszeile1" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Anschriftszeile 2</mat-label>
                        <input matInput formControlName="anschriftszeile2" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Anschriftszeile 3</mat-label>
                        <input matInput formControlName="anschriftszeile3" />
                    </mat-form-field>
                    <div *ngIf="!weitereAdresszeilen">
                        <button mat-stroked-button color="primary" (click)="weitereAdresszeilenAnzeigen()">
                            Weitere Adresszeilen anzeigen
                        </button>
                    </div>
                    <div *ngIf="weitereAdresszeilen" class="flex flex-col">
                        <mat-form-field>
                            <mat-label>Anschriftszeile 4</mat-label>
                            <input matInput formControlName="anschriftszeile4" />
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Anschriftszeile 5</mat-label>
                            <input matInput formControlName="anschriftszeile5" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Anschriftszeile 6</mat-label>
                            <input matInput formControlName="anschriftszeile6" />
                        </mat-form-field>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</k5-dialog-content>

<k5-dialog-actions [alignEnd]="true">
    <button mat-stroked-button color="primary" (click)="cancelDialog()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="acceptDialog()" [disabled]="saveButtonDisabled || isLoading">
        Speichern
    </button>
</k5-dialog-actions>
