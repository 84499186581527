import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePair } from '@core/models/key-value';

@Pipe({
    name: 'simpleEnumToArray'
})
export class SimpleEnumToArrayPipe implements PipeTransform {

    /**
     * Wandelt ein einfaches Enum in ein Array um
     * @param data Enum
     * @returns Array mit Enum Werten
     */
    transform(data: Object): KeyValuePair[] {
        let result: KeyValuePair[] = [];
        let keys = Object.keys(data);
        keys = keys.slice(keys.length / 2);
        for (let key of keys) {
            result.push({ key: key, value: data[key] });
        }
        return result;
    }

}
