<k5-widget>
    <!--Link Element inkl. Image-->
    <ng-container>
        <div class="h-full" *ngIf="!loading; else loadingIndicator">
            <!-- Normalfall-->
            <div class="h-full" *ngIf="getBetreiberBild(); else error">
                <a class="flex h-full w-full items-center justify-center" [href]="getBetreiberUrl()" target="_blank">
                    <img
                    class="h-full w-full"
                    [src]="imagesource"
                    [alt]="bildAlt"
                    [title]="betreiberName"
                    />
                </a>
            </div>

            <!-- Beim Error des Bildes wird angezeigt-->
            <ng-template #error class="grid grid-rows-2">
                <a class="h-3/5" [href]="getBetreiberUrl()" target="_blank">
                    <img
                        class="h-3/5 w-full"
                        [src]="betreiberFallbackImg"
                        [alt]="bildAlt"
                        [title]="betreiberName"
                    />
                </a>
                <div class="grid grid-rows-2 h-2/5" *ngIf="!betreiberBild">
                    <span class="bold text-xl">Fehler beim laden des Widgets</span>
                    <div class="flex items-end">
                        <button mat-button color="primary" class="w-fit" (click)="loadBetreiberWidget(widgetEinstellungen)">
                            <i aria-hidden="true" class="fa-duotone fa-arrows-rotate pr-md"></i>Erneut versuchen
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>

        <ng-template #loadingIndicator>
            <k5-loading message="Lädt Betreiber Widget ..."></k5-loading>
        </ng-template>
    </ng-container>


</k5-widget>
