import { Component, Input } from '@angular/core';

@Component({
    selector: 'k5-small-section-header',
    templateUrl: './small-section-header.component.html'
})
export class SmallSectionHeaderComponent {
    @Input()
    heading: string;
}
