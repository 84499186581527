<div matRipple class="autocomplete-item flex items-center" (click)="onClick($event)">
    <!-- Icons vor den jeweiligen Einträgen-->
    <ng-container [ngSwitch]="item.typ">
        <!--natürliche Person-->
        <div *ngSwitchCase="0">
            <!--inaktive Person-->
            <i
                *ngIf="!item.istAktiv"
                class="fa-duotone fa-sm w-5"
                [ngClass]="KM_CONST.FA_USER_XMARK_FG_GRAY"
                aria-hidden="true"
            ></i>
            <!--aktive Person-->
            <i
                *ngIf="item.istAktiv && !item.istVerzogen"
                class="fa-duotone fa-sm w-5"
                [ngClass]="item.istStandardadresseAktiv === false ? KM_CONST.FA_USER_MINUS : KM_CONST.FA_USER_PRIMARY"
                aria-hidden="true"
            ></i>
            <i
                *ngIf="item.istAktiv && item.istVerzogen"
                class="fa-duotone fa-sm w-5"
                [ngClass]="KM_CONST.FA_USER_MINUS"
                aria-hidden="true"
            ></i>
        </div>
        <!--aktives / inkatives Unternehmen-->
        <i
            *ngSwitchCase="1"
            class="fa-duotone fa-sm w-5"
            [ngClass]="item.istAktiv ? KM_CONST.FA_INDUSTRY_PRIMARY : KM_CONST.FA_INDUSTRY_XMARK_FG_GRAY"
            aria-hidden="true"
        ></i>
        <!--Adresse-->
        <i *ngSwitchCase="2" class="fa-duotone fa-location-pin fa-sm fg-primary w-5" aria-hidden="true"></i>
        <!--Wähler-->
        <i
            *ngSwitchCase="5"
            class="fa-duotone fa-sm fg-primary w-5"
            [ngClass]="KM_CONST.FA_USER_PRIMARY"
            aria-hidden="true"
        ></i>

        <span class="pl-2" [innerHtml]="item.bezeichnung" data-cy="quick-search-item-bezeichnung"></span>
        <span class="zusatz" *ngFor="let zusatz of item.zusaetze" [innerHTML]="buildZusatz(zusatz)"></span>
    </ng-container>
</div>
