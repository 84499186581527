import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalEditService } from '@core/services/global-edit.service';
import { EditSectionConstants } from '@shared/constants/shared-constants';
import { DialogResult } from '@shared/models/dialogResult';
import { FormErrorMessageService } from '@shared/services/form-error-message.service';

/**
 * Interface für die Daten zur Befüllung des Begründungsdialogs
 */
export interface BegruendungDialogData {
    title: string;
    content: string;
    actionText: string;
    validation: boolean;
    maxTextLength?: number;
}

@Component({
    selector: 'k5-begruendung-dialog',
    templateUrl: './begruendung-dialog.component.html'
})
export class BegruendungDialogComponent implements OnDestroy {
    // Titel des Dialogs
    title: string = 'Title';

    // Inhalt des Dialogs
    content: string = '';

    // Text des Bestätigen-Buttons
    actionText: string = 'Bestätigen';

    // Gibt an ob das Textfeld Begruendung benötigt wird
    validation: boolean = false;

    // Gibt an wieviele Zeichen maximal in das Textfeld Begruendung eingegeben werden können
    maxTextLength: number = undefined;

    // Output des Dialogs
    dialogAccepted: EventEmitter<DialogResult<string>> = new EventEmitter<DialogResult<string>>();

    public begruendungForm = this.formBuilder.group({
        begruendung: new FormControl('')
    });

    editModeOpenBefore = false;

    /**
     * Konstruktor
     * @param formBuilder FormBuilder
     */
    constructor(
        private formBuilder: FormBuilder,
        private globalEditService: GlobalEditService,
        public formErrorMessageService: FormErrorMessageService,
        public dialogRef: MatDialogRef<BegruendungDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BegruendungDialogData
    ) {
        if (data) {
            this.title = data.title;
            this.content = data.content;
            this.actionText = data.actionText;
            this.validation = data.validation;
            this.maxTextLength = data.maxTextLength;

            if (this.validation) {
                this.begruendungForm.controls.begruendung.addValidators(Validators.required);

                if (this.maxTextLength !== undefined) {
                    this.begruendungForm.controls.begruendung.addValidators(Validators.maxLength(this.maxTextLength));
                }
            }
        }

        this.editModeOpenBefore = this.globalEditService.isEditing;
        if (!this.globalEditService.isEditing) {
            this.globalEditService.switchToEditMode(EditSectionConstants.SHARED_BEGRUENDUNG);
        }
    }

    ngOnDestroy(): void {
        if (!this.editModeOpenBefore) {
            this.globalEditService.switchToReadMode();
        }
    }

    /**
     * Wird der Dialog positiv abgeschlossen, werden die Daten aus dem
     * Formular an die aufrufende Komponente als DialogResponse-Objekt übergeben
     */
    acceptDialog(): void {
        const begruendung = this.begruendungForm.controls.begruendung.value;
        const dialogResult: DialogResult<string> = {
            accepted: true,
            dialogData: begruendung
        };
        this.dialogAccepted.emit(dialogResult);
    }

    /**
     * Wird die Bearbeitung des Dialogs abgebrochen, wird die aufrufende
     * Komponente darüber informiert.
     */
    cancelDialog(): void {
        const dialogResult: DialogResult<string> = {
            accepted: false
        };
        this.dialogAccepted.emit(dialogResult);
    }
}
