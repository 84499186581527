import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { KontakteTooltipConstants, StandardTooltipConstants } from '@core/constants/tooltip-constants';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { K5NextHilfeNavigationService } from '@core/services/k5NextHilfe-navigation.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { EditSectionConstants } from '@shared/constants/shared-constants';
import { DetailsucheAdditionalSearchCriteria } from '@shared/models/detailsucheAdditionalSearchCriteria';
import { ActionButtonEnum } from '@shared/models/enums';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DetailsucheSpeichernDialogComponent } from '../detailsuche-speichern-dialog/detailsuche-speichern-dialog.component';

@Component({
    selector: 'k5-detailsuche',
    templateUrl: './detailsuche.component.html',
    styleUrls: ['./detailsuche.component.scss']
})
export class DetailsucheComponent implements OnChanges {
    // Konstante für die Tooltips
    KONTAKTE_TOOLTIP_CONSTANTS = KontakteTooltipConstants;
    STANDARD_TOOLTIP_CONSTANTS = StandardTooltipConstants;

    @Input()
    heading: string;

    @Input()
    additionalSearchCriteria: DetailsucheAdditionalSearchCriteria[];

    @Input()
    savedSearchList: KontaktmanagementClient.GespeicherteSucheResponse[];

    @Input()
    changedSearchData: KontaktmanagementClient.GespeicherteSucheResponse;

    @Input()
    showGespeicherteSuchen: boolean = true;

    @Output()
    resetSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    executeSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    saveNewSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten>();

    @Output()
    saveExistingSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten>();

    @Output()
    deleteSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse>();

    @Output()
    shareSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse>();

    @Output()
    searchChanged: EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheResponse>();

    @Output()
    changeAdditionalSearchCriteria: EventEmitter<DetailsucheAdditionalSearchCriteria[]> = new EventEmitter<
        DetailsucheAdditionalSearchCriteria[]
    >();

    // Selektierte Suche, welche befüllt wird, sobald eine selbst gespeicherte Suche ausgeführt wird
    selectedSearch: KontaktmanagementClient.GespeicherteSucheResponse;

    searchSelection: FormControl<string> = new FormControl('');
    additionalSearchCriteriaControl = new FormControl<DetailsucheAdditionalSearchCriteria[] | null>(null);

    constructor(
        private rightSideNavigationService: RightSidenavigationService,
        private dialog: MatDialog,
        private globalEditService: GlobalEditService,
        private alertService: AlertService,
        private k5NextHilfeNavigationService: K5NextHilfeNavigationService
    ) {}

    /**
     * Gibt die Liste mit geteilten Suchen zurück
     */
    get sharedSearchList(): KontaktmanagementClient.GespeicherteSucheResponse[] {
        return this.savedSearchList?.filter((search) => !search.suche.istEigeneSuche);
    }

    /**
     * Gibt die Liste mit eigens gespeicherten Suchen zurück
     */
    get ownSavedSearchList(): KontaktmanagementClient.GespeicherteSucheResponse[] {
        return this.savedSearchList?.filter((search) => search.suche.istEigeneSuche);
    }

    /**
     * Gib an, ob das Suchkriterium angezeigt werden soll
     */
    get displaySearchCritera(): DetailsucheAdditionalSearchCriteria[] {
        return this.additionalSearchCriteria?.filter((criteria) => criteria.display);
    }

    /**
     * Setzt die selektierte Suche auf neue Werte, wenn diese bearbeitet wurden
     * @param changes changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['changedSearchData']) {
            if (!changes['changedSearchData'].currentValue?.geteiltVon) {
                this.selectedSearch = {
                    suche: changes['changedSearchData'].currentValue
                };
            }

            this.selectedSearch = changes['changedSearchData'].currentValue;

            if (this.selectedSearch) {
                this.searchSelection.setValue(this.selectedSearch.suche.id);
            } else {
                this.searchSelection.setValue('');
            }
        }
        if (changes['additionalSearchCriteria']) {
            this.additionalSearchCriteriaControl.patchValue(
                changes['additionalSearchCriteria'].currentValue.filter(
                    (searchcriteria: DetailsucheAdditionalSearchCriteria) => searchcriteria.selected
                )
            );
        }
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Ausführen der Suche
     */
    search(): void {
        this.executeSearch.emit(true);
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Reset der Suche
     */
    newSearch(): void {
        this.resetSearch.emit(true);
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Speichern der Suche
     */
    saveSearch(): void {
        const detailsucheSpeichernDialogRef = this.dialog.open(DetailsucheSpeichernDialogComponent, {
            width: '50%',
            data: {
                searchData: this.selectedSearch
            }
        });
        this.globalEditService.switchToEditMode(EditSectionConstants.KONTAKTE_SUCHE_SPEICHERN);

        detailsucheSpeichernDialogRef.componentInstance.closeDialog.subscribe({
            next: (closeDialog: boolean) => {
                if (closeDialog) {
                    detailsucheSpeichernDialogRef.close();
                    this.globalEditService.switchToReadMode();
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Schließen des Dialogs');
            }
        });

        detailsucheSpeichernDialogRef.componentInstance.saveExistingSearch.subscribe({
            next: (search: KontaktmanagementClient.GespeicherteSucheDaten) => {
                if (search) {
                    this.saveExistingSearch.emit(search);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Speichern der Suche');
            }
        });

        detailsucheSpeichernDialogRef.componentInstance.saveNewSearch.subscribe({
            next: (search: KontaktmanagementClient.GespeicherteSucheDaten) => {
                if (search) {
                    this.saveNewSearch.emit(search);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Speichern der Suche');
            }
        });
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Speichern der Suche
     */
    shareSelectedSearch(search: KontaktmanagementClient.GespeicherteSucheResponse): void {
        this.shareSearch.emit(search);
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Löschen der Suche
     */
    deleteSelectedSearch(search: KontaktmanagementClient.GespeicherteSucheResponse): void {
        const confimationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Gespeicherte Suche löschen',
                content: 'Sind Sie sicher, dass Sie die gespeicherte Suche löschen wollen?',
                primaryButtonLabel: 'Gespeicherte Suche löschen',
                secondaryButtonLabel: 'Abbrechen'
            }
        });
        confimationDialogRef.componentInstance.dialogAction.subscribe({
            next: (actionButtonValue: ActionButtonEnum) => {
                if (actionButtonValue === ActionButtonEnum.Primary) {
                    this.deleteSearch.emit(search);
                    if (search.suche.id === this.selectedSearch?.suche?.id) {
                        this.selectedSearch = null;
                    }
                } else {
                    confimationDialogRef.close();
                }
            }
        });
    }

    /**
     * Benachrichtigt die Eltern Komponente wenn sich die selektierte Suche geändert hat
     * @param search MatSelectChange
     */
    selectedSearchChange(searchIdSelectionChange: MatSelectChange): void {
        this.selectedSearch = this.savedSearchList.find((s) => s.suche.id === searchIdSelectionChange.value);

        this.searchChanged.emit(this.selectedSearch);
    }

    /**
     * Schließt das Detailsuche Panel
     */
    closeDetailsuchePanel(): void {
        this.rightSideNavigationService.closeRightSidenav();
    }

    /**
     * Benachrichtigt die Eltern Komponente bei einer Änderung der weiteren Suchkriterien
     */
    additionalSearchCriteriaSelectionChange(selection: DetailsucheAdditionalSearchCriteria[]): void {
        this.changeAdditionalSearchCriteria.emit(selection);
    }

    /**
     * Behandelt den Klick auf einen Tooltip-Link.
     * @param link - Der Link, auf den geklickt wurde.
     */
    handleTooltipLinkClick(link: string): void {
        this.k5NextHilfeNavigationService.openk5NextHilfePage(link);
    }
}
