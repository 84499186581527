import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { FabItemDescriptor } from '@shared/models/fabItemDescriptor';
import { fabMenuAnimations } from './fab-menu.animations';
import { RichTooltip } from '@shared/models/richTooltip';

/**
 * Komponente für die Darstellung eines FAB-Button Menüs
 */
@Component({
    selector: 'k5-fab-menu',
    templateUrl: './fab-menu.component.html',
    styleUrls: ['./fab-menu.component.scss'],
    animations: [fabMenuAnimations]
})
export class FabMenuComponent {
    @Input()
    fabButtons: FabItemDescriptor[];

    @Input()
    tooltipOpened: string | null;

    @Input()
    tooltipClosed: string | null;

    @Input()
    icon: string | null;

    @Input()
    richTooltip: RichTooltip | null;

    @Output()
    tooltipLinkClick: EventEmitter<string> = new EventEmitter<string>();

    buttons: FabItemDescriptor[] = [];
    fabTogglerState = signal('inactive');

    /**
     * Öffnet das Button-Menü
     */
    showItems(): void {
        this.fabTogglerState.set('active');
        this.buttons = this.fabButtons;
    }

    /**
     * Schließt das Button-Menü
     */
    hideItems(): void {
        this.fabTogglerState.set('inactive');
        this.buttons = [];
    }

    /**
     * Toggled das Button-Menü
     */
    onToggleFab(): void {
        this.buttons.length ? this.hideItems() : this.showItems();
    }

    /**
     * Emittiert ein Event mit dem Link als Content, wenn auf den Link im Tooltip geklickt wird.
     */
    handleTooltipLinkClick(): void {
        this.tooltipLinkClick.emit(this.richTooltip.k5NextRichTooltipLink);
    }
}
