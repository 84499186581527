import { Directive, AfterContentInit, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[k5AutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

    @Input() public appAutoFocus: boolean;

    public constructor(private el: ElementRef) {

    }

    public ngAfterContentInit() {

        setTimeout(() => {

            this.el.nativeElement.focus();

        }, 50);

    }

}
