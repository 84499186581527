import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    appInsights: ApplicationInsights;
    config: AppConfig = null;

    constructor(private configService: ConfigAssetLoaderService) {
        this.config = this.configService.getConfig();

        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: this.config.appInsights.connectionString,
                instrumentationKey: this.config.appInsights.instrumentationKey,
                cookieCfg: {
                    enabled: false
                }
            }
        });
        this.appInsights.loadAppInsights();
    }

    /**
     * Loggt eine Seite
     * @param name Name der TrackPage. Default ist der Dokumententitel
     * @param url Eine relative/absolute URL, die die Seite identifiziert. Default ist die window location
     */
    logPageView(name?: string, url?: string): void {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    /**
     * Loggt eine Benutzeraktion
     * @param name An event name string
     * @param properties
     */
    logEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    /**
     * Loggt einen numerischen Wert
     * @param name Name der Metrik
     * @param average value/average für diese Metrik
     * @param properties
     */
    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    /**
     * Loggt eine Exception
     * @param exception
     * @param severityLevel severityLevel
     */
    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    /**
     * Loggt ein Diagnoseszenario, wie das betreten oder verlassen einer Funktion
     * @param message message
     * @param properties
     */
    logTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace({ message: message }, properties);
    }

    /**
     * Loggt eine Metrik mit der vergangenen Zeit zwischen Request und Response
     * @param startTime Startzeit als Datum in ms
     * @param endTime Startzeit als Datum in ms
     */
    logMetricWithElapsedTime(name: string, startTime: number, endTime: number) {
        this.logMetric(name, endTime - startTime);
    }
}
