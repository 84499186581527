import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserInformationService } from '@core/services/user-information.service';
import { WidgetConstants } from '@shared/constants/shared-constants';
import { DateUtilsService } from '@shared/services/date-utils.service';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'k5-willkommen-widget',
    templateUrl: './willkommen-widget.component.html',
    styleUrls: ['./willkommen-widget.component.scss']
})
export class WillkommenWidgetComponent implements OnInit, OnDestroy {

    currentSetting: null | string;

    timeText: string = '';

    now: Date = new Date();

    source = interval(5000);

    subscription = new Subscription();

    DATE_UTITLS_SERVICE = DateUtilsService;


    constructor(private userInformationService: UserInformationService) { }

    ngOnInit(): void {
        this.now = new Date();
        this.updateIconAndText();
        this.subscription.add(this.source.subscribe({
            next: () => {
                this.now = new Date();
                this.updateIconAndText();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Updated den Text und das Icon
     */
    updateIconAndText(): void {
        if (this.now.getHours() >= WidgetConstants.NIGHT_HOUR && this.now.getHours() < WidgetConstants.MORNING_HOUR) {
            this.timeText = 'Guten Morgen';
            this.currentSetting = 'fa-mug-hot coffee';
        }
        else if (this.now.getHours() >= WidgetConstants.MORNING_HOUR && this.now.getHours() < WidgetConstants.AFTERNOON_HOUR) {
            this.timeText = 'Guten Tag';
            this.currentSetting = 'fa-sun sun';
        }
        else if (this.now.getHours() >= WidgetConstants.AFTERNOON_HOUR && this.now.getHours() < WidgetConstants.EVENING_HOUR) {
            this.timeText = 'Schönen Nachmittag';
            this.currentSetting = 'fa-sun-cloud sun';
        }
        else {
            this.timeText = 'Guten Abend';
            this.currentSetting = 'fa-cloud-moon moon';
        }
    }

    /**
     * Gibt den aktuellen Wochentag zurück
     */
    get dayOfWeek() {
        const dayOfWeek = this.now.getDay();
        return isNaN(dayOfWeek) ? null :
            ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][dayOfWeek];
    }

    /**
     * Gibt den Vornamen zurück
     */
    get vorname(): string {
        return this.userInformationService.getVorname();
    }
}
