import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { Dictionary } from '@core/models/dictionary';
import { WahlDetailsuche } from '@shared/constants/shared-constants';
import { ChartLegend } from '@shared/models/chart-legend';
import { WAHLBERECHTIGTE_AKTUELL_THEME } from '@shared/models/chartColors';
import { PersonenTyp } from '@shared/models/enums';
import { WahlberechtigteAktuellStatistikResponse } from '@shared/models/wahlberechtigteAktuellStatistikResponse';
import { WahlbestandSuchkriterien } from '@shared/models/wahlbestandSuchkriterien';
import { Wahldaten } from '@shared/models/wahldaten';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { ChartOptions } from 'chart.js';
import { WahldetailService } from 'src/app/buergerservice/wahl/services/wahldetail.service';

@Component({
    selector: 'k5-wahlberechtigte-aktuell-widget',
    templateUrl: './wahlberechtigte-aktuell-widget.component.html'
})
export class WahlberechtigteAktuellWidgetComponent implements OnInit {
    @Input()
    wahl: Wahldaten | null = null;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    wahlberechtigteAktuellStatistik: WahlberechtigteAktuellStatistikResponse = null;

    dataSource: ChartLegend[] = [];
    davonLegend: ChartLegend[] = [];

    // Bar-Chart
    pieChartOptions: ChartOptions<'pie'> = null;
    pieChartLabels = null;
    pieChartDatasets = null;
    pieChartLegend = false;

    colorScheme = {
        domain: WAHLBERECHTIGTE_AKTUELL_THEME
    };

    error: boolean = false;
    loading: boolean = true;

    constructor(
        private wahlService: WahldetailService,
        private detailsucheService: DetailsucheService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadWidgetData();
    }

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }

    /**
     * Lädt die Daten für die Anzeige im Widget
     */
    loadWidgetData(): void {
        this.loading = true;
        this.error = false;
        this.wahlService.getWahlberechtigteAktuellStatistik(this.wahl?.id).subscribe({
            next: (data: WahlberechtigteAktuellStatistikResponse) => {
                this.wahlberechtigteAktuellStatistik = data;
                this.buildLegend(data);
                this.loading = false;
            },
            error: () => {
                this.error = true;
                this.loading = false;
            }
        });
    }

    /**
     * Baut die Daten für die Anzeige zusammen
     * @param data WahlberechtigteAktuell Statistik
     */
    buildLegend(data: WahlberechtigteAktuellStatistikResponse): void {
        const legend: ChartLegend[] = [
            {
                id: 1,
                name: 'Österreicher mit Hauptwohnsitz',
                amount: data.oesterreicher,
                color: WAHLBERECHTIGTE_AKTUELL_THEME[0]
            },
            {
                id: 2,
                name: 'EU Bürger',
                amount: data.euBuerger,
                color: WAHLBERECHTIGTE_AKTUELL_THEME[1]
            },
            {
                id: 3,
                name: 'Auslandsösterreicher',
                amount: data.auslandsoesterreicher,
                color: WAHLBERECHTIGTE_AKTUELL_THEME[2]
            }
        ];

        const davonLegend: ChartLegend[] = [
            {
                id: 4,
                name: 'Personen in Haft',
                amount: data.personenInHaft,
                color: WAHLBERECHTIGTE_AKTUELL_THEME[3]
            },
            {
                id: 5,
                name: 'Obdachlose',
                amount: data.obdachlose,
                color: WAHLBERECHTIGTE_AKTUELL_THEME[4]
            }
        ];

        this.dataSource = legend;
        this.davonLegend = davonLegend;

        this.pieChartDatasets = [
            {
                data: legend.map((x) => x.amount),
                backgroundColor: WAHLBERECHTIGTE_AKTUELL_THEME
            }
        ];
        this.pieChartLabels = legend.map((x) => x.name);

        this.pieChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            animation: false
        };
    }

    /**
     * Navigiert in die Detailsuche des Wahlbestands mit den vordefinierten Suchparametern
     */
    navigateToWahlbestand(item: ChartLegend): void {
        let offeneBearbeitungen = PersonenTyp.Oesterreicher;
        switch (item.id) {
            case 2:
                offeneBearbeitungen = PersonenTyp.EUBuerger;
                break;
            case 3:
                offeneBearbeitungen = PersonenTyp.Auslandsoesterreicher;
                break;
            case 4:
                offeneBearbeitungen = PersonenTyp.PersonInHaft;
                break;
            case 5:
                offeneBearbeitungen = PersonenTyp.Obdachlos;
                break;
        }

        const guid = this.detailsucheService.generateSessionStorageKey();
        const suchkriterien: Dictionary<string> = {};
        suchkriterien[WahlDetailsuche.PERSONENTYP] = offeneBearbeitungen.toString();

        const wahlbestandSuchkriterien: WahlbestandSuchkriterien = {
            suchkriterien,
            schnellsucheQuery: null,
            sort: null
        };
        this.detailsucheService.saveSearchInStorage(wahlbestandSuchkriterien, guid);
        this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id, PathConstants.WAHLBESTAND], {
            queryParams: { searchKey: guid }
        });
    }
}
