<k5-widget id="willkommenWidgetContent">
    <div class="willkommen-widget-container h-full">
        <em class="fa-duotone fa-3x" id="tageszeitIcon" [ngClass]="currentSetting" aria-hidden="true"></em>
        <div class="flex flex-col pl-4">
            <span class="text-2xl" id="benutzerNameUndBegruessung">{{ timeText }}, {{ vorname }}</span>
            <span class="text-base" id="datumString">
                {{ dayOfWeek }}, {{ now ? DATE_UTITLS_SERVICE.convertDateToFormattedDateString(now) : "" }}
            </span>
        </div>
    </div>
</k5-widget>
