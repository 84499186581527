import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { AlertService } from '@core/services/alert.service';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { DateUtilsService } from '@shared/services/date-utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { KontaktmanagementService } from 'src/app/buergerservice/kontaktmanagement/services/kontaktmanagement.service';

@Component({
    selector: 'k5-gespeicherte-suche-widget',
    templateUrl: './gespeicherte-suche-widget.component.html',
    styleUrls: ['./gespeicherte-suche-widget.component.scss']
})
export class GespeicherteSucheWidgetComponent implements OnInit {
    DATE_UTILS_SERVICE = DateUtilsService;
    titelText = 'Meine gespeicherten Suchen';

    gespeicherteSuchenSubject: BehaviorSubject<KontaktmanagementClient.GespeicherteSuchenResponse> =
        new BehaviorSubject(null);
    gespeicherteSuchen$: Observable<KontaktmanagementClient.GespeicherteSuchenResponse> =
        this.gespeicherteSuchenSubject.asObservable();

    filteredGespeicherteSuchen: KontaktmanagementClient.GespeicherteSucheResponse[] = [];

    loading = true;

    constructor(
        private kontaktmanagementService: KontaktmanagementService,
        private alertService: AlertService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.kontaktmanagementService.getSuchen().subscribe({
            next: (response: KontaktmanagementClient.GespeicherteSuchenResponse) => {
                if (response) {
                    this.gespeicherteSuchenSubject.next(response);
                    this.filteredGespeicherteSuchen = response.responses;
                }
                this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der geteilten Suchen');
                this.loading = false;
            }
        });
    }

    /**
     * Filtert die Datenmenge nach dem Filtertext.
     * @param text
     */
    search(text: string): void {
        if (text) {
            text = text.toLowerCase();
            this.filteredGespeicherteSuchen = this.gespeicherteSuchenSubject.value.responses.filter(
                (gespeicherteSuche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                    return (
                        gespeicherteSuche.suche.name.toLowerCase().includes(text) ||
                        this.DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                            gespeicherteSuche.suche?.letzteAenderung
                        )
                            .toLowerCase()
                            .includes(text)
                    );
                }
            );
        } else {
            this.filteredGespeicherteSuchen = this.gespeicherteSuchenSubject.value.responses;
        }
    }

    /**
     * Navigiert zum KM mit der Suche Id
     * @param searchId Id der Suche
     */
    navigateToKM(searchId: string): void {
        this.router.navigateByUrl(`${RouterLinkConstants.KONTAKTE_LISTE}?searchKey=${searchId}`);
    }
}
