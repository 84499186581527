import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { FormErrorMessageService } from '@shared/services/form-error-message.service';

export interface DetailsucheSpeichernDaten {
    searchData?: KontaktmanagementClient.GespeicherteSucheResponse;
}

@Component({
    selector: 'k5-detailsuche-speichern-dialog',
    templateUrl: './detailsuche-speichern-dialog.component.html'
})
export class DetailsucheSpeichernDialogComponent implements OnInit {
    @Output()
    closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    saveExistingSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten>();

    @Output()
    saveNewSearch: EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten> =
        new EventEmitter<KontaktmanagementClient.GespeicherteSucheDaten>();

    saveSearchForm = new FormGroup({
        name: new FormControl<string | null>(null, { validators: [Validators.required, Validators.maxLength(100)] }),
        beschreibung: new FormControl<string | null>(null, { validators: [Validators.maxLength(500)] })
    });

    saveExisting: boolean;

    constructor(
        public formErrorMessageService: FormErrorMessageService,
        @Inject(MAT_DIALOG_DATA) public data: DetailsucheSpeichernDaten
    ) {}

    ngOnInit(): void {
        if (this.data?.searchData?.suche) {
            this.saveSearchForm.patchValue({
                name: this.data.searchData.suche.name,
                beschreibung: this.data.searchData.suche.beschreibung
            });
            if (this.saveExistingSearchSelected) {
                this.saveExisting = true;
            } else if (this.saveNewSeachSelected) {
                this.saveExisting = false;
            }
        } else {
            this.saveExisting = false;
        }
    }

    /**
     * Gibt an, ob die Checkbox der bestehenden Suche eingeschalten sein soll
     */
    get saveExistingSearchSelected(): boolean {
        if (this.data?.searchData?.suche) {
            return this.data.searchData.suche.istEigeneSuche;
        }
        return false;
    }

    /**
     * Gibt an, ob die Checkbox der neuen Suche eingeschalten sein soll
     */
    get saveNewSeachSelected(): boolean {
        if (this.data?.searchData?.suche) {
            return !this.data.searchData.suche.istEigeneSuche;
        }
        return true;
    }

    /**
     * Selektion der Radio Buttons hat sich geändert
     * @param event MatRadioChange
     */
    radioSelectionChanged(event: MatRadioChange): void {
        if (event.value === 0) {
            this.saveExisting = true;
        } else {
            this.saveExisting = false;
        }
    }

    /**
     * Benachrichtigt die Eltern Komponente beim Speichern der Suche
     */
    save(): void {
        this.saveSearchForm.markAllAsTouched();
        if (this.saveExisting && this.saveSearchForm.valid) {
            const searchData: KontaktmanagementClient.GespeicherteSucheDaten = this.saveSearchForm.value;
            searchData.id = this.data.searchData.suche.id;
            searchData.letzteAenderung = this.data.searchData.suche.letzteAenderung;
            searchData.geaendertVon = this.data.searchData.suche.geaendertVon;
            this.saveExistingSearch.emit(searchData);
        } else if (!this.saveExisting && this.saveSearchForm.valid) {
            this.saveNewSearch.emit(this.saveSearchForm.value);
        }
    }

    /**
     * Benachrichtigt andere Komponente nach Abbrechen
     */
    cancel(): void {
        this.closeDialog.emit(true);
    }
}
