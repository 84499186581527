<div class="flex h-full flex-col">
    <!-- Detailsuche header -->
    <div class="flex flex-col bg-[#dde2e7] p-4">
        <div
            class="flex basis-full items-center"
            k5nextRichTooltip
            [k5nextRichTooltipContent]="KONTAKTE_TOOLTIP_CONSTANTS.KONTAKT_SUCHEN_TOOLTIP_CONTENT"
            [k5nextRichTooltipLink]="KONTAKTE_TOOLTIP_CONSTANTS.KONTAKTE_SUCHEN_TOOLTIP_LINK"
            [k5nextRichTooltipLinkAlias]="STANDARD_TOOLTIP_CONSTANTS.STANDARD_TOOLTIP_ALIAS"
            (clickLink)="handleTooltipLinkClick($event)"
        >
            <!-- Titel -->
            <div class="grow text-2xl">
                {{ heading }}
            </div>
            <!-- Button zum Schließen -->
            <button
                type="button"
                aria-label="Suche ausblenden"
                matTooltip="Suche ausblenden"
                mat-icon-button
                (click)="closeDetailsuchePanel()"
            >
                <mat-icon fontSet="fa-solid" fontIcon="fa-chevron-right" class="fg-primary"></mat-icon>
            </button>
        </div>

        <div class="flex gap-4" *ngIf="showGespeicherteSuchen">
            <mat-form-field class="grow">
                <mat-select
                    id="searchSelection"
                    matInput
                    placeholder="meine Suchen"
                    [matTooltip]="selectedSearch?.suche.name"
                    [formControl]="searchSelection"
                    (selectionChange)="selectedSearchChange($event)"
                    data-cy="detail-search-gespeicherte-suchen"
                >
                    <mat-select-trigger>
                        {{ selectedSearch?.suche.name }}
                    </mat-select-trigger>
                    <mat-optgroup label="meine Suchen" *ngIf="ownSavedSearchList?.length > 0">
                        <mat-option
                            *ngFor="let search of ownSavedSearchList"
                            [value]="search.suche.id"
                            data-cy="detail-search-gespeicherte-suchen-option"
                        >
                            <div class="flex max-w-full items-center justify-between py-2">
                                <p class="option-break line-clamp-3 leading-6" [matTooltip]="search.suche.name">
                                    {{ search.suche.name }}
                                </p>
                                <div class="flex">
                                    <button
                                        type="button"
                                        aria-label="Suche teilen"
                                        mat-icon-button
                                        matTooltip="Teilen"
                                        data-cy="detailsuche-button-suche-teilen"
                                        (click)="$event.stopPropagation(); shareSelectedSearch(search)"
                                    >
                                        <mat-icon
                                            fontSet="fa-duotone"
                                            fontIcon="fa-share-nodes"
                                            class="text-base text-gray-500"
                                        ></mat-icon>
                                    </button>
                                    <button
                                        type="button"
                                        aria-label="Suche löschen"
                                        mat-icon-button
                                        matTooltip="Löschen"
                                        (click)="$event.stopPropagation(); deleteSelectedSearch(search)"
                                        data-cy="detailsuche-button-suche-loeschen"
                                    >
                                        <mat-icon
                                            fontSet="fa-duotone"
                                            fontIcon="fa-trash-alt"
                                            class="text-base text-red-500"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="geteilte Suchen" *ngIf="sharedSearchList?.length > 0">
                        <mat-option *ngFor="let search of sharedSearchList" [value]="search.suche.id" class="">
                            <div class="grid max-w-full grid-cols-2 items-center justify-between gap-x-2 py-2">
                                <p class="option-break line-clamp-3 leading-6" [matTooltip]="search.suche.name">
                                    {{ search.suche.name }}
                                </p>
                                <div>
                                    <span
                                        class="text-xs text-gray-500"
                                        [matTooltip]="'geteilt von: ' + search.suche.besitzer.name"
                                        >{{ search.suche.besitzer.name }}</span
                                    >
                                </div>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <button
                class="mt-1"
                type="button"
                aria-label="Suche speichern"
                matTooltip="Suche speichern"
                mat-icon-button
                (click)="saveSearch()"
                data-cy="detailsuche-button-suche-speichern"
            >
                <mat-icon fontSet="fa-duotone" fontIcon="fa-floppy-disk" class="fg-primary"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Inhalt mit allen Form Elementen -->
    <div class="grow overflow-auto">
        <ng-content select="[k5-detailsuche-content]"></ng-content>
    </div>

    <!-- Aktionsbereich für das Hinzufügen von Suchkriterien und die Ausführung der Suche -->
    <div class="block">
        <!-- Button zum Erweitern der Suchkriterien -->
        <mat-form-field class="w-full px-4">
            <mat-label>weitere Suchkriterien</mat-label>
            <mat-select
                #searchCriteria
                [formControl]="additionalSearchCriteriaControl"
                (selectionChange)="additionalSearchCriteriaSelectionChange($event.value)"
                multiple
                data-cy="detail-search-kontakte-weitere-suchkriterien-select"
            >
                <mat-option
                    *ngFor="let searchCriteria of displaySearchCritera"
                    [value]="searchCriteria"
                    color="primary"
                    data-cy="detail-search-kontakte-weitere-suchkriterien-option"
                >
                    {{ searchCriteria.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex justify-evenly py-4">
            <button mat-stroked-button type="submit" color="primary" (click)="newSearch()">
                <i class="fg-primary fa-fw fa-duotone fa-lg fa-rotate-left" aria-hidden="true"></i>
                Neue Suche
            </button>
            <button
                mat-raised-button
                color="primary"
                class="pl-1"
                type="submit"
                (click)="search()"
                data-cy="detailsuche-button-suchen"
            >
                <i class="fa-fw fa-duotone fa-lg fa-search" aria-hidden="true"></i>
                Suchen
            </button>
        </div>
    </div>
</div>
