import { Pipe, PipeTransform } from '@angular/core';
import { Personenart } from '@shared/models/personenart';

@Pipe({
  name: 'personArt'
})
export class PersonArtPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value KontaktSuchTyp
     */
     transform(value: Personenart): string {
        switch (value) {
            case Personenart.Natuerlich:
                return 'Natürlich';
            case Personenart.Unternehmen:
                return 'Unternehmen';
            default:
                return 'Alle';

        }
    }

}
