/**
 * Enumerationen für die k5Buergerservice-Datenklassen
 */

/**
 * Daten-Kategorie im Schnellsuche Suchergebnis
 */
export enum SchnellsucheKategorieEnum {
    Kontakte,
    Adressen,
    Listen,
    Gruppen,
    Waehler
}

/**
 * Typ eines Elementes im Schnellsuche-Ergebnis
 */
export enum SchnellsucheErgebnisElementTypEnum {
    NatuerlichePerson = 0,
    Unternehmen = 1,
    Adresse = 2,
    Gruppe = 3,
    Liste = 4,
    Waehler = 5
}

/** Arten von Zusätzen der Schnellsuche Ergebnisse. */
export enum SchnellsucheErgebnisElementZusatzArtEnum {
    Ansprechpartner = 0,
    ZMRHauptwohnsitz = 1,
    ZMRNebenwohnsitz = 2,
    ErnPAnschrift = 3,
    URFirmensitz = 4,
    URZustelladresse = 5,
    ManuellHauptadresse = 6,
    ManuellZustelladresse = 7,
    Telefon = 8,
    Mobil = 9,
    Fax = 10,
    EMail = 11,
    Website = 12
}

/**
 * Kontakttyp in der Detailsuche
 */
export enum KontaktSuchTyp {
    Alle,
    NatuerlichePerson,
    Unternehmen
}

/**
 * Wahlsuchtyp für die Detailsuche
 */
export enum WahlSuchTyp {
    Waehler
}

/**
 * Dashboardtyp für die Dashboards
 */
export enum DashboardType {
    Home = 1,
    Kontaktmanagement = 2,
    Wahl = 3
}

// #region Detailsuche Wahl

/**
 * Wählerherkunft im Datenmodell
 */
export enum WaehlerHerkunft {
    Oesterreicher = 0,
    Auslandsoesterreicher = 1,
    EUBuerger = 2
}

/**
 * Enum für die Detailsuche im Wahlbestand
 */
export enum PersonenTyp {
    Oesterreicher = 0,
    Auslandsoesterreicher = 1,
    EUBuerger = 2,
    Obdachlos = 3,
    PersonInHaft = 4
}

/**
 * Enum Werte die Darstellung von bool´schen Werten
 * Wird für Auswahlfelder verwendet.
 */
export enum NullableBoolean {
    Ja = 0,
    Nein = 1
}

/**
 * Enum Werte für die Suche nach Zustelladressen bei wahlberechtigten Personen
 * Wird für Auswahlfelder in der Detailsuche des Wahlbestand verwendet.
 */
export enum ZustelladresseVorhanden {
    Nein = 0,
    Ja = 1,
    Unvollstaendig = 2
}

/**
 * Enum Werte für den Status einer wahlberechtigten Person im Wahlbestand
 * Wird für Auswahlfelder in der Detailsuche des Wahlbestand verwendet.
 */
export enum WaehlerStatus {
    Gestrichen = 0,
    Hineinreklamiert = 1,
    Verstorben = 2
}

/**
 * Enum Werte für die durchgeführten Datenabgleiche
 * einer wahlberechtigten Person im Wahlbestand
 * Wird für Auswahlfelder in der Detailsuche des Wahlbestand verwendet.
 */
export enum Datenabgleich {
    Verstorben = 0,
    Meldeadresse = 1,
    Name = 2
}

/**
 * Enum mit den Werten des Wahlkartenstatus
 */
export enum WahlkartenStatusEnum {
    /**
     * gesetzt, wenn keine Wahlkarte vorhanden ist
     */
    Keine = 0,

    /**
     * gesetzt, wenn ein Datum zur Beantragung vorhanden ist
     */
    Beantragt = 1,

    /**
     * gesetzt, wenn ein Datum zum Wahlkartendruck vorhanden ist
     */
    WahlkarteGedruckt = 2,

    /**
     * gesetzt, wenn ein Datum zum Etikettdruck vorhanden ist
     */
    EtikettGedruckt = 3,

    /**
     * gesetzt, wenn ein Datum zum Etikettdruck vorhanden ist und die Zustellart RSa
     * Wert im Dez.22 neu eingeführt
     */
    RSaEtikettGedruckt = 4,

    /**
     * gesetzt, wenn ein Datum zum Etikettdruck vorhanden ist und die Zustellart RSb
     * Wert im Dez.22 neu eingeführt
     */
    RSbEtikettGedruckt = 5,

    /**
     * gesetzt, wenn ein Datum zur Postaufgabenliste vorhanden ist
     * Wert im Dez.22 geändert von 4 auf 10
     */
    Versendet = 10
}

/**
 * Enum mit den Werten des Wahlkartenstatus
 */
export enum SucheWahlkartenStatusEnum {
    /**
     * gesetzt, wenn keine Wahlkarte vorhanden ist
     */
    Keine = 0,

    /**
     * gesetzt, wenn ein Datum zur Beantragung vorhanden ist
     */
    Beantragt = 1,

    /**
     * gesetzt, wenn ein Datum zum Wahlkartendruck vorhanden ist
     */
    WahlkarteGedruckt = 2,

    /**
     * gesetzt, wenn ein Datum zum Etikettdruck vorhanden ist
     */
    EtikettGedruckt = 3,

    /**
     * gesetzt wenn keine Bearbeitungen mehr offen sind
     */
    Ausgestellt = 6,

    /**
     * gesetzt, wenn ein Datum zur Postaufgabenliste vorhanden ist
     */
    Versendet = 10
}

/**
 * Enum Werte für austehende Bearbeitungen im Wahlbestand
 * Wird für Auswahlfelder in der Detailsuche des Wahlbestand verwendet.
 */
export enum OffeneBearbeitungen {
    Wahlkarten = 0,
    Etiketten = 1,
    Postaufgabeliste = 2
}

export enum WahlstatusStepState {
    /* Aktueller Schritt im Wahlablauf hat noch nicht angefangen */
    NotStarted = 0,

    /* Aktueller Schritt im Wahlablauf ist in Arbeit */
    InProgress = 1,

    /* Fehler im aktuellen Schritt des Wahlablaufs */
    Failure = 2,

    /* Aktueller Schritt im Wahlablauf wurde abgeschlossen */
    Completed = 3
}

export enum WahlstatusLinkType {
    // Verlinkung auf den Wahlbestand
    Wahlbestand = 1,
    // Verlinkung auf die Klärungsfälle
    Klaerungsfaelle = 2,
    // Verlinkung auf die Sprengelzuordnungen
    Sprengelzuordnungen = 3
}

export enum Wahlstatus {
    /* Wahl befindet sich in Vorbereitung
     * vorher: 0
     * jetzt: 0
     */
    Vorbereitung = 0,

    /* Anlage einer Wahl
     * Wert im Okt.22 geändert von 1 auf 10
     */
    Anlage = 10,

    /* eWählerverzeichnis automatisch importieren
     * Wert im Okt.22 geändert von 2 auf 20
     */
    WahlbestandImportAutomatisch = 20,

    /* eWählerverzeichnis manuell importieren
     * Wert im Okt.22 geändert von 3 auf 30
     */
    WahlbestandImportManuell = 30,

    /* Wahlbestand erstellt
     * Wert im Okt.22 geändert von 5 auf 40
     */
    WahlbestandErstellt = 40,

    /* Adress- und Sprengelprüfung des Wahlbestands
     * Wert im Okt.22 geändert von 4 auf 50
     */
    WahlbestandGeprueft = 50,

    /* Wahlbestand zu Bearbeitung freigegeben
     * Wert im Okt.22 geändert von 6 auf 60
     */
    WaehlerverzeichnisFreigegeben = 60,

    /**
     * Kontakt- und Wahllokaldetails kontrollieren
     * Wert im Feb.23 hinzugefügt
     */
    KontaktWahllokaldatenKontrolliert = 70,

    /*
     * Verknüpfung der wahlberechtigten Personen mit der zentralen Person durchgeführt
     * Wert im Feb.23 hinzugefügt
     */
    DatenverknuepfungDurchgefuehrt = 80,

    /*
     * Abgleich der wahlberechtigten Personen mit der zentralen Person durchgeführt
     * Wert im Mai 23 hinzugeführt
     */
    DatenabgleichDurchgefuehrt = 83,

    /*
     * Freigabe der Wahlinformationen für den zentralen Druck
     * Wert im Feb.23 hinzugefügt
     */
    WahlinformationDruckfreigabe = 90,

    /* Wahl abgeschlossen
     * Wert im Okt.22 geändert von 7 auf 70
     * Wert im Feb.23 geändert von 70 auf 120
     */
    WahlAbgeschlossen = 120,

    /* Wahl historisch
     * Wert im Okt.22 geändert von 8 auf 80
     * Wert im Feb.23 geändert von 80 auf 150
     */
    WahlHistorisch = 150
}

// #endregion Detailsuche Wahl

/**
 * Geschlechtstypen, die vom Personenservice gekannt werden
 */
export enum GeschlechtEnum {
    // Unbekanntes Geschlecht
    // Kann vorkommen wenn die Daten so aus dem Register geliefert werden
    Unbekannt = 'Unbekannt',

    // Männlich
    Maennlich = 'Maennlich',

    // Weiblich
    Weiblich = 'Weiblich',

    // Divers
    Divers = 'divers',

    // Offen
    Offen = 'offen',

    // Inter
    Inter = 'inter'
}

/**
 * Kontaktarten
 */
export enum KontaktartEnum {
    // Telefonnummer
    Telefon = 'Telefon',

    // Mobile Telefonnummer
    Mobil = 'Mobil',

    // Fax
    Fax = 'Fax',

    // Email Adresse
    EMail = 'EMail'
}

/**
 * Adresstypen für das Ergebnis einer ZMR-Suche
 */
export enum AdressTypEnum {
    // Zentrales Melderegister - Hauptwohnsitz
    ZMRHauptwohnsitz = 1,

    // Zentrales Melderegister - Nebenwohnsitz
    ZMRNebenwohnsitz = 2,

    // Zentrales Melderegister - Obdachlos
    ZMRObdachlos = 3
}

/**
 * Action Button Values
 */
export enum ActionButtonEnum {
    // Primärer Button
    Primary = 1,

    // Sekundärer Button
    Secondary = 2,

    // Tertiärer Button
    Tertiary = 3
}

/**
 * Enum mit den möglichen Arten, um einen Wähler beim Wahlkartenantrag zu authentifizieren
 */
export enum Authentifizierungsart {
    /**
     * Authentifizierung über IDAustria/Handysignatur
     */
    IdAustria,

    /**
     * Authentifizierung über Antragscode auf der Wählerinformation
     */
    Antragscode,

    /**
     * Authentifizierung über Reisepassnummer
     */
    Reisepassnummer,

    /**
     * Authentifizierung über gescannten Lichtbildausweis
     * (Bsp.: Reisepass, Personalausweis, Führerschein)
     */
    Lichtbildausweis
}

/**
 * Möglichkeiten des Identitätsnachweises
 */
export enum IdentitaesnachweisEnum {
    /**
     * Führerschein
     */
    Fuehrerschein = 0,
    /**
     * Reisepass
     */
    Reisepass = 1,
    /**
     * Personalausweis
     */
    Personalausweis = 2,
    /**
     * Andere Dokumente
     * Lichtbildausweis zur Identifikation
     */
    AndereUrkunde = 3,
    /**
     * Id-Austria
     */
    IdAustria = 4,
    /**
     * Antragscode
     */
    Antragscode = 5
}

// #region Reports

/**
 * Wahltyp
 */
export enum Wahltyp {
    EU = 0,
    Bund = 1,
    Land = 2,
    Gemeinde = 3
}

/**
 * Sortierung im Wahlbestand
 */
export enum WahlbestandSortierungEnum {
    // Sprengelnummer, Ortschaft, Straßenbezeichnung, Hausnummer, Türnummer, Familienname, Vorname
    SprengelOrtschaftAdresseBisTuerName = 0,
    // Standardsortierung => Sprengelnummer, Ortschaft, Straßenbezeichnung, Hausnummer, Familienname, Vorname
    SprengelOrtschaftAdresseBisHausnummerName = 1,
    // Sprengelnummer, Straßenbezeichnung, Hausnummer, bis Tür, Familienname, Vorname
    SprengelAdresseBisTuerName = 2,
    // Sprengelnummer, Straßenbezeichnung, Hausnummer, Familienname, Vorname
    SprengelAdresseBisHausnummerName = 3,
    // Sprengelnummer, Familienname, Vorname
    SprengelName = 4
}

/**
 * Wahlkartenunterschrift
 */
export enum WahlkarteUnterschriftEnum {
    Buergermeister = 0,
    Wahlleiter = 1,
    Sachbearbeiter = 2
}

// #endregion Reports
