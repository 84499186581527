import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { LoggingConstants, SchnellsucheConstants } from '@shared/constants/shared-constants';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { SchnellsucheErgebnis } from '@shared/models/schnellsucheErgebnis';
import { SchnellsucheErgebnisElementZusatz } from '@shared/models/schnellsucheErgebnisElementZusatz';
import { UIDetailsucheRequest } from '@shared/models/uIDetailsucheRequest';
import { WahlbestandSchnellsucheDaten } from '@shared/models/wahlbestandSchnellsucheDaten';
import { WahlbestandSchnellsucheResponse } from '@shared/models/wahlbestandSchnellsucheResponse';
import { WahlbestandSuchkriterien } from '@shared/models/wahlbestandSuchkriterien';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { LoggingService } from '@shared/services/logging.service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { PathConstants, PathIds, RouterLinkConstants } from 'src/app/core/constants/url-constants';
import {
    SchnellsucheErgebnisElementTypEnum,
    SchnellsucheErgebnisElementZusatzArtEnum,
    SchnellsucheKategorieEnum
} from '../../models/enums';
import { SchnellsucheErgebnisDaten } from '../../models/schnellsucheErgebnisDaten';
import { SchnellsucheErgebnisElement } from '../../models/schnellsucheErgebnisElement';
import { SchnellsucheService } from '../../services/schnellsuche.service';
import { QuickSearchItemComponent } from '../quick-search-item/quick-search-item.component';

// Konstanten für den Kontext der Schnellsuche
const HOME_CONTEXT = 'home';
const KONTAKTMANAGEMENT_CONTEXT = 'kontaktmanagement';
const WAHL_CONTEXT = 'wahlvorbereitung';

const MINIMUM_QUERY_LENGTH = 2;

/**
 * Komponente für die Schnellsuche
 */
@Component({
    selector: 'k5-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit, OnDestroy {
    @Input()
    detailSearchEnabled = false;

    @Output()
    searchInputChanges: Observable<string>;
    currentSearchInput = '';

    @ViewChildren(QuickSearchItemComponent)
    quickSearchItems: QueryList<QuickSearchItemComponent>;
    activeItem: SchnellsucheErgebnisElement;
    activeItemIndex = -1;

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    searchInputSubject: BehaviorSubject<string> = new BehaviorSubject(this.currentSearchInput);

    schnellsucheErgebnisDaten: SchnellsucheErgebnisDaten[] = [];
    itemsLeft: SchnellsucheErgebnisDaten[] = [];
    itemsRight: SchnellsucheErgebnisDaten[] = [];

    autocompleteOpen = false;
    loading = false;
    clickWasInside = false;

    // Zustand die Abhandlung des Antragscode-Scans in der Wahl
    searchChangeInProgress = false;
    enterPressedBeforeSearchCompletion = false;

    schnellsucheSubscription: Subscription = new Subscription();
    clearSearchInputSubscription: Subscription = new Subscription();

    // Kontext für die Schnellsuche
    context: string = WAHL_CONTEXT || KONTAKTMANAGEMENT_CONTEXT || HOME_CONTEXT;
    private wahlId: string | null = null;

    get itemsFound(): boolean {
        return this.schnellsucheErgebnisDaten?.length > 0;
    }

    constructor(
        private schnellsucheService: SchnellsucheService,
        private router: Router,
        private route: ActivatedRoute,
        public rightSidenavigationService: RightSidenavigationService,
        private alertService: AlertService,
        private detailsucheService: DetailsucheService,
        private loggingService: LoggingService
    ) {}

    /**
     * Baut Observables für die Suchleisteneingabe und die Http-Requests auf.
     */
    ngOnInit() {
        /**
         * Vorgehensweise für die Schnellsuche
         *
         *  1. In welchem Kontext wird die Schnellsuche durchgeführt
         *      z.B.: Schnellsuche in der Wahlvorbereitung
         *            Schnellsuche im Kontaktmanagement
         *
         *  2. Eingabestring auslesen und bei bestimmter Länge die Suche absetzen
         *  3. Je nach Kontext wird der jeweiligen Schnittstelle der Suchebegriff und weitere Parameter übergeben
         *  4. Ergebnisse aus der Suche werden in der Schnellsuchevorschau angezeigt
         */

        // Kontext standardmäßig auf KM setzen, da eine Suche im Wahlbestand ohne ausgewählter Wahl keinen Sinn macht
        this.context = KONTAKTMANAGEMENT_CONTEXT;

        /**
         * Wird in der Detailsuche ein Inputfeld angeklickt wird der Suchparameter aus dem Eingabefeld der Schnellsuche gelöscht.
         * Die Ergebnisliste bleibt aber erhalten.
         */
        this.clearSearchInputSubscription = this.schnellsucheService.clearSearchInput.subscribe(
            (preserveDropdownData: boolean) => {
                this.currentSearchInput = '';
                if (!preserveDropdownData) {
                    this.resetData();
                }
            }
        );

        this.searchInputChanges = this.searchInputSubject.asObservable().pipe(
            distinctUntilChanged(),
            tap(() => {
                this.searchChangeInProgress = true;
                this.enterPressedBeforeSearchCompletion = false;
            }),
            debounceTime(350)
        );

        let startFrom: number;

        this.schnellsucheSubscription = this.searchInputChanges
            .pipe(
                map((query: string) => {
                    startFrom = new Date().getTime();
                    return query ? query.trim() : query;
                }),
                switchMap((query: string) => {
                    this.resetData();

                    if (!query || query.length < MINIMUM_QUERY_LENGTH) {
                        this.closeAutocomplete();
                        return this.contextualEmptyResponseObject();
                    } else {
                        this.loading = true;
                        if (this.currentSearchInput && !this.enterPressedBeforeSearchCompletion) {
                            this.autocompleteOpen = true;
                        } else if (!this.currentSearchInput) {
                            this.searchInputSubject.next('');
                            return this.contextualEmptyResponseObject();
                        }
                        const serviceCall = this.contextualSearchApi(query);
                        return serviceCall.pipe(
                            catchError(() => {
                                return this.contextualEmptyResponseObject();
                            })
                        );
                    }
                })
            )
            .subscribe({
                // Alle Microservices sollten ein einheitliches Response-Objekt zurückgeben,
                // aus dem die Vorschläge der Schnellsuche erstellt werden
                next: (data: any) => {
                    // Workaround bis obiger Vertrag erfüllt ist:
                    // Für jeden Rückgabewert ein Mapping auf SchnellsucheErgebnisDaten durchführen
                    if (Object.hasOwn(data, SchnellsucheConstants.WAHLBESTANDSCHNELLSUCHEDATEN_TEXT)) {
                        this.mapWahlbestandSchnellsucheErgebnis(data);
                    } else if (Object.hasOwn(data, SchnellsucheConstants.SUCHERGEBNISSE_TEXT)) {
                        this.loggingService.logMetricWithElapsedTime(
                            LoggingConstants.KM_SCHNELLSUCHE,
                            startFrom,
                            new Date().getTime()
                        );
                        this.loggingService.logEvent(LoggingConstants.KM_SCHNELLSUCHE);
                        this.mapKontaktmanagementSchnellsucheErgebnis(data);
                    } else if (data) {
                        this.mapSchnellsucheErgebnis(data);
                    }
                    // Mapping für Wahlschnellsuche oder andere SchnellsucheErgebnisse einfügen
                    // Vorschau der Schnellsuchevorschläge erstellen
                    this.buildSchnellsucheVorschlaegeFromResult();
                    this.searchChangeInProgress = false;
                    this.loading = false;
                },
                error: (error: HttpErrorResponse) => {
                    console.log(error);
                    this.searchChangeInProgress = false;
                    this.loading = false;
                }
            });
    }

    /**
     * Beendet offene Subscriptions.
     */
    ngOnDestroy() {
        this.schnellsucheSubscription.unsubscribe();
        this.clearSearchInputSubscription.unsubscribe();
    }

    /**
     * Erstellt aus den SchnellsucheErgebnisDaten die Vorschläge für die Vorschau der Schnellsuche
     * Je nach Anzahl der Ergebnisse wird die Vorschau in mehrere Bereiche mit Sektionen unterteilt.
     */
    buildSchnellsucheVorschlaegeFromResult(): void {
        if (this.itemsFound) {
            const totalItemCount = this.schnellsucheErgebnisDaten
                .map((x) => x.ergebnis.length)
                .reduce((previousValue, currentValue) => previousValue + currentValue);

            let currentItemCount = 0;
            this.schnellsucheErgebnisDaten.forEach((value) => {
                if (currentItemCount >= Math.round(totalItemCount / 2)) {
                    this.itemsRight.push(value);
                } else {
                    this.itemsLeft.push(value);
                }
                currentItemCount += value.ergebnis.length;
            });
        }
    }

    /**
     * Liefert,abhängig vom Suchkontext, ein leeres Responseobjekt zurück, welches vom selben Interface
     * wie ein ResponseObjekt des Suchkontext abhängigen Microsservice ableitet.
     * @returns Leeres Responseobjekt abhängig vom Suchkontext
     */
    contextualEmptyResponseObject(): Observable<SchnellsucheErgebnis> {
        return of({ paramterIgnoriert: false, daten: [] } as SchnellsucheErgebnis);
    }

    /**
     * Liefert den kontextabhängigen Response der Schnittstelle im jeweiligen Microservice zurück
     * @param query Suchtext
     * @returns Response als Observable mit den Treffern der jeweiligen Schnellsuche
     */
    contextualSearchApi(
        query: string
    ): Observable<KontaktmanagementClient.SchnellsucheV2Response | WahlbestandSchnellsucheResponse | null> {
        if (this.getContextFromCurrentURL() === WAHL_CONTEXT) {
            return this.route.params.pipe(
                switchMap((params: Params): Observable<WahlbestandSchnellsucheResponse> => {
                    if (Object.hasOwn(params, PathIds.WAHL_ID)) {
                        this.wahlId = params[PathIds.WAHL_ID];
                        return this.schnellsucheService.getWahlbestandSchnellsucheErgebnis(this.wahlId, query);
                    }
                    return of(null);
                })
            );
        } else if (
            this.getContextFromCurrentURL() === KONTAKTMANAGEMENT_CONTEXT ||
            this.getContextFromCurrentURL() === HOME_CONTEXT
        ) {
            return this.schnellsucheService.getKontaktmanagementSchnellsucheErgebnis(query);
        } else {
            // Um Aufrufe für weitere Microservices erweitern

            return of(null);
        }
    }

    /**
     * Mapped das Rückgabeobjekt KontaktpersonenTrefferlisteQuery aus dem Service auf
     * SchnellsucheErgebnisDaten um damit die Schnellsuchevorschläge zu befüllen.
     * @param data QuickSearchPersonenResponse
     */
    mapKontaktmanagementSchnellsucheErgebnis(data: KontaktmanagementClient.SchnellsucheV2Response): void {
        this.schnellsucheErgebnisDaten = [];
        const ergebnis: Array<SchnellsucheErgebnisElement> = [];

        if (data.suchergebnisse.length > 0) {
            for (const kontakt of data.suchergebnisse) {
                const zusaetze: SchnellsucheErgebnisElementZusatz[] = [];

                kontakt.zusaetze.forEach((z: KontaktmanagementClient.SchnellsucheErgebnisZusatz) => {
                    const zusatz: SchnellsucheErgebnisElementZusatz = {
                        art: SchnellsucheErgebnisElementZusatzArtEnum[
                            KontaktmanagementClient.SchnellsucheErgebnisZusatzArt[z.art]
                        ],
                        text: z.text
                    };
                    zusaetze.push(zusatz);
                });

                ergebnis.push({
                    bezeichnung: kontakt.bezeichnung,
                    id: kontakt.id,
                    typ: SchnellsucheErgebnisElementTypEnum[
                        KontaktmanagementClient.SchnellsucheErgebnisTyp[kontakt.typ]
                    ],
                    istStandardadresseAktiv: kontakt.istStandardadresseAktiv,
                    zusaetze: zusaetze,
                    anzahlErgebnisse: data.total,
                    istAktiv: kontakt.istAktiv,
                    istVerzogen: kontakt.istVerzogen
                });
            }
            this.schnellsucheErgebnisDaten = [
                {
                    kategorie: SchnellsucheKategorieEnum.Kontakte,
                    ergebnis
                }
            ];
        }
    }

    /**
     * Mapped das Rückgabeobjekt QuickSearchPersonenResponse aus dem WvzService auf
     * SchnellsucheErgebnisDaten um damit die Schnellsuchevorschläge zu befüllen.
     * @param data QuickSearchPersonenResponse
     */
    mapWahlbestandSchnellsucheErgebnis(data: WahlbestandSchnellsucheResponse): void {
        this.schnellsucheErgebnisDaten = [];
        const ergebnis: Array<SchnellsucheErgebnisElement> = [];

        if (data.wahlbestandSchnellsucheDaten.length > 0) {
            for (const waehler of data.wahlbestandSchnellsucheDaten) {
                ergebnis.push({
                    bezeichnung: this.getWahlbestandPersonDisplayName(waehler),
                    id: waehler.id,
                    typ: SchnellsucheErgebnisElementTypEnum.Waehler,
                    anzahlErgebnisse: data.anzahlTreffer
                });
            }
            this.schnellsucheErgebnisDaten = [
                {
                    kategorie: SchnellsucheKategorieEnum.Waehler,
                    ergebnis
                }
            ];

            // Abhandlung der Suche, wenn Enter gedrückt wurde, bevor das Suchergebnis geladen werden konnte
            // Barcode-Scanner
            if (
                this.enterPressedBeforeSearchCompletion &&
                this.checkSchnellsucheTextForAntragscode(this.currentSearchInput) &&
                this.schnellsucheErgebnisDaten[0].ergebnis.length === 1
            ) {
                this.enterPressedBeforeSearchCompletion = false;
                this.navigateToWahlPages(this.schnellsucheErgebnisDaten[0].ergebnis[0]);
            }
        }
    }

    /**
     * Liefert den Namen für das autocomplete der Schnellsuche zurück
     */
    getWahlbestandPersonDisplayName(person: WahlbestandSchnellsucheDaten): string {
        return person.sprNrLfdNr + ', ' + person.name + ', ' + person.geburtsdatum + ', ' + person.adresse;
    }

    /**
     * Mapped das Rückgabeobjekt SchnellsucheErgebnis aus einem Microservice
     * um damit die Schnellsuchevorschläge zu befüllen.
     * @param data SchnellsucheErgebnis
     */
    mapSchnellsucheErgebnis(data: SchnellsucheErgebnis): void {
        this.schnellsucheErgebnisDaten = data?.daten?.filter((x: SchnellsucheErgebnisDaten) => x.ergebnis.length > 0);
    }

    /**
     * Liefert den Kontext der Schnellsuche anhand der derzeitigen URL zurück.
     *
     * @returns context Kontext der Schnellsuche
     */
    private getContextFromCurrentURL(): string {
        const currRoute = this.router.url;
        let context: string;

        if (currRoute.includes(`/${PathConstants.WAHL}`)) {
            context = WAHL_CONTEXT;
        } else {
            context = KONTAKTMANAGEMENT_CONTEXT;
        }
        return context;
    }

    /**
     * Setzt das Suchergebnis zurück
     */
    private resetData(): void {
        this.activeItem = null;
        this.activeItemIndex = -1;
        this.itemsLeft = [];
        this.itemsRight = [];
        this.schnellsucheErgebnisDaten = [];
    }

    /**
     * Behandelt alle Tastenanschläge der Suchbox.
     * @param event KeyboardEvent
     */
    onKeyUp(event: KeyboardEvent) {
        if (event.code === 'ArrowDown') {
            this.activeItemIndex++;
            if (this.activeItemIndex >= this.quickSearchItems.length) {
                this.activeItemIndex = -1;
                this.activeItem = null;
            } else {
                this.activeItem = this.quickSearchItems.toArray()[this.activeItemIndex].item;
            }
        } else if (event.code === 'ArrowUp') {
            event.preventDefault();

            this.activeItemIndex--;
            if (this.activeItemIndex < 0) {
                if (this.activeItemIndex < -1) {
                    this.activeItem = this.quickSearchItems.last.item;
                    this.activeItemIndex = this.quickSearchItems.length - 1;
                } else {
                    this.activeItemIndex = -1;
                    this.activeItem = null;
                }
            } else {
                this.activeItem = this.quickSearchItems.toArray()[this.activeItemIndex].item;
            }
        } else if (event.code === 'Escape') {
            this.closeAutocomplete();
        } else {
            this.searchInputSubject.next((event.target as HTMLInputElement).value);
        }
    }

    /**
     * Behandelt das Drücken der Entertaste im Eintabefeld
     * @param event Enter-Event
     */
    onEnter(event: KeyboardEvent): void {
        event.preventDefault();
        this.autocompleteOpen = false;

        // Wird Enter gedrückt bevor das Suchergebnis da ist, so wird das Enter zwischengespeichert
        if (this.searchChangeInProgress) {
            this.enterPressedBeforeSearchCompletion = true;

            // Im Wahlkontext bei einem Antragscode darf erst dann eine Weiterverarbeitung stattfinden, wenn die Daten geladen wurden.
            if (
                this.checkSchnellsucheTextForAntragscode(this.currentSearchInput) &&
                this.getContextFromCurrentURL() === WAHL_CONTEXT
            ) {
                return;
            }
        }

        if (this.activeItem) {
            // Konkreter Eintrag wurde ausgewählt
            this.navigateToDetailPage(this.activeItem);

            // Loggt ein Event, wenn die Schnellsuche ausreichend war, der User hat auf eine Ergebniszeile gelickt.
            this.loggingService.logEvent(LoggingConstants.KM_SCHNELLSUCHE_SUFFICIENT);
        } else if (this.currentSearchInput.length >= MINIMUM_QUERY_LENGTH) {
            this.navigateToListOrDetailPage();

            // Loggt ein Event, wenn die Schnellsuche nicht ausreichend wurde und der User mit Enter/Eingabe zum Detailsuche weitergeläutet wurde.
            this.loggingService.logEvent(LoggingConstants.KM_SCHNELLSUCHE_NOTSUFFICIENT);
        } else {
            this.alertService.info('Für die Suche sind mindestens ' + MINIMUM_QUERY_LENGTH + ' Zeichen notwendig.');
        }
    }

    /**
     * Navigiert zu der kontextabhängigen Listenansicht und führt dort eine Suche für die Anzeige als Liste durch
     */
    navigateToListPage(): void {
        const guid = this.detailsucheService.generateSessionStorageKey();
        this.detailsucheService.updateDetailsucheInputFields({});
        switch (this.getContextFromCurrentURL()) {
            case KONTAKTMANAGEMENT_CONTEXT: {
                const query: UIDetailsucheRequest = {
                    suchkriterien: {
                        suchstring: this.currentSearchInput
                    }
                };
                this.detailsucheService.saveSearchInStorage(query, guid);
                this.router.navigate([RouterLinkConstants.KONTAKTE_LISTE], { queryParams: { searchKey: guid } });
                break;
            }
            case WAHL_CONTEXT: {
                const suchkriterien: WahlbestandSuchkriterien = {
                    suchkriterien: null,
                    schnellsucheQuery: this.currentSearchInput,
                    sort: null
                };
                this.detailsucheService.saveSearchInStorage(suchkriterien, guid);
                if (!this.wahlId) {
                    this.route.params.subscribe({
                        next: (params: Params) => {
                            this.wahlId = params[PathIds.WAHL_ID];
                            this.router.navigate([RouterLinkConstants.WAHL, this.wahlId, PathConstants.WAHLBESTAND], {
                                queryParams: { searchKey: guid }
                            });
                        }
                    });
                } else {
                    this.router.navigate([RouterLinkConstants.WAHL, this.wahlId, PathConstants.WAHLBESTAND], {
                        queryParams: { searchKey: guid }
                    });
                }
                break;
            }
        }
    }

    /**
     * Navigiert zu der kontextabhängigen Detailansicht und führt im Wahl-Context eine Prüfung auf das Suchtextformat durch.
     * Entspricht der Suchtext eine bestimmten Format wird zum Wahlkartetab der Detailansicht navigiert.
     * @param item Item der Schnellsuche für die Navigation
     */
    navigateToDetailPage(item: SchnellsucheErgebnisElement): void {
        switch (this.getContextFromCurrentURL()) {
            case WAHL_CONTEXT:
                this.navigateToWahlPages(item);
                break;
            case KONTAKTMANAGEMENT_CONTEXT:
                this.navigateToKontaktmanagementPages(item);
                break;
        }
    }

    /**
     * Routet zu den Detailseiten des Kontaktmanagements um die Darstellung
     * des ausgewählten Ergebnisses anzuzeigen.
     * @param item SchnellsucheErgebnisElement
     */
    navigateToKontaktmanagementPages(item: SchnellsucheErgebnisElement): void {
        switch (item.typ) {
            case SchnellsucheErgebnisElementTypEnum.NatuerlichePerson:
                this.router.navigate([RouterLinkConstants.KONTAKTE_PERSON_DETAIL, item.id]);
                break;
            case SchnellsucheErgebnisElementTypEnum.Unternehmen:
                this.router.navigate([RouterLinkConstants.KONTAKTE_UNTERNEHMEN_DETAIL, item.id]);
                break;
        }
    }

    /**
     * Routet zu den Detailseiten der Wahlvorbereitung um die Darstellung
     * des ausgewählten Ergebnisses anzuzeigen.
     * @param item SchnellsucheErgebnisElement
     */
    navigateToWahlPages(item: SchnellsucheErgebnisElement): void {
        if (item.typ === SchnellsucheErgebnisElementTypEnum.Waehler) {
            if (this.checkSchnellsucheTextForAntragscode(this.currentSearchInput)) {
                this.router.navigate([RouterLinkConstants.WAHL, this.wahlId, PathConstants.WAHLBESTAND, item.id], {
                    queryParams: { tab: '1' /* Tab Wahlkarte */, antragscodeEntered: true }
                });
            } else {
                this.router.navigate([RouterLinkConstants.WAHL, this.wahlId, PathConstants.WAHLBESTAND, item.id]);
            }
        }
    }

    /**
     * Schließt die seitliche Suchleiste, wenn diese geöffnet ist
     */
    inputClick(): void {
        if (this.rightSidenavigationService.rightSidenavOpen) {
            this.rightSidenavigationService.closeRightSidenav();
            // Durch ein Timeout wird das Input Element erst nach der bool-Änderung ausgeführt
            setTimeout(() => this.searchInput.nativeElement.focus(), 0);
        }
    }

    /**
     * Ändert die Suche zur erweiterten Suche.
     */
    extendSearch() {
        // Autocomplete schließen wenn in die Detailsuche gewechselt wird
        if (!this.rightSidenavigationService.rightSidenavOpen) {
            this.closeAutocomplete();
        }

        this.rightSidenavigationService.toggleRightSidenav();
    }

    /**
     * Prüft, ob ein Suchstring dem Format eines Antragscodes entspricht
     * 12 oder 14 Zeichen (RN8T 1821 8JK9 oder RN8T18218JK9)
     * @param suchtext String der Sucheingabe
     * @returns Boolean, ob die Prüfung Der RegEx entspricht
     */
    private checkSchnellsucheTextForAntragscode(suchtext: string): boolean {
        if (!suchtext) {
            return false;
        }

        suchtext = suchtext.trim();
        return SchnellsucheConstants.ANTRAGSCODE_REGEXP.test(suchtext);
    }

    /**
     * Abhandlung der Navigation nach einem Suchergebnis
     * Gibt es nur 1 Suchergebnis, wird auf eine Detail-Page weitergeleitet
     * Gibt es mehrere Suchergebnisse, wird eine Liste angezeigt
     */
    private navigateToListOrDetailPage(): void {
        if (
            this.schnellsucheErgebnisDaten &&
            this.schnellsucheErgebnisDaten.length > 0 &&
            this.schnellsucheErgebnisDaten[0]?.ergebnis?.length === 1
        ) {
            this.navigateToDetailPage(this.schnellsucheErgebnisDaten[0].ergebnis[0]);
        } else {
            this.navigateToListPage();
        }
    }

    /**
     * Schließt die Autocomplete-Ansicht
     */
    private closeAutocomplete(): void {
        this.activeItem = null;
        this.activeItemIndex = -1;
        this.autocompleteOpen = false;
    }

    /**
     * Horcht auf lokale Click Events um
     * die Vorschläge einzublenden.
     */
    @HostListener('click')
    clickInside() {
        this.clickWasInside = true;
        if (
            !this.autocompleteOpen &&
            (this.schnellsucheErgebnisDaten?.length > 0 || this.currentSearchInput) &&
            !this.rightSidenavigationService.rightSidenavOpen
        ) {
            this.autocompleteOpen = true;
        }
    }

    /**
     * Horcht auf globale Click Events um
     * die Vorschläge auszublenden.
     */
    @HostListener('document:click')
    clickOutside() {
        if (!this.clickWasInside) {
            this.autocompleteOpen = false;
        }
        this.clickWasInside = false;
    }
}
