/**
 * Konstanten im Zusammenhang mit Kontakte-Tooltip.
 */
export class KontakteTooltipConstants {
    /*******************
     * Kontakte Suchen
     *******************/
    /**
     * Tool-Tipp-Inhalt für die Suche nach Kontakten.
     */
    static readonly KONTAKT_SUCHEN_TOOLTIP_CONTENT =
        'Je nach Suchkriterium haben Sie unterschiedliche Möglichkeiten für die Gestaltung Ihrer Suchbegriffe';

    /**
     * Tooltip Link für Kontakte suchen.
     */
    static readonly KONTAKTE_SUCHEN_TOOLTIP_LINK = 'https://hilfe.k5next.at/kontakt/suchfelder-suchmoglichkeiten';

    /************************
     * Person anlegen Button
     ************************/
    /**
     * Der Inhalt des Tooltipps für den Person Anlegen Button.
     */
    static readonly KONTAKT_PERSON_ANLEGEN_TOOLTIP_CONTENT = 'Person oder Unternehmen anlegen';

    /**
     * Tooltip Link für den Person anlegen Button.
     */
    static readonly KONTAKTE_PERSON_ANLEGEN_TOOLTIP_LINK = 'https://hilfe.k5next.at/kontakt/kontakte-verwalten';

    /***********************
     * Suchergebnis teilen
     **********************/
    /**
     * Der Inhalt des Tooltipps für den Suchergebnis teilen Button.
     */
    static readonly KONTAKT_SUCHERGEBNIS_TEILEN_TOOLTIP_CONTENT = 'Suchergebnis teilen';

    /**
     * Tooltip Link für den Suchergebnis teilen Button.
     */
    static readonly KONTAKTE_SUCHERGEBNIS_TEILEN_TOOLTIP_LINK = 'https://hilfe.k5next.at/kontakt/suchergebnis-teilen';

    /*****************
     * Zustimmung für
     *****************/
    /**
     * Der Inhalt des Tooltipps für das "Zustimmung für" Label.
     */
    static readonly KONTAKT_ZUSTIMMUNG_FOR_TOOLTIP_CONTENT =
        'Damit Sie die Standard E-Mail-Adresse für die elektronische Zustellung verwenden dürfen, muss mindestens ein Zustimmungsbereich gesetzt werden';

    /**
     * Tooltip Link für das "Zustimmung für" Label.
     */
    static readonly KONTAKTE_ZUSTIMMUNG_FOR_TOOLTIP_LINK = 'https://hilfe.k5next.at/kontakt/zustimmungserklarungen';
}

/**
 * Konstanten im Zusammenhang mit der Konfiguration.
 */
export class KonfigurationTooltipConstants {
    /***********************************
     * Gesamtabgleich durchführen Button
     ***********************************/
    /**
     * Der Inhalt des Tooltipps für den Gesamtabgleich durchführen Button.
     */
    static readonly KONFIGURATION_GESAMTABGLEICH_TOOLTIP_CONTENT = 'Gesamtabgleich durchführen';

    /**
     * Tooltip link für den Gesamtabgleich durchführen Button.
     */
    static readonly KONFIGURATION_GESAMTABGLEICH_TOOLTIP_LINK = 'https://hilfe.k5next.at/basis/zmr-abgleich';
}

/**
 * Eine Klasse, die standard Konstanten für die Tooltipps enthält.
 */
export class StandardTooltipConstants {
    /**
     * Der Standard-Alias für einen Tooltip.
     */
    static readonly STANDARD_TOOLTIP_ALIAS = 'Weitere Infos';
}
