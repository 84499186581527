import { Component, Input } from '@angular/core';

@Component({
    selector: 'k5-error',
    templateUrl: './error.component.html'
})
export class ErrorComponent {
    /**
     * Nachricht für die Fehlermeldung
     */
    @Input()
    message: string;
}
