import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { MandantClient } from '@shared/models/mandantenClient';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    config: AppConfig = null;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {
        this.config = this.configService.getConfig();
    }

    /**
     * Gibt alle Benutzer zurück, mit welchen eine Suche geteilt werden kann
     */
    getBenutzerToShareWith(): Observable<MandantClient.UITeilenMitBenutzerListe> {
        return this.httpClient.get<MandantClient.UITeilenMitBenutzerListe>(
            `${this.config.k5Mandanten.mandantenServiceBaseUrlUIApi}/Benutzer/ListeTeilenMit`
        );
    }
}
