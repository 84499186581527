<k5-dialog-heading [heading]="title" [showClose]="false"></k5-dialog-heading>

<k5-dialog-content>
    <div>{{ content }}</div>
    <form [formGroup]="begruendungForm">
        <mat-form-field class="w-full">
            <mat-label id="begruendungLabel">Anmerkung</mat-label>
            <input
                aria-labelledby="begruendungLabel"
                matInput
                formControlName="begruendung"
                name="begruendung"
                autocomplete="off"
                #input
            />
            <mat-hint *ngIf="maxTextLength" align="end">{{ input.value.length }} / {{ maxTextLength }}</mat-hint>
            <mat-error>
                {{ formErrorMessageService.getFormErrorMessage('begruendung', begruendungForm, 'Begründung') }}
            </mat-error>
        </mat-form-field>
    </form>
</k5-dialog-content>

<k5-dialog-actions [alignEnd]="true">
    <button mat-stroked-button color="primary" type="button" (click)="cancelDialog()">Abbrechen</button>
    <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="validation && !(begruendungForm.valid && begruendungForm.dirty)"
        (click)="acceptDialog()"
    >
        {{ actionText }}
    </button>
</k5-dialog-actions>
