import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Wahldaten } from '@shared/models/wahldaten';
import { WahldetailService } from 'src/app/buergerservice/wahl/services/wahldetail.service';
import { WahlkartenAntragsartStatistikResponse } from '@shared/models/wahlkarten-antragsart-statistik-response';
import { ChartLegend } from '@shared/models/chart-legend';
import { DEFAULT_THEME } from '@shared/models/chartColors';
import { ChartOptions } from 'chart.js';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { Dictionary } from '@core/models/dictionary';
import { WahlDetailsuche } from '@shared/constants/shared-constants';
import { WahlbestandSuchkriterien } from '@shared/models/wahlbestandSuchkriterien';
import { Router } from '@angular/router';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { AntragsartEnum } from '@shared/models/wahlkarten-enums';

@Component({
  selector: 'k5-wahlkarten-antragsart-widget',
  templateUrl: './wahlkarten-antragsart-widget.component.html'
})
export class WahlkartenAntragsartWidgetComponent implements OnInit {
  @Input()
  wahl: Wahldaten | null = null;

  @Output()
  remove: EventEmitter<Event> = new EventEmitter<Event>();

  wahlkartenAntragsartStatistik: WahlkartenAntragsartStatistikResponse = null;

  dataSource: ChartLegend[] = [];

  // Pie chart
  pieChartOptions: ChartOptions<'pie'> = null;
  pieChartLabels: string[] = [];
  pieChartDatasets: any[] = [];
  pieChartLegend: boolean = false;

  colorScheme = {
    domain: DEFAULT_THEME
  };

  loading: boolean = true;
  error: boolean = false;
  

  constructor(
    private wahlService: WahldetailService,
    private detailsucheService: DetailsucheService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadWidgetData();
  }

  removeWidget(event: Event): void {
    this.remove.emit(event);
  }

  loadWidgetData() {
    this.loading = true;
    this.error = false;
    this.wahlService.getWahlkartenAntragsartStatistik(this.wahl?.id).subscribe({
      next: (data: WahlkartenAntragsartStatistikResponse) => {
        this.wahlkartenAntragsartStatistik = data;
        this.buildLegend(data);
        this.loading = false;
      },
      error: () => {
        this.error = true;
        this.loading = false;
      }
    });
  }

  buildLegend(data: WahlkartenAntragsartStatistikResponse) {
    const legend: ChartLegend[] = [
      {
        id: AntragsartEnum.ElektPortal,
        name: "Elektronisch",
        amount: data.anzahlElektronisch,
        color: DEFAULT_THEME[0]
      },
      {
        id: AntragsartEnum.Persoenlich,
        name: "Persönlich",
        amount: data.anzahlPersoenlich,
        color: DEFAULT_THEME[1]
      },
      {
        id: AntragsartEnum.Schriftlich,
        name: "Schriftlich",
        amount: data.anzahlSchriftlich,
        color: DEFAULT_THEME[2]
      },
      {
        id: AntragsartEnum.Abo,
        name: "Wahlkartenabo",
        amount: data.anzahlWahlkartenabo,
        color: DEFAULT_THEME[3]
      }
    ];

    this.dataSource = legend;

    this.pieChartDatasets = [
      {
        data: legend.map((x) => x.amount),
        backgroundColor: DEFAULT_THEME
      }
    ];

    this.pieChartLabels = legend.map((x) => x.name);

    this.pieChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        animation: false
    };
  }

  navigateToWahlbestand(item: ChartLegend){
    let offeneBearbeitungen = item.id ?? AntragsartEnum.ElektPortal;

    const guid = this.detailsucheService.generateSessionStorageKey();
    let suchkriterien: Dictionary<string> = {};
    suchkriterien[WahlDetailsuche.ANTRAGSART] = offeneBearbeitungen.toString();

    const wahlbestandSuchkriterien: WahlbestandSuchkriterien = {
        suchkriterien,
        schnellsucheQuery: null,
        sort: null
    };
    this.detailsucheService.saveSearchInStorage(wahlbestandSuchkriterien, guid);
    this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id, PathConstants.WAHLBESTAND], {
        queryParams: { searchKey: guid }
    });
  }
}
