import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@core/services/alert.service';
import { UITeilenMitBenutzer } from '@shared/models/uiTeilenMitBenutzer';
import { UITeilenMitBenutzerListe } from '@shared/models/uiTeilenMitBenutzerListe';
import { ShareService } from '@shared/services/share.service';

export interface UserShareDialogData {
    title: string;
    selectedUsers?: string[];
    required?: boolean;
    allowMultipleSelection?: boolean;
    saveDisabled: boolean;
}

@Component({
    selector: 'k5-user-share-dialog',
    templateUrl: './user-share-dialog.component.html',
    styleUrls: ['./user-share-dialog.component.scss']
})
export class UserShareDialogComponent implements OnInit {
    saveSelection: EventEmitter<UITeilenMitBenutzer[]> = new EventEmitter<UITeilenMitBenutzer[]>();

    selection = new SelectionModel<UITeilenMitBenutzer>(true, []);

    completeUserList: UITeilenMitBenutzerListe;
    displayedUserList: UITeilenMitBenutzer[] = [];

    loading = true;
    saveButtonDisabled = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserShareDialogData,
        public dialogRef: MatDialogRef<UserShareDialogComponent>,
        private alertService: AlertService,
        private shareService: ShareService
    ) {}

    /**
     * Gibt an, ob der Speichern Button disabled ist
     */
    get savingDisabled(): boolean {
        return (this.data.required && this.selection.selected.length === 0) || this.saveButtonDisabled;
    }

    ngOnInit(): void {
        this.shareService.getBenutzerToShareWith().subscribe({
            next: (users: UITeilenMitBenutzerListe) => {
                this.completeUserList = users;
                this.displayedUserList = this.completeUserList.benutzer;

                if (this.data.selectedUsers) {
                    for (const benutzer of this.data.selectedUsers) {
                        const index = this.completeUserList.benutzer.findIndex((user) => user.data.id === benutzer);
                        if (index >= 0) {
                            this.completeUserList.benutzer[index].alreadySharedWith = true;
                            this.selection.select(
                                this.completeUserList.benutzer.find((user) => user.data.id === benutzer)
                            );
                        }
                    }
                }

                this.completeUserList.benutzer = this.completeUserList.benutzer.sort(
                    (a: UITeilenMitBenutzer, b: UITeilenMitBenutzer) => {
                        let sortIndex = 0;
                        sortIndex = a.data.nameSort.localeCompare(b.data.nameSort);

                        if (a.alreadySharedWith && !b.alreadySharedWith) {
                            sortIndex -= 2;
                        } else if (!a.alreadySharedWith && b.alreadySharedWith) {
                            sortIndex += 2;
                        }

                        return sortIndex;
                    }
                );

                this.displayedUserList = this.completeUserList.benutzer;
                this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
                this.loading = false;
                this.alertService.errorResponse(
                    error,
                    'Fehler bei der Abfrage der Benutzer, mit denen geteilt werden kann'
                );
            }
        });
    }

    /**
     * Emittiert wenn der Dialog gespeichert werden soll
     */
    save(): void {
        this.saveSelection.emit(this.selection.selected);
    }

    /**
     * Sucht nach Usern nach Eingabe eines Buchstaben
     * @param search Sucheingabe
     */
    searchUsers(search: string): void {
        this.displayedUserList = this.completeUserList.benutzer.filter((user) =>
            user.data.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    /**
     * Gibt an, ob der Bereich "Bereits geteilt mit" angezeigt werden soll
     * @param index Index des Users
     * @returns Ob der Bereich angezeigt werden soll
     */
    checkIfAlreadySharedShouldBeDisplayed(index: number): boolean {
        return index === 0 && this.displayedUserList.filter((user) => user.alreadySharedWith === true).length > 0;
    }

    /**
     * Gibt an ob der Sortierbuchstabe angezeigt werden soll
     * Dieser wird angezeigt, wenn mit dem Benutzer noch nicht geteilt wurde oder der Familienname mit dem nächsten Buchstaben anfängt.
     * @param index Index des Users
     * @param userBefore User mit index -1
     * @param userCurrent aktueller User
     * @returns Ob der Sortierbuchstabe angezeigt werden soll
     */
    checkIfSortingCharShouldBeDisplayed(
        index: number,
        userBefore: UITeilenMitBenutzer | null,
        userCurrent: UITeilenMitBenutzer
    ): boolean {
        return (
            (index > 0 &&
                userBefore?.data.familienname.charAt(0) !== userCurrent.data.familienname.charAt(0) &&
                !userCurrent.alreadySharedWith) ||
            (index === 0 && !userCurrent.alreadySharedWith) ||
            (userBefore?.alreadySharedWith && !userCurrent.alreadySharedWith)
        );
    }

    /**
     * Selektiert alle sichtbaren Reihen in der Liste, falls nicht alle ausgewählt sind,
     * andernfalls wird die Selektion für alle aufgehoben.
     */
    selectAll(): void {
        this.saveButtonDisabled = false;
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.completeUserList.benutzer.forEach((row) => this.selection.select(row));
        }
    }

    /**
     * Togglet die Selektion eines Benutzers
     * @param user User
     */
    toggleSelection(user: UITeilenMitBenutzer): void {
        if (this.selection.selected.length > 0 && this.data.allowMultipleSelection === false) {
            this.selection.clear();
        }
        this.selection.toggle(user);
        this.saveButtonDisabled = false;
    }

    /**
     * Überprüft ob die Anzahl der selektierten Reihen der Gesatmtanzahl an Reihen entspricht
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.completeUserList.benutzer.length;
        if (numRows === 0 || numSelected === 0) {
            return false;
        }
        return numSelected === numRows;
    }

    /**
     * Gibt an, ob ein User bereits selekiert wurde
     * @param user User dessen Selektion gecheckt werden soll
     * @returns Ob der User bereits selektiert wurde
     */
    userSelected(user: UITeilenMitBenutzer): boolean {
        return this.selection.isSelected(user);
    }
}
