import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePair } from '@core/models/key-value';

@Pipe({
    name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

    /**
     * Wandelt ein Enum in ein Array um
     * @param data Enum
     * @returns Array mit Enum Werten
     */
    transform(data: Object): KeyValuePair[] {
        let result: KeyValuePair[] = [];
        let keys = Object.keys(data);
        for (let key of keys) {
            result.push({ key: key, value: data[key] });
        }
        return result;
    }

}
