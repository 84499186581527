<k5-widget id="gespeicherteSucheWidgetContent" [title]="titelText">
    <div *ngIf="!loading; else loadingIndicator" class="flex h-full flex-col">
        <k5-header-search
            [centerSearch]="true"
            (inputChanged)="search($event)"
            id="gespeicherteSucheFilter"
        ></k5-header-search>

        <div class="mt-4 overflow-auto">
            <div *ngIf="filteredGespeicherteSuchen?.length === 0" class="font-bold">
                Keine gespeicherten Suchen gefunden
            </div>
            <div
                *ngFor="let gespeicherteSuche of filteredGespeicherteSuchen; let i = index"
                class="suche-list-item flex items-center border-b border-solid border-gray-300 p-4"
                (click)="navigateToKM(gespeicherteSuche.suche.id)"
            >
                <div class="pr-4">
                    <i
                        *ngIf="gespeicherteSuche.suche.istEigeneSuche"
                        class="fa-duotone fa-search fa-2x text-gray-500"
                    ></i>
                    <i
                        *ngIf="!gespeicherteSuche.suche.istEigeneSuche"
                        class="fa-duotone fa-share-nodes fa-2x text-gray-500"
                    ></i>
                </div>
                <div class="flex flex-col">
                    <span class="search-name pb-2" data-cy="gespeicherte-suche-widget-span-gespeicherte-suchen">
                        {{ gespeicherteSuche.suche.name }}
                    </span>
                    <span
                        *ngIf="gespeicherteSuche.suche.istEigeneSuche"
                        class="text-xs text-gray-500"
                        [matTooltip]="
                            'zuletzt geändert am ' +
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteSuche.suche.letzteAenderung
                            ) +
                            ' von ' +
                            gespeicherteSuche.suche.geaendertVon
                        "
                    >
                        {{
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteSuche.suche.letzteAenderung
                            )
                        }},
                        {{ gespeicherteSuche.suche.geaendertVon }}
                    </span>
                    <span
                        *ngIf="!gespeicherteSuche.suche.istEigeneSuche"
                        class="text-xs text-gray-500"
                        [matTooltip]="
                            'zuletzt geändert am ' +
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteSuche.suche.letzteAenderung
                            ) +
                            ' von ' +
                            gespeicherteSuche.suche.geaendertVon
                        "
                    >
                        {{
                            DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                                gespeicherteSuche.suche.letzteAenderung
                            )
                        }},
                        {{ gespeicherteSuche.suche.geaendertVon }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loadingIndicator>
        <k5-loading message="Gespeicherte Suchen werden geladen ..."></k5-loading>
    </ng-template>
</k5-widget>
