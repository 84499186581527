import { Component, Input } from '@angular/core';

/**
 * Kompontente für die Darstellung eines Ladeindikators
 */
@Component({
    selector: 'k5-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    /**
     * Nachricht für den Progress spinner
     */
    @Input()
    message: string;

    @Input()
    fontSize: 'small' | 'normal' | 'large' = 'normal';

    @Input()
    diameter: number = 64;

    @Input()
    strokeWidth: number = 3;
}
