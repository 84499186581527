import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionButtonEnum } from '@shared/models/enums';

/**
 * Interface für die Übertragung der Daten über MAT_DIALOG_DATA injection
 */
export interface ConfirmationDialogData {
    title: string;
    content: string;
    isLoading?: boolean;
    contentLineBreak?: boolean;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    tertiaryButtonLabel?: string;
    primaryButtonDisabled?: boolean;
    secondaryButtonDisabled?: boolean;
    showSecondaryButton?: boolean;
    tertiaryButtonDisabled?: boolean;
    showTertiaryButton?: boolean;
}

@Component({
    selector: 'k5-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    contentLineBreak: boolean = false;

    primaryButtonLabel: string = 'Löschen';

    secondaryButtonLabel: string = 'Abbrechen';

    tertiaryButtonLabel: string = 'Schließen';

    showSecondaryButton: boolean = true;

    showTertiaryButton: boolean = false;

    @Output()
    dialogAction: EventEmitter<ActionButtonEnum> = new EventEmitter<ActionButtonEnum>();

    ACTION_BUTTON_VALUES = ActionButtonEnum;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

    /**
     * Inputwerte, falls vorhanden, mit Daten aus MAT_DIALOG_DATA befüllen
     */
    ngOnInit() {
        if (this.data) {
            this.contentLineBreak =
                this.data.contentLineBreak != undefined || null ? this.data.contentLineBreak : this.contentLineBreak;
            this.primaryButtonLabel = this.data.primaryButtonLabel
                ? this.data.primaryButtonLabel
                : this.primaryButtonLabel;
            this.secondaryButtonLabel = this.data.secondaryButtonLabel
                ? this.data.secondaryButtonLabel
                : this.secondaryButtonLabel;
            this.tertiaryButtonLabel = this.data.tertiaryButtonLabel
                ? this.data.tertiaryButtonLabel
                : this.tertiaryButtonLabel;
            this.showSecondaryButton =
                this.data.showSecondaryButton != undefined || null
                    ? this.data.showSecondaryButton
                    : this.showSecondaryButton;
            this.showTertiaryButton =
                this.data.showTertiaryButton != undefined || null
                    ? this.data.showTertiaryButton
                    : this.showTertiaryButton;
        }
    }

    /**
     * Benachrichtigt andere Komponente nach Button Klick
     * @param actionButtonValue Gibt an welcher Button geklickt wurde
     */
    handleButton(actionButtonValue: ActionButtonEnum) {
        switch (actionButtonValue) {
            case ActionButtonEnum.Primary:
                this.dialogAction.emit(ActionButtonEnum.Primary);
                break;
            case ActionButtonEnum.Secondary:
                this.dialogAction.emit(ActionButtonEnum.Secondary);
                break;
            case ActionButtonEnum.Tertiary:
                this.dialogAction.emit(ActionButtonEnum.Tertiary);
                break;
        }
    }
}
