import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { Wahldaten } from '@shared/models/wahldaten';
import { WahlkalenderService } from '@shared/services/wahlkalender.service';
import { ElectionTimelineItem } from '@shared/wahlkalenderItem';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'k5-timeline-widget',
    templateUrl: './timeline-widget.component.html'
})
export class TimelineWidgetComponent implements OnInit {
    @Input()
    wahl: Wahldaten | null = null;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    timelineEntries: ElectionTimelineItem[] = null;

    error: boolean = false;
    loading: boolean = true;

    constructor(private router: Router, private wahlkalenderService: WahlkalenderService) {}

    ngOnInit(): void {
        this.loadWidgetData();
    }

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }

    /**
     * Lädt die Daten für die Anzeige im Widget
     */
    loadWidgetData(): void {
        this.loading = true;
        this.error = false;
        this.wahlkalenderService.getWahlkalenderEintraege(this.wahl?.id).subscribe({
            next: (data: ElectionTimelineItem[]) => {
                this.timelineEntries = data;
                this.loading = false;
            },
            error: () => {
                this.error = true;
                this.loading = false;
            }
        });
    }

    /**
     * Navigiert zu den Wahldetails
     */
    navigateToWahlDetails(): void {
        this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id], {
            queryParams: {
                tab: 0
            }
        });
    }
}
