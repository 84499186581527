/**
 * Konstanten für das UI
 */
export class UiConstants {
    /**
     * Localization string
     */
    static readonly LOCALE_AT = 'de-AT';

    /**
     * UI Initialisierung
     */
    static readonly UIINFO = 'UI-Info';
    static readonly UIRECHTE = 'UI-Rechte';
    static readonly WAPPEN = 'wappen';
    static readonly WAPPEN_IMAGE = 'wappen-image';
    static readonly START_MANDANT = 'start-mandant';
    static readonly THEME = 'farb-schema';
    static readonly UNTERSCHRIFT_VORANGESTELLT = 'unterschrift-vorangestellt';
    static readonly UNTERSCHRIFT = 'unterschrift';
    static readonly PERSONLIST_COLUMNS = 'personliste-spalten';
    static readonly KONTAKT_LISTE_COLUMNS = 'kontakt-liste-spalten';
    static readonly KONTAKT_SEARCH_CRITERIA = 'kontakt-detailsuche-suchkriterien';
    static readonly GRUPPEN_LISTE_COLUMNS = 'gruppen-liste-spalten';
    static readonly KONTAKT_LISTE_SORTING = 'kontakt-liste-sortierung';
    static readonly GRUPPEN_LISTE_SORTING = 'gruppen-liste-sortierung';
    static readonly WAHLKARTENANTRAG_LISTE_COLUMNS = 'wahlkartenantrag-liste-spalten';
    static readonly WVZ_COLUMNS = 'wvz-spalten';
    static readonly WAHL_DASHBOARD = 'wahl-dashboard';
    static readonly INFORMATION_LOADED = 'Informationen wurden geladen.';
    static readonly INFORMATION_ERROR = 'Fehler beim Laden der Inforamtionen.';
    static readonly UI_ACCESS_DENIED_ERROR_ID = 'admin-not-allowed-for-k5next';
    static readonly WAPPEN_FALLBACK_URL = 'assets/img/k5next_logo_minimal.svg';

    // UI Kategorien
    static readonly KATEGORIE_WAHL = 'ui-wahl';
    static readonly KATEGORIE_KONTAKT = 'ui-kontakt';
    static readonly KATEGORIE_PERSONEN = 'ui-person';
    static readonly KATEGORIE_SPRENGEL = 'ui-sprengel';
    static readonly KATEGORIE_ADRESSEN = 'ui-adressen';

    /**
     * Header Konstanten
     */
    static readonly MANDANT_ID_HEADER = 'k5-MandantID';

    /**
     * Konstanten für die Personenliste
     */
    static readonly PERSONEN_DEFAULT_COLUMNS = ['name-kompakt', 'adresse-kompakt', 'ort-kompakt'];
    static readonly KONTAKTE_DEFAULT_COLUMNS = ['name-sort', 'adresse', 'ort-kompakt'];
    static readonly SAVE_COLUMNS_SUCCESS = 'Ausgewählte Spalten erfolgreich gesichert.';
    static readonly SAVE_COLUMNS_ERROR = 'Sicherung der ausgewählten Spalten fehlgeschlagen!';

    /**
     * Property Namen
     */
    static readonly PROPERTY_VORNAME = 'vorname';
    static readonly PROPERTY_FAMILIENNAME = 'familienname';
    static readonly PROPERTY_UNTERNEHMENS_NAME = 'name';

    /**
     * Konstanten für die Konfiguration
     */
    static readonly SAVE_COLOR_SUCCESS = 'Ausgewähltes Farbschema erfolgreich gesichert.';
    static readonly SAVE_COLOR_ERROR = 'Sicherung des ausgewählten Farbschemas fehlgeschlagen!';

    /**
     * Rechte
     */
    static readonly PROTOKOLL_READ = 'protokoll-read';
    static readonly ADMINISTRATOR_MODIFY = 'administrator-modify';
    static readonly BENUTZER_MODIFY = 'benutzer-modify';
    static readonly BENUTZER_DELETE = 'benutzer-delete';
    static readonly ROLLE_MODIFY = 'rolle-modify';
    static readonly ROLLE_DELETE = 'rolle-delete';
    static readonly MANDANT_CREATE = 'mandant-create';
    static readonly MANDANT_MODIFY = 'mandant-modify';
    static readonly MANDANT_DELETE = 'mandant-delete';
    static readonly MODUL_READ = 'modul-read';
    static readonly MODUL_MODIFY = 'modul-modify';
    static readonly PERSON_READ = 'person-read';
    static readonly PERSON_PROCESS = 'person-process';
    static readonly PERSON_DELETE = 'person-delete';
    static readonly PERSON_GENERATE = 'person-testdaten-generate';
    static readonly PERSON_CLEARING = 'person-clearing';
    static readonly KONTAKT_READ = 'kontakt-read';
    static readonly KONTAKT_PROCESS = 'kontakt-process';
    static readonly KONTAKT_DELETE = 'kontakt-delete';
    static readonly WAHL_READ = 'wahl-read';
    static readonly WAHL_PROCESS = 'wahl-process';
    static readonly WAHL_ADMINISTRATION = 'wahl-administration';
    static readonly WAHL_MONITORING = 'wahl-monitoring';
    static readonly WAHL_GENERATE = 'wahl-testdaten-generate';
    static readonly EINWOHNER_READ = 'einwohner-read';
    static readonly ADRESSE_READ = 'adresse-read';
    static readonly ADRESSE_MODIFY = 'adresse-modify';
    static readonly SPRENGEL_READ = 'sprengel-read';
    static readonly SPRENGEL_MODIFY = 'sprengel-modify';
    static readonly SPRENGEL_DELETE = 'sprenge-delete';
    static readonly WAHLTAG_READ = 'wahltag-read';
    static readonly VERFAHREN_READ = 'verfahren-read';
    static readonly ZMR_ABGLEICH_READ = 'person-zmr-abgleich-read';
    static readonly ZMR_ABGLEICH_PROCESS = 'person-zmr-abgleich-execute';
    static readonly TAG_MANAGE = 'kontakt-tag-manage';

    /**
     * Labels
     */
    static readonly HOME_LABEL = 'Home';
    static readonly KONTAKTE_LABEL = 'Kontakte';
    static readonly KONTAKT_LISTE_LABEL = 'Suche';
    static readonly GRUPPEN_LABEL = 'Gruppen';
    static readonly KONTAKTE_CLEARING_LABEL = 'Clearing';
    static readonly PERSONENVERWALTUNG_LABEL = 'Personenverwaltung';
    static readonly PERSONEN_LABEL = 'Personen';
    static readonly CLEARING_LABEL = 'Clearing';
    static readonly WAHL_LABEL = 'Wahl';
    static readonly AUSWERTUNGEN_LABEL = 'Auswertungen';
    static readonly WAHLBESTAND_LABEL = 'Wahlbestand';
    static readonly LISTEN_LABEL = 'Listen';
    static readonly SPRENGEL_LABEL = 'Sprengel';
    static readonly ZMR_ABGLEICH_LABEL = 'ZMR-Abgleich';
    static readonly TAGS_LABEL = 'Tags';
    static readonly KONFIGURATION_LABEL = 'Konfiguration';
    static readonly KONFIGURATION_WAHL_LABEL = 'Einstellungen';
    static readonly KONFIGURATION_ADRESSEN_LABEL = 'Adressen';
    static readonly MEINE_WAHLKARTE_LABEL = 'Meine Wahlkarte';
    static readonly WAHLTAG_LABEL = 'Wahltag';
    static readonly VERFAHREN_LABEL = 'Verfahren';

    static readonly BENACHRICHTIGUNGEN_LABEL = 'Benachrichtigungen';
    static readonly CHAT_LABEL = 'Chat';
    static readonly BENUTZER_LABEL = 'Benutzer';

    /**
     * Icons
     */
    static readonly HOME_ICON = 'home-alt';
    static readonly KONTAKTE_ICON = 'address-book';
    static readonly KONTAKT_LISTE_ICON = 'address-card';
    static readonly GRUPPEN_ICON = 'users';
    static readonly KONTAKTE_CLEARING_ICON = 'merge';
    static readonly PERSONENVERWALTUNG_ICON = 'address-book';
    static readonly PERSONEN_ICON = 'address-card';
    static readonly CLEARING_ICON = 'users';
    static readonly WAHL_ICON = 'k5-wahl-alt';
    static readonly WAHLEN_ICON = 'person-booth';
    static readonly AUSWERTUNGEN_ICON = 'th-list';
    static readonly WAHLBESTAND_ICON = 'tasks';
    static readonly LISTEN_ICON = 'th-list';
    static readonly SPRENGEL_ICON = 'map-marker';
    static readonly ZMR_ABGLEICH_ICON = 'file-lines';
    static readonly TAGS_ICON = 'tags';
    static readonly KONFIGURATION_ICON = 'sliders-h';
    static readonly KONFIGURATION_WAHL_ICON = 'gear';
    static readonly KONFIGURATION_ADRESSEN_ICON = 'map-marker';
    static readonly MEINE_WAHLKARTE_ICON = 'envelope-open-text';
    static readonly WAHLTAG_ICON = 'calendar-pen';
    static readonly VERFAHREN_ICON = 'folders';

    static readonly BENACHRICHTIGUNGEN_ICON = 'bell';
    static readonly CHAT_ICON = 'comments';
    static readonly BENUTZER_ICON = 'user-circle';

    /**
     * Titles
     */
    static readonly HOME_TITLE = 'Home';
    static readonly KONTAKTMANAGEMENT_TITLE = 'Kontakte';
    static readonly PERSONENVERWALTUNG_TITLE = 'Personenverwaltung';
    static readonly WAHL_TITLE = 'Wahlpaket';
    static readonly KONFIGURATION_TITLE = 'Konfiguration';
    static readonly CHAT_TITLE = 'Chat';
    static readonly BENACHRICHTIGUNGEN_TITLE = 'Benachrichtigungen';
    static readonly BENUTZER_TITLE = 'Benutzer';
    static readonly REPORTING_TITLE = 'Reporting';

    /**
     * Positions
     */
    static readonly POSITION_CENTER = 'center';
    static readonly POSITION_START = 'start';
    static readonly POSITION_END = 'end';

    /**
     * Cypress Ids
     */
    static readonly HOME_ID = 'nav-content-k5-nav-link-home';
    static readonly KONTAKTE_ID = 'nav-content-k5-nav-link-kontakte';
    static readonly GRUPPEN_ID = 'nav-content-k5-nav-link-kontakte-gruppen';
    static readonly KONTAKTE_CLEARING_ID = 'nav-content-k5-nav-link-kontakte-clearing';
    static readonly KONTAKTE_LISTE_ID = 'nav-content-k5-nav-link-kontakte-liste';
    static readonly WAHL_ID = 'nav-content-k5-nav-link-wahl';
    static readonly WAHLBESTAND_ID = 'nav-content-k5-nav-link-wahl-wahl-bestand';
    static readonly MEINE_WAHLKARTE_ID = 'nav-content-k5-nav-link-wahl-meine-wahlkarte';
    static readonly WAHL_KONFIGURATIONS_ID = 'nav-content-k5-nav-link-wahl-konfiguration';
    static readonly KONFIGURATIONS_ID = 'nav-content-k5-nav-link-konfiguration';
    static readonly KONFIGURATION_ADRESSEN_ID = 'nav-content-k5-nav-link-konfiguration-adressen';
    static readonly SPRENGEL_ID = 'nav-content-k5-nav-link-sprengel';
    static readonly ZMR_ABGLEICH_ID = 'nav-content-k5-nav-link-konfiguration-zmr-abgleich';
    static readonly TAGS_ID = 'nav-content-k5-nav-link-konfiguration-tags';
    static readonly WAHLTAG_ID = 'nav-content-k5-nav-link-wahltag';
    static readonly VERFAHREN_ID = 'nav-content-k5-nav-link-verfahren';
}
