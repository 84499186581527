import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WidgetConstants } from '@shared/constants/shared-constants';
import { MandantClient } from '@shared/models/mandantenClient';
@Component({
    selector: 'k5-betreiber-widget',
    templateUrl: './betreiber-widget.component.html'
})
export class BetreiberWidgetComponent implements OnInit {
    betreiberwidgetEinstellung: MandantClient.WidgetDaten;
    imagesource: SafeResourceUrl;
    loading: boolean = true;
    betreiberName: string = '';
    betreiberBild: string | undefined = undefined;
    betreiberFallbackImg: string = WidgetConstants.BETREIBER_FALLBACK_IMG;

    @Input()
    widgetEinstellungen: MandantClient.WidgetDaten[];

    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Gibt den Namen des Widgets zurück
     */
    get bildAlt(): string {
        return this.betreiberName + ' Betreiber Widget';
    }

    ngOnInit(): void {
        this.loadBetreiberWidget(this.widgetEinstellungen);
    }

    /**
     * Lädt das RSS Widget neu, wenn sich die URL geändert hat
     * @param changes SimpleChanges
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['widgetEinstellungen']?.currentValue) {
            this.loadBetreiberWidget(changes['widgetEinstellungen']?.currentValue);
        }
    }

    // Lädt die Einstellungen des Betreiber-Widgets
    loadBetreiberWidget(einstellungen: MandantClient.WidgetDaten[]): void {
        this.loading = true;
        if (einstellungen) {
            this.betreiberwidgetEinstellung = einstellungen.find(
                (widget: MandantClient.WidgetDaten) => widget.widgetKey === MandantClient.WidgetKeyData.BetreiberWidget
            );
            // Lädt das Bild des Betreibers
            this.betreiberBild = this.getBetreiberBild();
            // Image-Content von Base64 Daten zusammensetzen
            this.imagesource = `data:image/svg+xml;base64,${this.betreiberBild}`;
            // Lädt der Name des Betreibers
            this.betreiberName = this.getBetreiberName();
        } else {
            this.betreiberBild = null;
            this.imagesource = null;
            this.betreiberName = null;
            this.betreiberwidgetEinstellung = null;
        }
        this.loading = false;
    }

    /**
     * Ermittelt die Url von Betreiberwidget
     * @returns ein String, der enthaltet die Url
     */
    getBetreiberUrl(): string {
        return (
            this.betreiberwidgetEinstellung?.einstellungen?.find(
                (einstellung: MandantClient.WidgetEinstellungenData) =>
                    einstellung.name === WidgetConstants.URL_PROPERTY_NAME
            )?.wert ?? ''
        );
    }

    /**
     * Ermittelt die Bilddaten von Betreiberwidget
     * @returns ein String, der enthaltet die Bilddaten
     */
    getBetreiberBild(): string | undefined {
        return this.betreiberwidgetEinstellung?.einstellungen?.find(
            (einstellung: MandantClient.WidgetEinstellungenData) =>
                einstellung.name === WidgetConstants.BILD_PROPERTY_NAME
        )?.wert;
    }

    /**
     * Ermittelt der Name vom Betreiberwidget
     * @returns ein String, der enthaltet den Name vom Betreiber
     */
    getBetreiberName(): string {
        return (
            this.betreiberwidgetEinstellung?.einstellungen?.find(
                (einstellung: MandantClient.WidgetEinstellungenData) =>
                    einstellung.name === WidgetConstants.NAME_PROPERTY_NAME
            )?.wert ?? ''
        );
    }
}
