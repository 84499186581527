import { UiConstants } from '@core/constants/ui-constants';
import { RouterLinkConstants } from '@core/constants/url-constants';

/**
 * Model class for navigation links
 */
export class NavItem {
    label: string;
    icon: string;
    routerLink: string;
    url: string;
    navLevel: number;
    position: 'start' | 'center' | 'end';
    permissions?: string[];
    exactUrlActiveMatching: boolean;
    childrenVisible: boolean = false;
    cypressId: string;

    children?: NavItem[];

    get hasChildren(): boolean {
        return this.children?.length > 0;
    }

    constructor(
        label: string,
        icon: string,
        permissions: string[],
        routerLink: string,
        url: string | null,
        children: NavItem[],
        navLevel: number,
        position: 'start' | 'center' | 'end',
        exactUrlActiveMatching: boolean,
        cypressId: string
    ) {
        this.label = label;
        this.icon = icon;
        this.routerLink = routerLink;
        this.url = url;
        this.navLevel = navLevel;
        this.position = position;
        this.permissions = permissions;
        this.children = children;
        this.exactUrlActiveMatching = exactUrlActiveMatching;
        this.cypressId = cypressId;
    }
}

export const WAHL_CONFIGURATION_NAV_ITEM: NavItem = new NavItem(
    UiConstants.KONFIGURATION_WAHL_LABEL,
    UiConstants.KONFIGURATION_WAHL_ICON,
    [UiConstants.WAHL_READ, UiConstants.WAHL_PROCESS],
    RouterLinkConstants.CONFIGURATION_WAHL,
    null,
    [],
    2,
    UiConstants.POSITION_CENTER,
    false,
    UiConstants.WAHL_KONFIGURATIONS_ID
);

/**
 * Navigationslinks für Bürgerservice
 */
export const NAV_ITEMS: NavItem[] = [
    new NavItem(
        UiConstants.HOME_LABEL,
        UiConstants.HOME_ICON,
        [],
        RouterLinkConstants.HOME,
        null,
        [],
        1,
        UiConstants.POSITION_START,
        false,
        UiConstants.HOME_ID
    ),
    new NavItem(
        UiConstants.KONTAKTE_LABEL,
        UiConstants.KONTAKTE_ICON,
        [UiConstants.KONTAKT_READ, UiConstants.KONTAKT_PROCESS, UiConstants.PERSON_READ, UiConstants.PERSON_PROCESS],
        RouterLinkConstants.KONTAKTMANAGEMENT,
        null,
        [
            new NavItem(
                UiConstants.KONTAKT_LISTE_LABEL,
                UiConstants.KONTAKT_LISTE_ICON,
                [],
                RouterLinkConstants.KONTAKTE_LISTE,
                null,
                [],
                2,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.KONTAKTE_LISTE_ID
            )
        ],
        1,
        UiConstants.POSITION_CENTER,
        true,
        UiConstants.KONTAKTE_ID
    ),
    new NavItem(
        UiConstants.WAHL_LABEL,
        UiConstants.WAHL_ICON,
        [UiConstants.WAHL_READ, UiConstants.WAHL_PROCESS],
        RouterLinkConstants.WAHL_DASHBOARD,
        null,
        [WAHL_CONFIGURATION_NAV_ITEM],
        1,
        UiConstants.POSITION_CENTER,
        true,
        UiConstants.WAHL_ID
    ),
    new NavItem(
        UiConstants.KONFIGURATION_LABEL,
        UiConstants.KONFIGURATION_ICON,
        [
            UiConstants.ADRESSE_READ,
            UiConstants.ADRESSE_MODIFY,
            UiConstants.SPRENGEL_READ,
            UiConstants.SPRENGEL_MODIFY,
            UiConstants.TAG_MANAGE,
            UiConstants.ZMR_ABGLEICH_READ
        ],
        RouterLinkConstants.CONFIGURATION,
        null,
        [
            new NavItem(
                UiConstants.KONFIGURATION_ADRESSEN_LABEL,
                UiConstants.KONFIGURATION_ADRESSEN_ICON,
                [UiConstants.ADRESSE_READ, UiConstants.ADRESSE_MODIFY],
                RouterLinkConstants.CONFIGURATION_ADRESSEN,
                null,
                [],
                2,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.KONFIGURATION_ADRESSEN_ID
            ),
            new NavItem(
                UiConstants.SPRENGEL_LABEL,
                UiConstants.SPRENGEL_ICON,
                [UiConstants.SPRENGEL_READ, UiConstants.SPRENGEL_MODIFY],
                RouterLinkConstants.SPRENGEL,
                null,
                [],
                2,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.SPRENGEL_ID
            ),
            new NavItem(
                UiConstants.TAGS_LABEL,
                UiConstants.TAGS_ICON,
                [UiConstants.TAG_MANAGE],
                RouterLinkConstants.TAGS,
                null,
                [],
                2,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.TAGS_ID
            ),
            new NavItem(
                UiConstants.ZMR_ABGLEICH_LABEL,
                UiConstants.ZMR_ABGLEICH_ICON,
                [UiConstants.ZMR_ABGLEICH_READ],
                RouterLinkConstants.ZMR_ABGLEICH,
                null,
                [],
                2,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.ZMR_ABGLEICH_ID
            )
        ],
        1,
        UiConstants.POSITION_END,
        true,
        UiConstants.KONFIGURATIONS_ID
    )
];
