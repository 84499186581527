import { Component, Input } from '@angular/core';
import { Personenart } from '@shared/models/personenart';

@Component({
    selector: 'k5-personenart-table-icon',
    templateUrl: './personenart-table-icon.component.html'
})
export class PersonenartTableIconComponent {
    PERSONEN_ART = Personenart;

    @Input()
    type: Personenart;
}
